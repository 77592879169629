import styled from 'styled-components';
import Tokens from '@veneer/tokens';

export const Container = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  background-color: ${props => props.theme.colorBackground};
  overflow: hidden;
  flex-direction: column;
`;

export const PageArea = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  overflow-y: auto;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  box-sizing: border-box;
`;

export const Content = styled.div`
  height: auto;
  width: 100%;
  max-width: 944px;

  display: grid;
  grid-template-rows: auto;
  box-sizing: border-box;
  padding: ${props => props.theme.orbitalSix};
  gap: ${props => props.theme.orbitalSix};

  background-color: ${props => props.theme.colorBackground};

  @media (max-width: ${Tokens.mdMax}) {
    max-width: unset;
    height: auto;
    overflow-y: auto;
  }
`;

export const Header = styled.div`
  position: sticky;
  width: 100%;
  top: 0;
  height: 68px;

  background-color: ${props => props.theme.colorForegroundInverse};

  p {
    margin: ${props => props.theme.orbitalFour};
  }
`;
