import React, { useState, useEffect, useContext } from 'react'
import { assessmentContext } from '../contexts/assessmentContext'
import { assessmentHooksContext } from '../contexts/assesmentHooksContext'
import GeneralContext from '../contexts/GeneralContext'
import { PrinterContext } from '../provider/PrinterProvider'
import { getToastErrorStrings } from '../strings/Errors/index'
import {
  DeviceConfigurationResult,
  ToastIDs,
  RemediationState
} from '../static/consts'
// TODO: font change: add
// import { useToast } from '@veneer/core'
import { compliancyForResult } from '../utils/shared'
import { useI18n } from '@jarvis/react-portal-addons'
import {
  publishEvent,
  SnmpV1V2ToggleDisabled,
  SnmpV1V2ToggleEnabled
} from '../utils/analytics'
import { pickVeneerVersion } from '../font'

const withSnmpV1V2 = (Component) => {
  const NewComponent = () => {
    // TODO: font change: delete
    const { fontNewEnable } = useContext(GeneralContext)
    const VeneerCore = pickVeneerVersion(fontNewEnable)
    const { useToast } = VeneerCore.core

    const {
      useDeviceConfigurationsAssessmentHook,
      useRemediationHook,
      useSecurityAssessmentStatusHook,
      setHasCallRemediation
    } = useContext(assessmentHooksContext)
    const { t } = useI18n()

    const { remediationConfigSNMPV1V2, remediationResult } = useRemediationHook
    const { configSNMPv1v2Write } = useDeviceConfigurationsAssessmentHook
    const { isComplete } = useSecurityAssessmentStatusHook
    const { client } = useContext(GeneralContext)
    const { activePrinterID } = useContext(PrinterContext)
    const {
      requestAssessment,
      deviceEventData: { deviceUUID, productNumber }
    } = useContext(assessmentContext)

    const assessmentDataResult = configSNMPv1v2Write?.assessmentData?.result
    const isComplianceOff =
      assessmentDataResult === DeviceConfigurationResult.complianceOff

    const [isCompliant, setIsCompliant] = useState(
      compliancyForResult(configSNMPv1v2Write)
    )
    const [toggleState, setToggleState] = useState(!isCompliant)
    const [isInProgress, setInProgress] = useState(false)

    const { addToast } = useToast()

    const getString = (subkey: string, defaultText: string): string => {
      return t(
        `smbDevices.configurations.configSNMPv1v2Write.${subkey}`,
        defaultText
      )
    }

    async function onChangeSnmpV1V2(state) {
      state
        ? publishEvent(SnmpV1V2ToggleEnabled, deviceUUID, productNumber)
        : publishEvent(SnmpV1V2ToggleDisabled, deviceUUID, productNumber)
      let err
      setHasCallRemediation(true)
      setInProgress(true)
      try {
        setToggleState(state)
        await client.changeSNMPV1V2Config(activePrinterID, state)
      } catch (error) {
        setInProgress(false)
        err = error
        console.log(err)
        addToast({
          id: ToastIDs.setSNMPV1V2.NEGATIVE + activePrinterID,
          action: () => {},
          type: 'negative', //ToastTypes.NEGATIVE
          ...getToastErrorStrings(err.data, t)
        })
      }
      await requestAssessment()
    }

    useEffect(() => {
      if (
        remediationConfigSNMPV1V2.isLoading ||
        remediationConfigSNMPV1V2.remediationState ===
          RemediationState.inprogress
      ) {
        setInProgress(true)
      } else if (isComplete) {
        setInProgress(false)
        setIsCompliant(compliancyForResult(configSNMPv1v2Write))
        setToggleState(!compliancyForResult(configSNMPv1v2Write))
      }
    }, [isComplete, remediationConfigSNMPV1V2, configSNMPv1v2Write])

    const componentProps = {
      remediationConfigSNMPV1V2,
      remediationResult,
      isInProgress,
      getString,
      onChangeSnmpV1V2,
      isCompliant,
      isComplianceOff,
      configSNMPv1v2Write,
      toggleState
      // handleOpen
      // onAccordionClick,
      // state,
    }

    return <Component componentProps={componentProps} />
  }
  return NewComponent
}

export default withSnmpV1V2
