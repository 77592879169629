import moment from 'moment';

export const formatDateLong = (date, language = navigator.language) => (
  date.toLocaleDateString(language, {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  })
);

export const formatDateShort = (date, language = navigator.language) => (
  date.toLocaleDateString(language, {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  })
);

export const formatTime = (date, language = navigator.language) => (
  date.toLocaleTimeString(language)
);

export const formatDateTime = (date, language = navigator.language) => (
  `${formatDateShort(date, language)} | ${formatTime(date, language)}`
);

export const formatEpochDateTimeLongMonth = (epochDate, locale) => {
  let time = new Date();
  if (epochDate) {
    time = new Date(epochDate);
  }
  const options = {
    year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true,
  };

  return time.toLocaleDateString(locale, options);
};

export const diffYearsDate = (startDate, endDate) => {
  const yearNew = endDate.getFullYear();
  const monthNew = endDate.getMonth();
  const dayNew = endDate.getDate();
  const yearOld = startDate.getFullYear();
  const monthOld = startDate.getMonth();
  const dayOld = startDate.getDate();
  let diffYear = yearNew - yearOld;
  if (monthOld > monthNew) {
    diffYear -= 1;
  } else if (monthOld === monthNew && dayOld > dayNew) {
    diffYear -= 1;
  }
  return diffYear;
};

export const diffMonthsDate = (startDate, endDate) => {
  let months;
  months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
  months -= startDate.getMonth();
  months += endDate.getMonth();
  return months <= 0 ? 0 : months;
};

export function getDefaultDate() {
  return moment()
    .hour(1)
    .minute(0)
    .second(0)
    .millisecond(0);
}

export const compareDate = (startDate, endDate) => {
  if (startDate < endDate) {
    return -1;
  }
  if (startDate > endDate) {
    return 1;
  }
  return 0;
};

export const addDays = (date, numberDays) => {
  const dateNew = new Date();
  dateNew.setDate(date.getDate() + numberDays);
  return dateNew;
};

export default {
  addDays,
  compareDate,
  diffMonthsDate,
  diffYearsDate,
  formatDateLong,
  formatDateShort,
  formatDateTime,
  formatEpochDateTimeLongMonth,
  formatTime,
  getDefaultDate,
};
