import React from 'react'
import { defaultPrinterIcon, imageSizes } from '../../static/consts'

const PrinterIcon = ({ printerImages, inputSize }) => {
  function getImages(printerImages) {
    return printerImages.map((image) => {
      const url = image.url
      const [width, height] = getIconSize(url)
      return {
        url,
        width,
        height
      }
    })
  }

  const imagesDict = getImages(printerImages || [])

  function getIconSize(url) {
    const splitted = url?.split('/')
    const lastItem = splitted.length - 1
    const splitPng = splitted[lastItem]?.split('.png')
    const iconSizeString = splitPng[0]?.split('x')
    const iconSizeArray = iconSizeString.map(Number)

    return iconSizeArray
  }

  function printerIcon() {
    const sortedObj = imagesDict.sort((a, b) => b.width - a.width)
    const iconUrl = sortedObj.map((a) => a.url)

    switch (inputSize) {
      case imageSizes.large:
        return iconUrl[0]
      case imageSizes.medium:
        return iconUrl[1]
      case imageSizes.small:
        return iconUrl[2]
      case imageSizes.extrasmall:
        return iconUrl[3]
      default:
        return iconUrl[0]
    }
  }

  function statusImage() {
    return printerIcon() || defaultPrinterIcon
  }

  return (
    <img
      src={statusImage()}
      data-testid="device-inset-img"
      style={{ maxWidth: '128px', maxHeight: '128px' }}
    />
  )
}

export default PrinterIcon
