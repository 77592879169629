import { DeviceConfigurationResult } from '../static/consts'

export const compliancyForResult = (configuration) => {
  if (
    configuration &&
    configuration.assessmentData &&
    configuration.assessmentData.result
  ) {
    const lowercasedResult = configuration.assessmentData.result.toLowerCase()
    return lowercasedResult === DeviceConfigurationResult.compliant
  }
  return false
}

export const extractPwdComplexity = (config, id) => {
  const configControls = config.configcontrols.find((c) => c.id === id)
  if (
    configControls &&
    configControls.constraintsData &&
    configControls.constraintsData.passwordcomplexity
  ) {
    const {
      enabled,
      pattern
    } = configControls.constraintsData.passwordcomplexity
    return {
      enabled,
      pattern
    }
  }
  return {}
}

export const assessmentStatus = (assessmentResult, state) => {
  return (
    assessmentResult &&
    assessmentResult.securityAssessmentstatus &&
    assessmentResult.securityAssessmentstatus.assessmentStatus.toLowerCase() ===
      state.toLowerCase()
  )
}
