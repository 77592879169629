import styled from 'styled-components'

// pr-list__status
export const PrinterStatus = styled.div`
  display: flex;
  align-items: center;
`

// pr-list__status-type
export const PrinterStatusType = styled.span`
  display: flex;
  align-items: center;
`

// pr-list__status-indicator
export const PrinterStatusIndicator = styled.span`
  height: 12px;
  width: 12px;
  border-radius: 100%;
  display: inline-block;
  margin-right: 8px;
`

// pr-list__status-indicator pr-list__status-indicator--good
export const PrinterStatusIndicatorGood = styled(PrinterStatusIndicator)`
  background-color: #0c9f98;
`

// pr-list__status-indicator pr-list__status-indicator--bad
export const PrinterStatusIndicatorBad = styled(PrinterStatusIndicator)`
  background-color: #d06702;
`

// pr-list__status-indicator pr-list__status-indicator--unknown
export const PrinterStatusIndicatorUnknown = styled(PrinterStatusIndicator)`
  background-color: #adadad;
`
