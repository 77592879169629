import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@veneer/core/dist/scripts/button';
import IconWarningAlt from '@veneer/core/dist/scripts/icons/icon_warning_alt';
import {
  ButtonGroup,
  ModalBox,
  TitleContainer,
  VeneerModal,
} from './styles';

const UnsavedChangesModal = ({
  onCancel,
  onClose,
  onLeave,
  onShow,
  show,
  i18n,
}) => {
  useEffect(() => {
    if (show) {
      onShow?.();
    }
  }, [onShow, show]);

  return (
    <VeneerModal
      align="start"
      show={show}
      onClose={onClose}
      footer={(
        <ButtonGroup>
          <Button
            appearance="secondary"
            data-testid="cancel-btn"
            onClick={onCancel}
          >
            {i18n.cancel}
          </Button>
          <Button
            data-testid="leave-btn"
            onClick={onLeave}
          >
            {i18n.leave}
          </Button>
        </ButtonGroup>
      )}
    >
      <ModalBox>
        <TitleContainer>
          <IconWarningAlt
            filled
            color="colorOrange6"
            size={50}
          />
          <h4 className="title-small" data-testid="modal-title">
            {i18n.title}
          </h4>
        </TitleContainer>
        <p className="body-large" data-testid="body-text">
          {i18n.body}
        </p>
      </ModalBox>
    </VeneerModal>
  );
};

UnsavedChangesModal.defaultProps = {
  onClose: () => {},
  onCancel: () => {},
  onShow: () => {},
  onLeave: () => {},
  i18n: {
    title: 'Unsaved Changes',
    body: 'Are you sure you want to leave this page?'
      + ' Your changes have not been saved and will be discarded if you leave now.',
    cancel: 'Cancel',
    leave: 'Leave',
  },
  show: false,
};

UnsavedChangesModal.propTypes = {
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
  onShow: PropTypes.func,
  onLeave: PropTypes.func,
  show: PropTypes.bool,
  i18n: PropTypes.shape({
    title: PropTypes.string,
    body: PropTypes.string,
    cancel: PropTypes.string,
    leave: PropTypes.string,
  }),
};

export default UnsavedChangesModal;
