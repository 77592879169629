import styled from 'styled-components'

// export const HeaderContainer = styled.div`
//   display: flex;
//   height: 90%;
//   position: relative;
//   width: 100%;
// `

export const HeaderTitleWrap = styled.div`
  margin: 0px 0px 16px;
  width: 100%;
`

export const HeaderTitle = styled.h1`
  font-size: 32px;
  line-height: 36px;
  margin-bottom: 8px;
  font-family: ${(props) => props?.font};
  @media (max-width: 900px) {
    width: 100%;
  }
`

export const HeaderSubtitle = styled.p`
  font-family: ${(props) => props?.font};
  @media (max-width: 900px) {
    width: 100%;
  }
`
