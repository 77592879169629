import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
// import {
//   IconShieldCheck,
//   IconShieldWarning,
//   IconConnectivity,
//   IconChevronRight,
//   IconShieldOff
// } from '@veneer/core/dist/scripts/icons'
// import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator'
import { useI18n } from '@jarvis/react-portal-addons'
import { assessmentContext } from '../../contexts/assessmentContext'
import PrinterIcon from '../PrinterIcon'
import {
  DeviceDetailsContainer,
  DeviceDetailsWrapper,
  DeviceImageWrapper,
  DeviceDetails,
  DeviceFriendlyName,
  DeviceName,
  DeviceLocation,
  DeviceStatusList,
  DeviceStatus,
  DeviceStatusLabel,
  RedirectButton,
  RedirectButtonIcon
} from './styles'
import { AssessmentResult } from '../../static/consts'
import { PrinterDetailLinkClicked, publishEvent } from '../../utils/analytics'
import { pickVeneerVersion } from '../../font'
import GeneralContext from '../../contexts/GeneralContext'

const PrinterDetailsCardDesc = (props) => {
  // TODO: font change: delete
  const { fontNewEnable } = useContext(GeneralContext)
  const VeneerCore = pickVeneerVersion(fontNewEnable)
  const {
    ProgressIndicator,
    IconShieldCheck,
    IconShieldWarning,
    IconConnectivity,
    IconChevronRight,
    IconShieldOff
  } = VeneerCore.core
  const { fontFamilyRegular, fontFamilyLight } = VeneerCore.token

  const { t } = useI18n()
  const { deviceCardDetails, solutionMetaResult } = props
  const {
    deviceId,
    deviceName,
    deviceLocation,
    deviceConnectionStatus,
    deviceFriendlyName,
    deviceImage,
    solutionMetadata
  } = deviceCardDetails

  const {
    isMonitoringInProgress,
    loadingAssessment,
    hasCallRemediation,
    deviceEventData: { deviceUUID, productNumber }
  } = useContext(assessmentContext)

  return (
    <DeviceDetailsContainer>
      <DeviceDetailsWrapper>
        <DeviceImageWrapper>
          <PrinterIcon
            printerImages={deviceImage}
            inputSize="small"
            data-testid="device-image"
          />
        </DeviceImageWrapper>
        <DeviceDetails>
          <DeviceFriendlyName
            data-testid="device-friendly-name"
            font={fontFamilyRegular}
          >
            {deviceFriendlyName || deviceName}
          </DeviceFriendlyName>
          <DeviceName data-testid="device-name" font={fontFamilyLight}>
            {deviceName}
          </DeviceName>
          <DeviceLocation font={fontFamilyLight} data-testid="device-location">
            {deviceLocation}
          </DeviceLocation>
          <DeviceStatusList>
            <DeviceStatus>
              {deviceConnectionStatus === 'online' ? (
                <IconConnectivity
                  // color={'colorHpBlue5'}
                  color={'colorGreen6'}
                  filled
                  size={24}
                  data-testid={`device-connect-icon-${deviceConnectionStatus}`}
                />
              ) : (
                <IconConnectivity
                  color={'colorRed6'}
                  size={24}
                  filled
                  data-testid={`device-connect-icon-${deviceConnectionStatus}`}
                />
              )}
              <DeviceStatusLabel
                data-testid={`device-connect-status-${deviceConnectionStatus}`}
                font={fontFamilyRegular}
              >
                {deviceConnectionStatus === 'online'
                  ? t('smbDevices.connectionStatus.online', 'Connected')
                  : t('smbDevices.connectionStatus.offline', 'Not connected')}
              </DeviceStatusLabel>
            </DeviceStatus>
            {hasCallRemediation ||
            loadingAssessment ||
            isMonitoringInProgress ? (
              <DeviceStatus>
                <ProgressIndicator />
              </DeviceStatus>
            ) : (
              <>
                {deviceConnectionStatus === 'online' &&
                solutionMetadata &&
                Object.keys(solutionMetadata).length !== 0 ? (
                  <>
                    {solutionMetaResult ===
                    AssessmentResult.securityMonitored ? (
                      <DeviceStatus>
                        <IconShieldCheck
                          filled
                          size={24}
                          color="colorTurquoise7"
                          data-testid={`device-monitor-statusicon-${solutionMetaResult}`}
                        />
                        <DeviceStatusLabel
                          data-testid={`device-monitor-statustext-${solutionMetaResult}`}
                          font={fontFamilyRegular}
                        >
                          {t(
                            'smbDevices.deviceSecurityStatus.securityMonitored',
                            'Monitored'
                          )}
                        </DeviceStatusLabel>
                      </DeviceStatus>
                    ) : solutionMetaResult ===
                      AssessmentResult.needsAttention ? (
                      <DeviceStatus>
                        <IconShieldWarning
                          filled
                          size={24}
                          color="colorOrange6"
                          data-testid={`device-monitor-statusicon-${solutionMetaResult}`}
                        />
                        <DeviceStatusLabel
                          data-testid={`device-monitor-statustext-${solutionMetaResult}`}
                          font={fontFamilyRegular}
                        >
                          {t(
                            'smbDevices.deviceSecurityStatus.needsAttention',
                            'Needs attention'
                          )}
                        </DeviceStatusLabel>
                      </DeviceStatus>
                    ) : solutionMetaResult === AssessmentResult.unknown ? (
                      <DeviceStatus>
                        <IconShieldWarning
                          filled
                          size={24}
                          color="colorOrange6"
                          data-testid={`device-monitor-statusicon-${solutionMetaResult}`}
                        />
                        <DeviceStatusLabel
                          data-testid={`device-monitor-statustext-${solutionMetaResult}`}
                          font={fontFamilyRegular}
                        >
                          {t(
                            'smbDevices.deviceSecurityStatus.needsAttention',
                            'Needs attention'
                          )}
                        </DeviceStatusLabel>
                      </DeviceStatus>
                    ) : (
                      <DeviceStatus>
                        <IconShieldOff
                          size={24}
                          disabled
                          filled
                          data-testid={`device-monitor-statusicon-${solutionMetaResult}`}
                        />
                        <DeviceStatusLabel
                          data-testid={`device-monitor-statustext-${solutionMetaResult}`}
                          font={fontFamilyRegular}
                        >
                          {t(
                            'smbDevices.deviceSecurityStatus.notMonitored',
                            'Unmonitored'
                          )}
                        </DeviceStatusLabel>
                      </DeviceStatus>
                    )}
                  </>
                ) : null}
              </>
            )}
          </DeviceStatusList>
          <RedirectButton
            font={fontFamilyRegular}
            onClick={() =>
              publishEvent(PrinterDetailLinkClicked, deviceUUID, productNumber)
            }
          >
            <Link
              className="smb-pd-card__linkLabel"
              to={`/printers/${deviceId}`}
              data-testid="printer-details-link"
            >
              {t('smbDevices.deviceDesc.printerDetailsLink', 'Printer details')}
            </Link>
            <RedirectButtonIcon>
              <IconChevronRight
                color={'colorHpBlue6'}
                filled
                size={24}
                data-testid="link-icon-arrow"
              />
            </RedirectButtonIcon>
          </RedirectButton>
        </DeviceDetails>
      </DeviceDetailsWrapper>
    </DeviceDetailsContainer>
  )
}
export default PrinterDetailsCardDesc
