import styled from 'styled-components';
import Tokens from '@veneer/tokens';

export const Container = styled.div`
  position: sticky;
  display: flex;
  bottom: 0;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  align-self: end;
  width: 100%;
  z-index: 999;

  padding: ${props => `${props.theme.orbitalThree} ${props.theme.orbitalSix}`};
  box-shadow: ${props => (props.shadow ? `0 -2px 6px 0 ${props.theme.colorBackgroundLight}` : 'none')};
  background-color: ${props => props.theme.colorBackground};

  @media (max-width: ${Tokens.smMin}) {
    flex-direction: column;
    row-gap: ${Tokens.space3};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  column-gap: ${props => props.theme.orbitalThree};

  @media (max-width: ${Tokens.smMin}) {
    flex-direction: column;
    row-gap: ${Tokens.space3};

  }
`;
