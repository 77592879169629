import styled from 'styled-components'

export const SecuritySettingWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 16px 12px 24px;
`
export const SecuritySettingContent = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  h4 {
    font-family: ${({ font }) => font};
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 4px;
    color: #212121;
  }
  p {
    font-family: ${({ fontLight }) => fontLight};
    font-size: 16px;
    line-height: 24px;
    color: #212121;
  }
`
