import styled from 'styled-components'

// search-bar
export const SearchBoxContainer = styled.div`
  width: 100%;
  height: 48px;
  margin-bottom: 16px;
  @media (max-width: 576px) {
    width: 100%;
  }
`

export const SearchBoxWrap = styled.div`
  width: 352px;
  @media (max-width: 576px) {
    width: 100%;
  }
`
