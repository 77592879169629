// import { HelperLinks } from '../../static/consts'

export function getToastErrorStrings(data, t) {
  const nonNullData = data || {}
  const errorCode = nonNullData.errorCode || 'default'
  switch (errorCode) {
    case 'RemediationError':
      return {
        text: t(
          'smbDevices.common.toast.toastBadTitle',
          'Unable to save changes on Smart Security settings. Try again later.'
        )
        // subtitle: t('smbDevices.errors.remediationErrorToastSubtitle')
      }
    default:
      return {
        text: t(
          'smbDevices.common.toast.toastBadTitle',
          'Unable to save changes on Smart Security settings. Try again later.'
        )
        // subtitle: t('smbDevices.errors.toastSubtitleDefault')
      }
  }
}

export const getErrorMessage = (data, t) => {
  const nonNullData = data || {}
  const httpCode = nonNullData.status || 'default'
  switch (Number(httpCode)) {
    // Bad Request  / Unauthorized / Forbidden
    case 400:
    case 401:
    case 403:
      return {
        message: t(
          'smbDevices.errors.securityMonitoringLoadFail01',
          `We couldn’t load security monitoring. Find help and troubleshooting resources to resolve the issue,`
        ),
        linkText: t(
          'smbDevices.errors.securityMonitoringLoadFailFAQLink',
          'here.'
        ),
        showButton: false,
        showLink: true
      }
    case 500:
      return {
        message: t(
          'smbDevices.errors.unexpectedIssueError',
          `We encountered an unexpected issue. We are sorry for the inconvenience, but you will have to try accessing your account later.`
        ),
        showButton: false,
        showLink: false
      }
    case 502:
      return {
        message: t(
          'smbDevices.errors.noConnection',
          `We couldn't find an internet connection. Make sure you are connected and try again.`
        ),
        showButton: true,
        showLink: false
      }
    case 503:
      return {
        message: t(
          'smbDevices.errors.retryAgain',
          `We encountered an unexpected issue. Select Retry to try again.`
        ),
        showButton: true,
        showLink: false
      }
    default:
      return {
        message: t(
          'smbDevices.errors.noConnection',
          `We couldn't find an internet connection. Make sure you are connected and try again.`
        ),
        showButton: false,
        showLink: false
      }
  }
}
