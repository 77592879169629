import React, { useState, useEffect, useContext } from 'react'
// import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator'
// Import Context
import { assessmentContext } from '../../contexts/assessmentContext'
import { PrinterContext } from '../../provider/PrinterProvider'
import GeneralContext from '../../contexts/GeneralContext'
// Import Components
import PrinterDetailsCardDesc from '../PrinterDetailsCardDesc'
import MonitorStatus from '../MonitorStatus'
import useGetDeviceDetails from '../../hooks/useGetDeviceDetails'
// import conts
import { getDateAndTime, getEnvConfig } from '../../static/consts'
import {
  DeviceDetailsCard,
  DeviceDetailsCardLoader,
  DividerContainer,
  Divider
} from './styles'
import { useParams } from 'react-router-dom'
import { useI18n } from '@jarvis/react-portal-addons'
import { pickVeneerVersion } from '../../font'

const PrinterDetailsCard = (props) => {
  // TODO: font change: delete
  const { fontNewEnable } = useContext(GeneralContext)
  const VeneerCore = pickVeneerVersion(fontNewEnable)
  const { ProgressIndicator } = VeneerCore.core

  const {
    childFunc,
    remediationFetch,
    setgetDateTime,
    setPrinterName,
    setUpdateLoading,
    setDeviceAssLoading,
    setSolutionMonitorState,
    setSolutionAssResult,
    setActiveState,
    deviceCardDetails,
    setDeviceCardDetails
  } = props
  const { isMonitoringInProgress, hasCallRemediation, loadingAssessment } =
    useContext(assessmentContext)
  const { devID } = useParams()
  const { t } = useI18n()
  const { setConnectState, setsolutionMetadataVal, setBizModel } =
    useContext(PrinterContext)

  const { stack } = useContext(GeneralContext)
  const config = getEnvConfig(stack)

  const [solutionMetaResult, setSolutionMetaResult] = useState('')

  const [isActive, setIsActive] = useState('')

  const [loading, setLoading] = useState(true)

  const [outOfComplaint, setOutOfComplaint] = useState()

  const { fetchDeviceDetailsAPI } = useGetDeviceDetails()

  useEffect(() => {
    if (devID !== undefined) {
      reloadDeviceCard()
    }
  }, [devID])

  useEffect(() => {
    setConnectState(deviceCardDetails.deviceConnectionStatus)
    setsolutionMetadataVal(deviceCardDetails.solutionMetadata)
    setSolutionMonitorState(
      deviceCardDetails.solutionMetadata &&
        deviceCardDetails.solutionMetadata[config?.clientId] &&
        deviceCardDetails.solutionMetadata[config?.clientId]?.state
    )
    setSolutionAssResult(
      deviceCardDetails.solutionMetadata &&
        deviceCardDetails.solutionMetadata[config?.clientId] &&
        deviceCardDetails.solutionMetadata[config?.clientId]?.result
    )
    setActiveState(
      deviceCardDetails.solutionMetadata &&
        deviceCardDetails.solutionMetadata[config?.clientId] &&
        deviceCardDetails.solutionMetadata[config?.clientId]?.active
    )
    setOutOfComplaint(
      deviceCardDetails.solutionMetadata &&
        deviceCardDetails.solutionMetadata[config?.clientId] &&
        deviceCardDetails.solutionMetadata[config?.clientId]
          ?.outOfComplianceCount
    )
  }, [deviceCardDetails])

  const reloadDeviceCard = () => {
    setLoading(true)
    setUpdateLoading(true)
    setDeviceAssLoading(true)
    fetchDeviceDetailsAPI(devID)
      .then((details) => {
        setDeviceCardDetails(createDeviceCardData(details))
        setLoading(false)
        setUpdateLoading(false)
        setDeviceAssLoading(false)
        setgetDateTime(getDateAndTime(t))
        const deviceFriendlyName = details?.identity?.friendlyName
        const deviceName = details?.identity?.makeAndModel?.name
        setPrinterName(deviceFriendlyName || deviceName)
        setBizModel(details?.identity?.bizModel)
        const result =
          details?.solutionMetadata &&
          details?.solutionMetadata[config?.clientId] &&
          details?.solutionMetadata[config?.clientId]?.result

        const active =
          details?.solutionMetadata &&
          details?.solutionMetadata[config?.clientId] &&
          details?.solutionMetadata[config?.clientId]?.active

        const resultVal = result && result.toLowerCase()
        setSolutionMetaResult(resultVal)
        setIsActive(active)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
        setUpdateLoading(false)
        setDeviceAssLoading(false)
        setgetDateTime(getDateAndTime(t))
      })
  }

  const remediationReloadDevice = () => {
    setUpdateLoading(true)
    fetchDeviceDetailsAPI(devID)
      .then((details) => {
        setDeviceCardDetails(createDeviceCardData(details))
        setUpdateLoading(false)
        setgetDateTime(getDateAndTime(t))
        const deviceFriendlyName = details?.identity?.friendlyName
        const deviceName = details?.identity?.makeAndModel?.name
        setPrinterName(deviceFriendlyName || deviceName)
        setBizModel(details?.identity?.bizModel)
        const result =
          details?.solutionMetadata &&
          details?.solutionMetadata[config?.clientId] &&
          details?.solutionMetadata[config?.clientId]?.result

        const active =
          details?.solutionMetadata &&
          details?.solutionMetadata[config?.clientId] &&
          details?.solutionMetadata[config?.clientId]?.active

        const resultVal = result && result.toLowerCase()
        setSolutionMetaResult(resultVal)
        setIsActive(active)
      })
      .catch((err) => {
        console.log(err)
        setUpdateLoading(false)
        setgetDateTime(getDateAndTime(t))
      })
  }

  const createDeviceCardData = (details) => {
    return {
      deviceId: details.deviceId,
      deviceName: details?.identity?.makeAndModel?.name,
      deviceLocation: details?.identity?.location,
      deviceFriendlyName: details?.identity?.friendlyName,
      deviceConnectionStatus: details?.status?.connectionState,
      deviceImage: details?.images,
      solutionMetadata: details?.solutionMetadata,
      deviceUUID: details?.identity?.deviceUuid,
      productNumber: details?.identity?.makeAndModel?.number
    }
  }

  useEffect(() => {
    childFunc.current = reloadDeviceCard
    remediationFetch.current = remediationReloadDevice
  }, [])

  return (
    <>
      {loading ||
      ((loadingAssessment || isMonitoringInProgress) && !hasCallRemediation) ? (
        <DeviceDetailsCardLoader>
          <ProgressIndicator />
        </DeviceDetailsCardLoader>
      ) : (
        <DeviceDetailsCard>
          <PrinterDetailsCardDesc
            deviceCardDetails={deviceCardDetails}
            solutionMetaResult={solutionMetaResult}
          />
          <DividerContainer>
            <Divider />
          </DividerContainer>
          <MonitorStatus
            deviceConnectionStatus={deviceCardDetails.deviceConnectionStatus}
            solutionMetadata={deviceCardDetails.solutionMetadata}
            solutionMetaResult={solutionMetaResult}
            isActive={isActive}
            outOfComplaint={outOfComplaint}
          />
        </DeviceDetailsCard>
      )}
    </>
  )
}
export default PrinterDetailsCard
