import React, { useContext } from 'react'
// import Modal from '@veneer/core/dist/scripts/modal'
// import AlertModal from '@veneer/core/dist/scripts/alert_modal'
// import Toggle from '@veneer/core/dist/scripts/toggle'
// import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator'
// import Button from '@veneer/core/dist/scripts/button'
import { PrinterContext } from '../../provider/PrinterProvider'
import { assessmentHooksContext } from '../../contexts/assesmentHooksContext'
import { assessmentContext } from '../../contexts/assessmentContext'
import { useSecurityMonitor } from '../../hooks/useSecurityMonitor'
import { useI18n } from '@jarvis/react-portal-addons'
import { MonitorStatusInfoWrap, MonitorStatusInfo } from './styles'
// import { IconWarningAlt } from '@veneer/core/dist/scripts/icons'
import {
  publishEvent,
  SecurityMonitorCancelButtonClicked,
  SecurityMonitorModalDisplayed,
  SecurityMonitorToggleDisabled,
  SecurityMonitorToggleEnabled,
  SecurityMonitorTurnOffButtonClicked
} from '../../utils/analytics'
import { SecurityAssessmentState } from '../../static/consts'
import GeneralContext from '../../contexts/GeneralContext'
import { pickVeneerVersion } from '../../font'

const MonitorSettings = ({ isActive }) => {
  // TODO: font change: delete
  const { fontNewEnable } = useContext(GeneralContext)
  const VeneerCore = pickVeneerVersion(fontNewEnable)
  const {
    Modal,
    AlertModal,
    Toggle,
    IconWarningAlt,
    ProgressIndicator,
    Button
  } = VeneerCore.core
  const { fontFamilyRegular, fontFamilyLight } = VeneerCore.token

  const { t } = useI18n()
  const {
    useSecurityAssessmentStatusHook: { isEnabled, assessmentDataNull }
  } = useContext(assessmentHooksContext)
  const { activePrinterID, client } = useContext(PrinterContext)
  const {
    hasCallRemediation,
    solutionMonitorState,
    deviceEventData: { deviceUUID, productNumber }
  } = useContext(assessmentContext)

  const {
    alertState,
    modalState,
    monitorState,
    setModalState,
    setAlertState,
    setMonitorState
  } = useSecurityMonitor({
    client,
    activePrinterID
  })

  const handleToggle = (status) => {
    setMonitorState(status)
    !status
      ? (setModalState(true),
        publishEvent(SecurityMonitorToggleDisabled, deviceUUID, productNumber),
        publishEvent(SecurityMonitorModalDisplayed, deviceUUID, productNumber))
      : (setAlertState(true),
        publishEvent(SecurityMonitorToggleEnabled, deviceUUID, productNumber))
  }

  let emptyAssessmentToggle = false

  if (
    !!!isEnabled &&
    solutionMonitorState.toLowerCase() == SecurityAssessmentState.disabled
  ) {
    emptyAssessmentToggle = false
  }

  if (
    !!!isEnabled &&
    solutionMonitorState.toLowerCase() == SecurityAssessmentState.enabled
  ) {
    emptyAssessmentToggle = true
  }

  let isActiveDisable
  {
    isActive === 'false' ? (isActiveDisable = true) : (isActiveDisable = false)
  }

  return (
    <>
      <MonitorStatusInfoWrap>
        <Toggle
          id="monitor"
          disabled={hasCallRemediation || isActiveDisable || assessmentDataNull}
          on={isEnabled || emptyAssessmentToggle}
          onChange={(status) => handleToggle(status)}
          data-testid="security-monitor-toggle"
        />

        <MonitorStatusInfo
          Color={`${
            assessmentDataNull || isActiveDisable || hasCallRemediation
              ? '#adadad'
              : '#212121'
          }`}
          data-testid="security-monitor-title"
          fonts={fontFamilyLight}
          font={fontFamilyRegular}
        >
          {t('smbDevices.monitorSecuritySettings.title', 'Security monitoring')}
        </MonitorStatusInfo>
      </MonitorStatusInfoWrap>

      <AlertModal
        id="SM-alert-modal"
        className="sm-alert-modal"
        closeOnBlur={false}
        show={alertState}
        align={'center'}
        data-testid="security-monitor-alert"
      >
        <div className="sm-alert-modal__header">
          <div className="sm-alert-modal__loader">
            <ProgressIndicator data-testid="security-alert-inprogress" />
          </div>
          <h2
            style={{ fontFamily: fontFamilyRegular }}
            data-testid={`security-alert-${monitorState}`}
          >
            {monitorState
              ? t(
                  'smbDevices.monitorSecuritySettings.monitoringOnAlertTitle',
                  'Enabling security monitoring'
                )
              : t(
                  'smbDevices.monitorSecuritySettings.monitoringOffAlertTitle',
                  'Monitoring is shutting down...'
                )}
          </h2>
        </div>
        <p
          className="sm-alert-modal__text"
          data-testid="modal-alert-desc"
          style={{ fontFamily: fontFamilyLight }}
        >
          {monitorState
            ? t(
                'smbDevices.monitorSecuritySettings.monitoringOnAlertDesc',
                'This may take a moment. Please wait...'
              )
            : t(
                'smbDevices.monitorSecuritySettings.monitoringOffAlertDesc',
                'This may take a moment. Please wait…'
              )}
        </p>
      </AlertModal>

      <Modal
        id="SM-confirmation-modal"
        className="sm-modal"
        data-testid="security-monitor-modal"
        closeOnBlur={false}
        onClose={() => setModalState(false)}
        show={modalState}
        footer={
          <div
            className="vn-button-group--responsive"
            style={{ textAlign: 'right' }}
          >
            <Button
              customStyle={{ minWidth: '108px' }}
              appearance="secondary"
              onClick={() => {
                setModalState(false)
                publishEvent(
                  SecurityMonitorCancelButtonClicked,
                  deviceUUID,
                  productNumber
                )
              }}
              data-testid="modal-no-btn"
            >
              {t('smbDevices.common.cancelBtnText', 'Cancel')}
            </Button>
            <Button
              customStyle={{ minWidth: '118px' }}
              appearance="primary"
              onClick={() => {
                setAlertState(true)
                publishEvent(
                  SecurityMonitorTurnOffButtonClicked,
                  deviceUUID,
                  productNumber
                )
              }}
              data-testid="modal-yes-btn"
            >
              {t('smbDevices.common.turnOffBtnText', 'Turn Off')}
            </Button>
          </div>
        }
      >
        <div className="sm-modal__header">
          <IconWarningAlt
            size={36}
            filled
            color="colorDarkOrange4"
            data-testid="security-warning-icon"
          />
          <h2
            style={{ fontFamily: fontFamilyRegular }}
            data-testid="security-alert-title"
          >
            {t(
              'smbDevices.monitorSecuritySettings.monitoringOffTitle',
              'Turn off security monitoring?'
            )}
          </h2>
        </div>
        <div
          className="sm-modal__content"
          style={{ fontFamily: fontFamilyLight }}
        >
          <p
            className="sm-modal__text"
            data-testid="modal-content-1"
            style={{ fontFamily: fontFamilyLight }}
          >
            {t(
              'smbDevices.monitorSecuritySettings.monitoringOffDesc',
              'If you turn off security monitoring, HP will no longer monitor these settings. HP recommends that security monitoring remain on to help protect your data.'
            )}
          </p>
          <p
            className="sm-modal__text"
            data-testid="modal-content-2"
            style={{ fontFamily: fontFamilyLight }}
          >
            {t(
              'smbDevices.monitorSecuritySettings.monitoringOffSubDesc',
              'Are you sure you want to turn it off?'
            )}
          </p>
        </div>
      </Modal>
    </>
  )
}
export default MonitorSettings
