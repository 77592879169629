import styled from 'styled-components'

export const PrinterDetailsContainer = styled.div`
  margin: 0px 24px 24px;
`
export const PrinterSettingsWrap = styled.div`
  background-color: #fff;
  border-radius: 16px;
`
export const LoaderContainer = styled.div`
  min-height: 247px;
`
export const Loader = styled.div`
  min-height: 170px;
  display: grid;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #2121211a;
`
// divider-container
export const DividerContainer = styled.div`
  display: flex;
  align-self: stretch;
`
// divider
export const Divider = styled.div`
  & {
    display: flex;
    align-items: flex-start;
    padding: 0px 32px;
    transform: rotate(-180deg);
    position: static;
    height: 100%;
  }
  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 50%;
    border-left: 2px solid #2121211a;
    transform: translate(-50%);
  }
`
