import styled from 'styled-components'

// smb-pd-card__container
export const DeviceDetailsContainer = styled.div`
  flex: 1;
  @media (max-width: 768px) {
    padding-left: 0px;
    padding-top: 32px;
  }
`
