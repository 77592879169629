import styled from 'styled-components'

export const PolicyStatusStyle = `
border-radius: 12px;
padding: 12px;
display: flex;
`
export const PolicyStatusInProgress = styled.div`
  ${PolicyStatusStyle}
  background-color: #2121211a;
`
export const PolicyStatusGood = styled.div`
  ${PolicyStatusStyle}
  background-color: #1c7a171a;
  align-items: center;
  div {
    display: inline-flex;
  }
`
export const PolicyStatusBad = styled.div`
  ${PolicyStatusStyle}
  background-color: #d067021a;
`
export const PolicyStatusDesc = styled.div`
  padding: 2px 0px 2px 12px;
  width: 100%;
  h6 {
    font-family: ${({ font }) => font};
    color: #212121;
    font-size: 16px;
    line-height: 20px;
    flex-grow: 100;
  }
  p {
    font-family: ${({ fontLight }) => fontLight};
    color: #212121;
    font-size: 14px;
    line-height: 16px;
  }
`
