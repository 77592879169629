import styled from 'styled-components'
// import Breadcrumbs from '@veneer/core/dist/scripts/breadcrumbs'

//top-nav
export const TopNav = styled.div`
  padding: 0px 24px;
  align-items: center;
  display: flex;
  height: 42px;
  span {
    font-size: 14px;
    line-height: 20px;
  }
`

// smb-breadcrumbs
// export const CustomBreadcrumbs = styled(Breadcrumbs)`
//   font-size: 14px;
//   line-height: 20px;
// `
