import styled from 'styled-components'
// import Password from '@veneer/core/dist/scripts/password'
// import Button from '@veneer/core/dist/scripts/button'
// import TextBox from '@veneer/core/dist/scripts/text_box'

//SNMPV3
export const PolicyCardItem = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.showForm ? '1fr 1fr' : '1fr')};
  grid-gap: 32px;
  @media (max-width: 576px) {
    grid-template-columns: 1fr;
    grid-gap: 32px;
  }
`
export const PolicyCardItemWrap = styled.div``

export const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  label {
    margin-right: 12px;
  }
`

export const Title = styled.h4`
  margin: 2px 0;
  font-family: ${({ font }) => font};
  color: #212121;
  font-size: 16px;
  line-height: 20px;
  padding-bottom: 0px;
`

export const TitleDisable = styled(Title)`
  font-family: ${({ font }) => font};
  color: #adadad !important;
`

export const PolicyCardInfo = styled.div`
  margin-top: ${({ isInProgress, isCompliant, showForm }) =>
    !isCompliant || isInProgress || showForm ? '16px' : '0px'}}
`
//SNMPV3Form
export const PolicyFormWrap = styled.div`
  max-width: 352px;
  @media (max-width: 576px) {
    min-width: 100%;
  }
`
export const PolicyFormTitle = styled.p`
  font-family: ${({ font }) => font};
  color: ${({ isInProgress }) => (isInProgress ? '#adadad' : '#404040')};
  font-size: 16px;
  line-height: 20px;
  margin-bottom: ${({ isInProgress }) => (isInProgress ? '3px' : '6px')};
`
export const PolicyFormBody = styled.div`
  width: 352px;
  @media (max-width: 768px) {
    width: 100%;
  }
`
export const PolicyFormContent = styled.div`
  margin-bottom: 24px;
`
// const InputField = `
//   padding-bottom: 16px;
//   font-size: 16px;
//   line-height: 20px;
// `
// export const PasswordField = styled(Password)`
//   ${InputField}
//   &:last-child {
//     padding-bottom: 0px;
//   }
// `
// export const TextField = styled(TextBox)`
//   ${InputField}
// `

// export const FormButton = styled(Button)`
//   font-size: 16px;
//   line-height: 20px;
//   width: 112px;
//   height: 36px;
//   padding: 5px 40px;
// `
