import React, { useState, useContext } from 'react'
import InfoModal from '../InfoModal'
// import Accordion from '@veneer/core/dist/scripts/accordion'
// import {
//   IconHelp,
//   IconWarningAlt,
//   IconCheckCircle
// } from '@veneer/core/dist/scripts/icons'
// import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator'
// import Button from '@veneer/core/dist/scripts/button'
import { useI18n } from '@jarvis/react-portal-addons'
import {
  PolicyDetailsCardWrap,
  PolicyTitle,
  AccordionCentralArea,
  AccordionEndArea,
  AccordionContent
} from './styles'
import { publishEvent } from '../../utils/analytics'
import defaultLang from '../../assets/locale'
import { assessmentContext } from '../../contexts/assessmentContext'
import GeneralContext from '../../contexts/GeneralContext'
import { pickVeneerVersion } from '../../font'

const { en_US } = defaultLang

const PolicyDetailsCard = (props) => {
  // TODO: font change: delete
  const { fontNewEnable } = useContext(GeneralContext)
  const VeneerCore = pickVeneerVersion(fontNewEnable)
  const {
    Accordion,
    IconCheckCircle,
    IconHelp,
    IconWarningAlt,
    ProgressIndicator,
    Button
  } = VeneerCore.core
  const { fontFamilyRegular } = VeneerCore.token

  const { id, isCompliant, isInProgress, children, event } = props
  const {
    deviceEventData: { deviceUUID, productNumber }
  } = useContext(assessmentContext)
  const [active, setActive] = useState(false)
  const [showInfoModal, setShowInfoModal] = useState(false)
  const { t } = useI18n()

  const onAccordionClick = () => {
    setActive(!active)
  }

  const handleInfoModal = () => {
    setShowInfoModal(!showInfoModal)
  }

  return (
    <PolicyDetailsCardWrap
      id={`policy-details-${id}`}
      data-testid={`policy-details-${id}`}
    >
      <Accordion
        onExpand={onAccordionClick}
        onCollapse={onAccordionClick}
        data-testid={`policy-${id}`}
        items={[
          {
            header: {
              centralArea: (
                <AccordionCentralArea>
                  <PolicyTitle
                    font={fontFamilyRegular}
                    data-testid={`policy-${id}-title`}
                  >
                    {t(
                      `smbDevices.configurations.${id}.title`,
                      en_US?.smbDevices?.configurations[id]?.title
                    )}
                  </PolicyTitle>
                  <Button
                    data-testid={`policy-${id}-info`}
                    onClick={() => {
                      handleInfoModal()
                      publishEvent(event, deviceUUID, productNumber)
                    }}
                    style={{ padding: '6px', border: '0px', marginLeft: '8px' }}
                    appearance="secondary"
                    leadingIcon={
                      <IconHelp
                        data-testid={`policy-${id}-info-icon`}
                        focusable={true}
                        color={'colorHpBlue6'}
                        size={24}
                      />
                    }
                  />
                </AccordionCentralArea>
              ),
              endArea: (
                <AccordionEndArea>
                  {isInProgress ? (
                    <ProgressIndicator data-testid={`policy-${id}-progress`} />
                  ) : isCompliant ? (
                    <IconCheckCircle
                      color={'colorGreen6'}
                      filled
                      size={24}
                      data-testid={`${id}-compliant-icon`}
                    />
                  ) : (
                    <IconWarningAlt
                      size={28}
                      color="colorDarkOrange4"
                      filled
                      data-testid={`${id}-noncompliant-icon`}
                    />
                  )}
                </AccordionEndArea>
              )
            },
            expanded: active,
            content: <AccordionContent>{children}</AccordionContent>,
            id: `policy-${id}-noncompliant-icon`
          }
        ]}
        id={`policy-${id}`}
      />
      <InfoModal
        show={showInfoModal}
        id={id}
        data-testid={`${id}-info-modal`}
        handleClose={handleInfoModal}
        title={t(
          `smbDevices.configurations.${id}.title`,
          en_US?.smbDevices?.configurations[id]?.title
        )}
        content={
          id == 'configSNMPv3'
            ? [
                {
                  item: t(
                    `smbDevices.configurations.snmpModalContent`,
                    'Simple Network Management Protocol (SNMP) is a standard internet protocol for collecting and organizing information about managed devices, like this printer.'
                  )
                },
                {
                  item: t(
                    `smbDevices.configurations.${id}.infoModal.content2`,
                    en_US?.smbDevices?.configurations[id]?.infoModal?.content2
                  )
                }
              ]
            : id == 'configSNMPv1v2Write'
            ? [
                {
                  item: t(
                    `smbDevices.configurations.snmpModalContent`,
                    'Simple Network Management Protocol (SNMP) is a standard internet protocol for collecting and organizing information about managed devices, like this printer.'
                  )
                },
                {
                  item: t(
                    `smbDevices.configurations.${id}.infoModal.content2`,
                    en_US?.smbDevices?.configurations[id]?.infoModal?.content2
                  )
                }
              ]
            : [
                {
                  item: t(
                    `smbDevices.configurations.${id}.infoModal.content1`,
                    en_US?.smbDevices?.configurations[id]?.infoModal?.content1
                  )
                },
                {
                  item: t(
                    `smbDevices.configurations.${id}.infoModal.content2`,
                    en_US?.smbDevices?.configurations[id]?.infoModal?.content2
                  )
                }
              ]
        }
      />
    </PolicyDetailsCardWrap>
  )
}

export default PolicyDetailsCard
