import React, { useContext } from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
import { HeaderTitleWrap, HeaderTitle, HeaderSubtitle } from './styles'
import GeneralContext from '../../contexts/GeneralContext'
import { pickVeneerVersion } from '../../font'

const SubHeader = () => {
  const { t } = useI18n()
  // TODO: font change: delete
  const { fontNewEnable } = useContext(GeneralContext)
  const VeneerCore = pickVeneerVersion(fontNewEnable)
  const { fontFamilyRegular, fontFamilyLight } = VeneerCore.token

  return (
    <HeaderTitleWrap>
      <HeaderTitle data-testid="device-detail-title" font={fontFamilyRegular}>
        {t('smbDevices.header.title', 'Smart Security')}
      </HeaderTitle>
      <HeaderSubtitle
        data-testid="device-detail-subtitle"
        font={fontFamilyLight}
      >
        {t(
          'smbDevices.header.subTitle',
          'Check and monitor the security status of your printers.'
        )}
      </HeaderSubtitle>
    </HeaderTitleWrap>
  )
}

export default SubHeader
