import styled from 'styled-components';
import Tokens from '@veneer/tokens';

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  background-color: ${props => props.theme.colorBackground};
  overflow: hidden;
  flex-direction: row;

  @media (max-width: ${Tokens.lgMin}) {
    flex-direction: column;
  }
`;

export const PageArea = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  position: sticky;
  top: 0;
  padding: 32px 32px 0 32px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  background-color: ${props => props.theme.colorBackground};
`;

export const Separator = styled.div`
  border-bottom: #EBEBEB 1px solid;
`;

export const Content = styled.div`
  padding: 24px 32px 32px 32px;
  flex: fit-content;
  overflow-y: auto;
  @media screen and (max-width: 992px) {
    margin-bottom: 200px;
  }
`;
