// import PropTypes from 'prop-types'
import { path } from 'ramda'

const screenPath = `/ReactSmbSmartSecurity/`

const defaultCDMData = {
  activity: 'SmartSecurity-v01',
  version: '1.1.0'
}

interface Event {
  action: string
  activity: string
  screenPath: string
  screenName: string
  screenMode?: string
  controlName?: string
  controlDetail?: string
  version: string
}

export type dataCollectionMetadata = {
  associatedDeviceUuid?: string
  associatedDeviceProductNumber?: string
  xCorrelationId?: string
}

export type PublishCdmEventsOptions = {
  metadata: dataCollectionMetadata
}

export const publishEvent = (
  event: Event,
  deviceUUid?: string,
  productNumber?: string
) => {
  const publishCdmEvent = path(
    ['Shell', 'v1', 'analytics', 'publishCdmEvents'],
    window
  )

  let options
  if (deviceUUid && productNumber) {
    options = {
      metadata: {
        associatedDeviceUuid: deviceUUid,
        associatedDeviceProductNumber: productNumber
      }
    }
  }

  if (!publishCdmEvent) {
    console.error('Shell.v1.analytics.publishCdmEvents is not defined')
    return
  }

  publishCdmEvent(
    [
      {
        dateTime: new Date().toISOString(),
        eventDetailType:
          'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
        eventCategory: 'simpleUi',
        eventDetail: event,
        version: '1.4.0'
      }
    ],
    options
  )
}

// Printer List
export const PrinterListScreenDisplayed: Event = {
  action: 'ScreenDisplayed',
  screenPath,
  screenName: 'SsLandingScreen',
  ...defaultCDMData
}

export const PrinterListLastUpdatedButton: Event = {
  action: 'ControlButtonClicked',
  screenPath,
  screenName: 'SsLandingScreen',
  controlName: 'LandingScreenLastUpdatedButton',
  ...defaultCDMData
}

export const PrinterListClickedSearchBoxStarted: Event = {
  action: 'ControlButtonClicked',
  screenPath,
  screenName: 'SsLandingScreen',
  controlName: 'PrinterNameSearchTextBox',
  controlDetail: 'SearchStarted',
  ...defaultCDMData
}

export const PrinterListClickedSearchBoxCancelled: Event = {
  action: 'ControlButtonClicked',
  screenPath,
  screenName: 'SsLandingScreen',
  controlName: 'PrinterNameSearchTextBox',
  controlDetail: 'SearchCancelled',
  ...defaultCDMData
}

// Printer Details
export const PrinterDetailsScreenDisplayed: Event = {
  action: 'ScreenDisplayed',
  screenPath,
  screenName: 'SsDeviceDetail',
  ...defaultCDMData
}

export const PrinterDetailLastUpdatedButton: Event = {
  action: 'ControlButtonClicked',
  screenPath,
  screenName: 'SsDeviceDetail',
  controlName: 'SsDeviceDetailLastUpdatedButton',
  ...defaultCDMData
}

export const PrinterDetailLinkClicked: Event = {
  action: 'ControlButtonClicked',
  screenPath,
  screenName: 'SsDeviceDetail',
  controlName: 'PrinterDetailsButton',
  ...defaultCDMData
}

export const SecurityMonitorToggleEnabled: Event = {
  action: 'ControlToggleEnabled',
  screenPath,
  screenName: 'SsDeviceDetail',
  controlName: 'SmartSecurityToggle',
  ...defaultCDMData
}

export const SecurityMonitorToggleDisabled: Event = {
  action: 'ControlToggleDisabled',
  screenPath,
  screenName: 'SsDeviceDetail',
  controlName: 'SmartSecurityToggle',
  ...defaultCDMData
}

export const SecurityMonitorModalDisplayed: Event = {
  action: 'ScreenDisplayed',
  screenPath: `${screenPath}SsDeviceDetail/`,
  screenName: 'SsMonitoringOffWarning',
  ...defaultCDMData
}

export const SecurityMonitorCancelButtonClicked: Event = {
  action: 'ControlButtonClicked',
  screenPath: `${screenPath}SsDeviceDetail/`,
  screenName: 'SsMonitoringOffWarning',
  controlName: 'SsMonitoringCancelButton',
  ...defaultCDMData
}

export const SecurityMonitorTurnOffButtonClicked: Event = {
  action: 'ControlButtonClicked',
  screenPath: `${screenPath}SsDeviceDetail/`,
  screenName: 'SsMonitoringOffWarning',
  controlName: 'SsMonitoringTurnoffButton',
  ...defaultCDMData
}

export const AdminPasswordHelpButtonClicked: Event = {
  action: 'ControlHyperLinkClicked',
  screenPath,
  screenName: 'SsDeviceDetail',
  controlName: 'AdminPwdHelpLink',
  ...defaultCDMData
}

export const WifiHelpButtonClicked: Event = {
  action: 'ControlHyperLinkClicked',
  screenPath,
  screenName: 'SsDeviceDetail',
  controlName: 'WifiHelpLink',
  ...defaultCDMData
}

export const AutoFwUpdateHelpButtonClicked: Event = {
  action: 'ControlHyperLinkClicked',
  screenPath,
  screenName: 'SsDeviceDetail',
  controlName: 'AutoFwUpdateHelpLink',
  ...defaultCDMData
}

export const SnmpV1V2HelpButtonClicked: Event = {
  action: 'ControlHyperLinkClicked',
  screenPath,
  screenName: 'SsDeviceDetail',
  controlName: 'SnmpVersionOneHelpLink',
  ...defaultCDMData
}

export const SnmpV3HelpButtonClicked: Event = {
  action: 'ControlHyperLinkClicked',
  screenPath,
  screenName: 'SsDeviceDetail',
  controlName: 'SnmpVersionThreeHelpLink',
  ...defaultCDMData
}

export const AdminPwdSaveButtonClicked: Event = {
  action: 'ControlButtonClicked',
  screenPath,
  screenName: 'SsDeviceDetail',
  controlName: 'AdminPwdSaveButton',
  ...defaultCDMData
}

export const AutoFwUpdateToggleEnabled: Event = {
  action: 'ControlToggleEnabled',
  screenPath,
  screenName: 'SsDeviceDetail',
  controlName: 'AutoFwUpdateToggle',
  ...defaultCDMData
}

export const AutoFwUpdateToggleDisabled: Event = {
  action: 'ControlToggleDisabled',
  screenPath,
  screenName: 'SsDeviceDetail',
  controlName: 'AutoFwUpdateToggle',
  ...defaultCDMData
}

export const SnmpV1V2ToggleEnabled: Event = {
  action: 'ControlToggleEnabled',
  screenPath,
  screenName: 'SsDeviceDetail',
  controlName: 'SnmpVersionOneVersionTwoToggle',
  ...defaultCDMData
}

export const SnmpV1V2ToggleDisabled: Event = {
  action: 'ControlToggleDisabled',
  screenPath,
  screenName: 'SsDeviceDetail',
  controlName: 'SnmpVersionOneVersionTwoToggle',
  ...defaultCDMData
}

export const SnmpV3ToggleEnabled: Event = {
  action: 'ControlToggleEnabled',
  screenPath,
  screenName: 'SsDeviceDetail',
  controlName: 'SnmpVersionThreeToggle',
  ...defaultCDMData
}

export const SnmpV3ToggleDisabled: Event = {
  action: 'ControlToggleDisabled',
  screenPath,
  screenName: 'SsDeviceDetail',
  controlName: 'SnmpVersionThreeToggle',
  ...defaultCDMData
}

export const SnmpV3SaveButtonClicked: Event = {
  action: 'ControlButtonClicked',
  screenPath,
  screenName: 'SsDeviceDetail',
  controlName: 'SnmpSaveButton',
  ...defaultCDMData
}
