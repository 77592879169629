import styled from 'styled-components'

export const RetryCardContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 32px 0px 40px;
  border-top: 1px solid #2121211a;
`

export const RetryCardIcon = styled.div`
  height: 36px;
  width: 36px;
  background-color: #2121211a;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin-bottom: 8px;
`

export const RetryCardContent = styled.h6`
  color: #404040;
  font-family: ${({ font }) => font};
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 16px;
`

export const RetryCardButton = styled.a`
  color: #027aae;
  font-family: ${({ font }) => font};
  font-size: 16px;
  line-height: 20px;
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
`
