import React, { useState, useContext } from 'react'

// Create Context Object
export const PrinterContext = React.createContext(null)
import GeneralContext from '../contexts/GeneralContext'

// Create a provider for components to consume and subscribe to changes
export const PrinterProvider = (props) => {
  const [breadcrumbs, setBreadcrumbs] = useState([])
  const { client } = useContext(GeneralContext)
  const [connectState, setConnectState] = useState('')
  const [solutionMetadataVal, setsolutionMetadataVal] = useState({})
  const [activePrinterID, setActivePrinterID] = useState()
  const [bizModel, setBizModel] = useState(null)

  return (
    <PrinterContext.Provider
      value={{
        client,
        breadcrumbs,
        setBreadcrumbs,
        activePrinterID,
        setActivePrinterID,
        connectState,
        setConnectState,
        solutionMetadataVal,
        setsolutionMetadataVal,
        bizModel,
        setBizModel
      }}
    >
      {props.children}
    </PrinterContext.Provider>
  )
}
