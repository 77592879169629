import React, { useContext } from 'react'
import GeneralContext from '../../contexts/GeneralContext'
import { pickVeneerVersion } from '../../font'
// import Modal from '@veneer/core/dist/scripts/modal'
import { InfoModalContent } from './styles'

const InfoModal = ({ id, title, content, show, handleClose }) => {
  // TODO: font change: delete
  const { fontNewEnable } = useContext(GeneralContext)
  const VeneerCore = pickVeneerVersion(fontNewEnable)
  const { Modal } = VeneerCore.core
  const { fontFamilyRegular } = VeneerCore.token

  return (
    <Modal
      id="smb-info-modal"
      data-testid={`info-modal-${id}`}
      closeButton
      show={show}
      onClose={handleClose}
      className="info-modal"
      closeOnBlur={false}
      children={
        <InfoModalContent font={fontFamilyRegular}>
          <h2
            data-testid={`infomodal-title-${id}`}
            style={{ fontFamily: fontFamilyRegular }}
          >
            {title}
          </h2>
          {content.map((modalContent, i) => {
            return (
              <p key={i} data-testid={`infomodal-content-${id}`}>
                {modalContent.item}
              </p>
            )
          })}
        </InfoModalContent>
      }
    />
  )
}

export default InfoModal
