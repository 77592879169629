import React, { useState, useEffect, useContext } from 'react'
// TODO: font change: add
// import { useToast } from '@veneer/core'
import { PrinterContext } from '../provider/PrinterProvider'
import { assessmentContext } from '../contexts/assessmentContext'
import { assessmentHooksContext } from '../contexts/assesmentHooksContext'
import GeneralContext from '../contexts/GeneralContext'
import { getToastErrorStrings } from '../strings/Errors/index'
import utf8 from 'utf8'
import {
  ToastIDs,
  DeviceConfigurationResult,
  passwordConfigId,
  RemediationState,
  DeviceConfigurationsValue
} from '../static/consts'
import { compliancyForResult, extractPwdComplexity } from '../utils/shared'
import { useI18n } from '@jarvis/react-portal-addons'
import { AdminPwdSaveButtonClicked, publishEvent } from '../utils/analytics'
import { pickVeneerVersion } from '../font'

const withAdminPassword = (Component) => {
  const NewComponent = () => {
    // TODO: font change: delete
    const { fontNewEnable } = useContext(GeneralContext)
    const VeneerCore = pickVeneerVersion(fontNewEnable)
    const { useToast } = VeneerCore.core

    const { t } = useI18n()
    const { activePrinterID } = useContext(PrinterContext)
    const { client } = useContext(GeneralContext)
    const {
      requestAssessment,
      history,
      deviceEventData: { deviceUUID, productNumber }
    } = useContext(assessmentContext)
    const {
      useDeviceConfigurationsAssessmentHook: { configAdminPasswordSet },
      useSecurityAssessmentStatusHook,
      useRemediationHook,
      setHasCallRemediation
    } = useContext(assessmentHooksContext)
    const { remediationConfigAdminPasswordSet } = useRemediationHook
    const { isComplete } = useSecurityAssessmentStatusHook

    const defaultPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/
    const isPwdUnknownValue =
      configAdminPasswordSet.value === DeviceConfigurationsValue.unknownValue
    const assessmentDataResult = configAdminPasswordSet?.assessmentData?.result
    const isComplianceOff =
      assessmentDataResult === DeviceConfigurationResult.complianceOff

    const [isConfirmPwdCompliant, setIsConfirmPwdCompliant] = useState(true)
    const [confirmationPassword, setConfirmationPassword] = useState('')
    const [disableSave, setDisableSave] = useState(true)
    const [isInProgress, setInProgress] = useState(false)
    const [password, setPassword] = useState('')
    const [isCompliant, setIsCompliant] = useState(
      compliancyForResult(configAdminPasswordSet)
    )
    const [
      isPasswordCompliantWithRequirements,
      setIsPasswordCompliantWithRequirements
    ] = useState(true)
    const [
      passwordMeetingRequirementsMessage,
      setPasswordMeetingRequirementsMessage
    ] = useState('')
    const [
      confirmationPasswordMatchMessage,
      setConfirmationPasswordMatchMessage
    ] = useState('')

    const { addToast } = useToast()

    const getString = (subkey: string, defaultText: string): string => {
      return t(
        `smbDevices.configurations.configAdminPasswordSet.${subkey}`,
        defaultText
      )
    }

    const onInputPassword = (e) => {
      const passwordValue = e
      const encodedPassword = utf8.encode(passwordValue)
      setPassword(encodedPassword)
    }

    const onInputConfirmationPassword = (e) => {
      const confirmationPasswordValue = e
      const encodedConfirmationPassword = utf8.encode(confirmationPasswordValue)
      setConfirmationPassword(encodedConfirmationPassword)
    }

    const verifyPasswordInput = (disable = true) => {
      const { enabled, pattern } = extractPwdComplexity(
        configAdminPasswordSet,
        passwordConfigId.ADMIN_PASSWORD
      )
      if (password === '' && confirmationPassword === '') {
        disable && setIsPasswordCompliantWithRequirements(true)
        disable && setPasswordMeetingRequirementsMessage(undefined)
        disable && setConfirmationPasswordMatchMessage(undefined)
        disable && setIsConfirmPwdCompliant(true)
        return false
      }
      if (enabled) {
        const newPattern =
          pattern && pattern !== '' ? new RegExp(pattern) : defaultPattern
        if (newPattern.test(password)) {
          disable && setIsPasswordCompliantWithRequirements(true)
          disable && setPasswordMeetingRequirementsMessage(undefined)
          return true
        }
        disable && setIsPasswordCompliantWithRequirements(false)
        disable &&
          setPasswordMeetingRequirementsMessage(
            getString(
              'pwdRequirementErr',
              'The password does not meet the requirements. Try again.'
            )
          )
      } else if (defaultPattern.test(password)) {
        disable && setIsPasswordCompliantWithRequirements(true)
        disable && setPasswordMeetingRequirementsMessage(undefined)
        return true
      } else {
        disable && setIsPasswordCompliantWithRequirements(false)
        disable &&
          setPasswordMeetingRequirementsMessage(
            getString(
              'pwdRequirementErr',
              'The password does not meet the requirements. Try again.'
            )
          )
      }
      return false
    }

    const verifyConfirmationPwdInput = (disable = true) => {
      const isEqual = password === confirmationPassword
      if (password === '' && confirmationPassword === '') {
        disable && setConfirmationPasswordMatchMessage(undefined)
        disable && setIsConfirmPwdCompliant(true)
        disable && setIsPasswordCompliantWithRequirements(true)
        disable && setPasswordMeetingRequirementsMessage(undefined)
      } else if (isEqual) {
        disable && setConfirmationPasswordMatchMessage(undefined)
        disable && setIsConfirmPwdCompliant(true)
      } else {
        disable &&
          setConfirmationPasswordMatchMessage(
            getString(
              'pwdMismatchErr',
              'The passwords you entered do not match. Try again.'
            )
          )
      }
      disable && setIsConfirmPwdCompliant(isEqual)
      return isEqual
    }

    const cleanPasswordsField = () => {
      setPassword('')
      setConfirmationPassword('')
    }

    async function onSetAdminPassword(password_) {
      let err
      setHasCallRemediation(true)
      setInProgress(true)
      try {
        await client.setAdminPwdConfig(activePrinterID, password_)
        cleanPasswordsField()
      } catch (error) {
        err = error
        console.log(err)
        setInProgress(false)
        addToast({
          id: ToastIDs.setAdminPassword.NEGATIVE + activePrinterID,
          type: 'negative', //ToastTypes.NEGATIVE
          ...getToastErrorStrings(err.data, t)
        })
      }
      requestAssessment()
    }

    const verifyPassword = () => {
      publishEvent(AdminPwdSaveButtonClicked, deviceUUID, productNumber)
      if (verifyPasswordInput() && verifyConfirmationPwdInput()) {
        onSetAdminPassword(password)
      }
    }

    useEffect(() => {
      if (confirmationPassword !== '' && password !== '') {
        setDisableSave(
          !(verifyConfirmationPwdInput(false) && verifyPasswordInput(false))
        )
      } else {
        setDisableSave(true)
      }
      if (confirmationPassword !== '') {
        verifyConfirmationPwdInput()
      }
      if (password !== '') {
        verifyPasswordInput()
      } else {
        setIsPasswordCompliantWithRequirements(true)
        setPasswordMeetingRequirementsMessage(undefined)
      }
      if (password === '' && confirmationPassword === '') {
        setConfirmationPasswordMatchMessage(undefined)
        setIsConfirmPwdCompliant(true)
        setIsPasswordCompliantWithRequirements(true)
        setPasswordMeetingRequirementsMessage(undefined)
      }
    }, [configAdminPasswordSet, confirmationPassword, password])

    useEffect(() => {
      if (
        remediationConfigAdminPasswordSet.isLoading ||
        remediationConfigAdminPasswordSet.remediationState ===
          RemediationState.inprogress
      ) {
        setInProgress(true)
      } else if (isComplete) {
        setInProgress(false)
        setIsCompliant(compliancyForResult(configAdminPasswordSet))
      }
    }, [isComplete, remediationConfigAdminPasswordSet, configAdminPasswordSet])

    // useEffect(() => {
    //   setpolicyLoading(isInProgress)
    // }, [isInProgress])

    const clearPwdMsg = () => {
      setPasswordMeetingRequirementsMessage(undefined)
    }

    const clearPwdConfirmMsg = () => {
      setConfirmationPasswordMatchMessage(undefined)
    }

    const componentProps = {
      history,
      getString,
      clearPwdMsg,
      clearPwdConfirmMsg,
      setDisableSave,
      verifyPasswordInput,
      verifyConfirmationPwdInput,
      isConfirmPwdCompliant,
      confirmationPasswordMatchMessage,
      passwordMeetingRequirementsMessage,
      password,
      isPasswordCompliantWithRequirements,
      confirmationPassword,
      onInputPassword,
      onInputConfirmationPassword,
      verifyPassword,
      isInProgress,
      disableSave,
      isCompliant,
      isComplianceOff,
      isPwdUnknownValue
    }
    return <Component componentProps={componentProps} />
  }
  return NewComponent
}

export default withAdminPassword
