import React, { useContext } from 'react'
// import { IconWarningAlt } from '@veneer/core/dist/scripts/icons'
import { HelperLinks } from '../../static/consts'
import { useI18n } from '@jarvis/react-portal-addons'
// Import Context
import { assessmentContext } from '../../contexts/assessmentContext'

import {
  FailToLoadContainer,
  FailToLoadIcon,
  FailToLoadContent,
  FailToLoadButton
} from './styles'
import { pickVeneerVersion } from '../../font'
import GeneralContext from '../../contexts/GeneralContext'

const FailToLoad = () => {
  // TODO: font change: delete
  const { fontNewEnable } = useContext(GeneralContext)
  const VeneerCore = pickVeneerVersion(fontNewEnable)
  const { IconWarningAlt } = VeneerCore.core
  const { fontFamilyRegular } = VeneerCore.token

  const { t } = useI18n()
  const { onHandleReloadClick } = useContext(assessmentContext)
  const { errorMessage, showButton, linkText, showLink } = useContext(
    assessmentContext
  )

  return (
    <FailToLoadContainer>
      <FailToLoadIcon>
        <IconWarningAlt size={24} data-testid="unknown-icon" />
      </FailToLoadIcon>
      <FailToLoadContent font={fontFamilyRegular} data-testid="help-link">
        {errorMessage}
        {showLink ? <a href={HelperLinks.link2}>&nbsp;{linkText}</a> : null}
      </FailToLoadContent>
      {showButton && (
        <FailToLoadButton
          onClick={() => onHandleReloadClick()}
          font={fontFamilyRegular}
          data-testid="try-again-text"
        >
          {t('smbDevices.common.tryAgainBtnText', 'Try Again')}
        </FailToLoadButton>
      )}
    </FailToLoadContainer>
  )
}

export default FailToLoad
