import styled from 'styled-components'

//emptyTable
export const EmptyTableContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 276px);
`

// emptyTable__icon
export const EmptyTableIcon = styled.div`
  height: 36px;
  width: 36px;
  background-color: #2121211a;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin-bottom: 8px;
`

// emptyTable__content
export const EmptyTableContent = styled.h6`
  color: #404040;
  font-family: ${({ font }) => font};
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 16px;
`

// emptyTable__button
export const EmptyTableButton = styled.a`
  color: #027aae;
  font-family: ${({ font }) => font};
  font-size: 16px;
  line-height: 20px;
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
`
