import styled from 'styled-components'

export const PolicyCardItem = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`
export const PolicyCardItemWrap = styled.div`
  flex: 0 0 50%;
  flex-grow: 1;
  @media (max-width: 768px) {
    flex: 0 0 50%;
    flex-grow: 1;
  }
`
export const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  label {
    margin-right: 12px;
  }
`
export const Title = styled.h4`
  margin: 2px 0;
  font-family: ${({ font }) => font};
  color: #212121;
  font-size: 16px;
  line-height: 20px;
  padding-bottom: 0px;
`

export const TitleDisable = styled(Title)`
  font-family: ${({ font }) => font};
  color: #adadad !important;
`
export const PolicyCardInfo = styled.div`
  margin-top: ${({ isInProgress, isCompliant }) =>
    !isCompliant || isInProgress ? '16px' : '0px'}}
`
