import React from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
import {
  PrinterStatus,
  PrinterStatusType,
  PrinterStatusIndicatorGood,
  PrinterStatusIndicatorBad,
  PrinterStatusIndicatorUnknown
} from './styles'

const PrinterListStatus = (props) => {
  const { t } = useI18n()
  const { deviceStatus, deviceConnectionState, deviceMetaData } = props

  let component
  switch (deviceStatus) {
    case 'securityMonitored':
      component = (
        <div>
          <PrinterStatusIndicatorGood data-testid="device-monitored-color" />
          <span data-testid="device-monitored">
            {t(
              'smbDevices.deviceSecurityStatus.securityMonitored',
              'Monitored'
            )}
          </span>
        </div>
      )
      break
    case 'needsAttention':
      component = (
        <div>
          <PrinterStatusIndicatorBad data-testid="device-needs-attention" />
          <span data-testid="device-needs-attention">
            {t(
              'smbDevices.deviceSecurityStatus.needsAttention',
              'Needs attention'
            )}
          </span>
        </div>
      )
      break
    case 'notMonitored':
      component = (
        <div>
          <PrinterStatusIndicatorUnknown data-testid="device-unmonitored" />
          <span data-testid="device-unmonitored">
            {t('smbDevices.deviceSecurityStatus.notMonitored', 'Unmonitored')}
          </span>
        </div>
      )
      break
    default:
      component = (
        <div>
          <PrinterStatusIndicatorBad data-testid="device-needs-attention" />
          <span data-testid="device-needs-attention">
            {t(
              'smbDevices.deviceSecurityStatus.needsAttention',
              'Needs attention'
            )}
          </span>
        </div>
      )
  }
  return (
    <PrinterStatus>
      <PrinterStatusType data-testid={`device-status-${deviceConnectionState}`}>
        {deviceConnectionState === 'online' &&
        deviceMetaData &&
        Object.keys(deviceMetaData).length !== 0 ? (
          component
        ) : (
          <div>
            <span data-testid="device-nodata-available">
              {t(
                'smbDevices.deviceSecurityStatus.noDataAvailable',
                'No data available'
              )}
            </span>
          </div>
        )}
      </PrinterStatusType>
    </PrinterStatus>
  )
}
export default PrinterListStatus
