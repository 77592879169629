import React from 'react';
import PropTypes from 'prop-types';
import IconWarningAlt from '@veneer/core/dist/scripts/icons/icon_warning_alt';
import {
  ActionButton,
  Container,
  Icon,
  Message,
} from './styles';
import BaseComponent from '../base';

const ErrorWidget = ({
  dataTestId,
  labelBack,
  labelRetry,
  message,
  onBack,
  onRetry,
  inline,
}) => (
  <BaseComponent data-testid={dataTestId}>
    <Container inline={inline}>
      <Icon inline={inline}>
        <IconWarningAlt size={24} color="colorGray7" />
      </Icon>
      <Message className="label">{message}</Message>
      {onRetry && (
        <ActionButton
          onClick={onRetry}
          appearance="tertiary"
          small
        >
          {labelRetry}
        </ActionButton>
      )}
      {onBack && (
        <ActionButton
          onClick={onBack}
          appearance="tertiary"
          small
        >
          {labelBack}
        </ActionButton>
      )}
    </Container>
  </BaseComponent>
);

ErrorWidget.defaultProps = {
  dataTestId: null,
  labelBack: 'Back',
  labelRetry: 'Retry',
  message: 'Unable to load data',
  onBack: null,
  onRetry: null,
  inline: false,
};

ErrorWidget.propTypes = {
  dataTestId: PropTypes.string,
  labelBack: PropTypes.string,
  labelRetry: PropTypes.string,
  message: PropTypes.string,
  onBack: PropTypes.func,
  onRetry: PropTypes.func,
  inline: PropTypes.bool,
};

export default ErrorWidget;
