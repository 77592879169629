import React, { useState, useEffect, useContext } from 'react'
import { assessmentContext } from '../contexts/assessmentContext'
import { assessmentHooksContext } from '../contexts/assesmentHooksContext'
import { PrinterContext } from '../provider/PrinterProvider'
import GeneralContext from '../contexts/GeneralContext'
// TODO: font change: add
// import { useToast } from '@veneer/core'
import { compliancyForResult, extractPwdComplexity } from '../utils/shared'
import { getToastErrorStrings } from '../strings/Errors/index'
import utf8 from 'utf8'
import {
  ToastIDs,
  DeviceConfigurationResult,
  passwordConfigId,
  RemediationState
} from '../static/consts'
import { useI18n } from '@jarvis/react-portal-addons'
import {
  publishEvent,
  SnmpV3ToggleDisabled,
  SnmpV3SaveButtonClicked,
  SnmpV3ToggleEnabled
} from '../utils/analytics'
import { pickVeneerVersion } from '../font'

const withSnmpV3 = (Component) => {
  const NewComponent = () => {
    // TODO: font change: delete
    const { fontNewEnable } = useContext(GeneralContext)
    const VeneerCore = pickVeneerVersion(fontNewEnable)
    const { useToast } = VeneerCore.core

    const { t } = useI18n()
    const { activePrinterID } = useContext(PrinterContext)
    const { client } = useContext(GeneralContext)
    const {
      requestAssessment,
      history,
      deviceEventData: { deviceUUID, productNumber }
    } = useContext(assessmentContext)
    const {
      useDeviceConfigurationsAssessmentHook: { configSNMPv3 },
      useSecurityAssessmentStatusHook,
      useRemediationHook,
      setHasCallRemediation
    } = useContext(assessmentHooksContext)

    const { remediationConfigSNMPV3 } = useRemediationHook
    const { isComplete } = useSecurityAssessmentStatusHook
    const defaultPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/

    // SnmpV3 state
    const assessmentDataResult = configSNMPv3?.assessmentData?.result
    const isComplianceOff =
      assessmentDataResult === DeviceConfigurationResult.complianceOff
    const [isCompliant, setIsCompliant] = useState(
      compliancyForResult(configSNMPv3)
    )
    const [toggleState, setToggleState] = useState(!isCompliant)
    const [showForm, setShowForm] = useState(false)
    const [isInProgress, setInProgress] = useState(false)
    const [disableSave, setDisableSave] = useState(true)

    const [username, setUsername] = useState('')
    const [authPassphrase, setAuthPassphrase] = useState('')
    const [confirmAuthPassphrase, setConfirmAuthPassphrase] = useState('')
    const [privPassphrase, setPrivPassphrase] = useState('')
    const [confirmPrivPassphrase, setConfirmPrivPassphrase] = useState('')
    const [usernameValidationErr, setUsernameValidationErr] = useState(null)
    const [authValidationErr, setAuthValidationErr] = useState(null)
    const [privacyValidationErr, setPrivacyValidationErr] = useState(null)
    const [confirmPrivValidationErr, setConfirmPrivValidationErr] =
      useState(null)
    const [confirmAuthValidationErr, setConfirmAuthValidationErr] =
      useState(null)

    const { addToast } = useToast()

    // functions
    const getString = (subkey: string, defaultText: string): string => {
      return t(`smbDevices.configurations.configSNMPv3.${subkey}`, defaultText)
    }

    const handleToggle = (state) => {
      state
        ? publishEvent(SnmpV3ToggleEnabled, deviceUUID, productNumber)
        : publishEvent(SnmpV3ToggleDisabled, deviceUUID, productNumber)
      setToggleState(state)
      if (state && isCompliant) {
        setShowForm(true)
      } else if (isCompliant && showForm && !state) {
        setShowForm(false)
        cleanInputFields()
      } else if (!isCompliant && !state) {
        const reqPayload = {
          configSNMPv3: false
        }
        submitSnmpForm(reqPayload)
        setShowForm(false)
      }
    }

    const onInputUsername = (e) => {
      setUsername(e)
    }

    const onInputAuthPass = (e) => {
      const encodedPassword = utf8.encode(e)
      setAuthPassphrase(encodedPassword)
    }

    const onInputPrivPass = (e) => {
      const encodedPassword = utf8.encode(e)
      setPrivPassphrase(encodedPassword)
    }

    const onInputConfirmAuthPassword = (e) => {
      const encodedPassword = utf8.encode(e)
      setConfirmAuthPassphrase(encodedPassword)
    }

    const onInputConfirmPrivPassword = (e) => {
      const encodedPassword = utf8.encode(e)
      setConfirmPrivPassphrase(encodedPassword)
    }

    const cleanInputFields = () => {
      setUsername('')
      setAuthPassphrase('')
      setPrivPassphrase('')
      setConfirmAuthPassphrase('')
      setConfirmPrivPassphrase('')
      setUsernameValidationErr(null)
      setAuthValidationErr(null)
      setPrivacyValidationErr(null)
      setConfirmAuthValidationErr(null)
      setConfirmPrivValidationErr(null)
    }

    const verifyUsername = (disable = true) => {
      const { enabled, pattern } = extractPwdComplexity(
        configSNMPv3,
        passwordConfigId.SNMP_USERNAME
      )
      const defaultPattern = /^.{2,32}$/
      if (username === '' && authPassphrase === '' && privPassphrase === '') {
        disable && setUsernameValidationErr(null)
        disable && setAuthValidationErr(null)
        disable && setPrivacyValidationErr(null)
        return false
      }
      if (enabled) {
        const newPattern =
          pattern && pattern !== '' ? new RegExp(pattern) : defaultPattern
        if (newPattern.test(username)) {
          disable && setUsernameValidationErr(undefined)
          return true
        }
        disable &&
          setUsernameValidationErr(
            getString(
              'usernameRequirementErr',
              'The username does not meet the requirements. Try again.'
            )
          )
      } else if (defaultPattern.test(username)) {
        disable && setUsernameValidationErr(undefined)
        return true
      } else {
        disable &&
          setUsernameValidationErr(
            getString(
              'usernameRequirementErr',
              'The username does not meet the requirements. Try again.'
            )
          )
      }
      return false
    }

    const verifyAuthPass = (disable = true) => {
      const { enabled, pattern } = extractPwdComplexity(
        configSNMPv3,
        passwordConfigId.AUTH_PASSPHRASE
      )
      if (username === '' && authPassphrase === '' && privPassphrase === '') {
        disable && setUsernameValidationErr(null)
        disable && setAuthValidationErr(null)
        disable && setPrivacyValidationErr(null)
        return false
      }
      if (enabled) {
        const newPattern =
          pattern && pattern !== '' ? new RegExp(pattern) : defaultPattern
        if (newPattern.test(authPassphrase)) {
          disable && setAuthValidationErr(undefined)
          return true
        }
        disable &&
          setAuthValidationErr(
            getString(
              'authPwdRequirementErr',
              'The authentication passphrase does not meet the requirements. Try again.'
            )
          )
      } else if (defaultPattern.test(authPassphrase)) {
        disable && setAuthValidationErr(undefined)
        return true
      } else {
        disable &&
          setAuthValidationErr(
            getString(
              'authPwdRequirementErr',
              'The authentication passphrase does not meet the requirements. Try again.'
            )
          )
      }
      return false
    }

    const verifyConfirmAuthPass = (disable = true) => {
      const isEqual = authPassphrase === confirmAuthPassphrase
      if (authPassphrase === '' && confirmAuthPassphrase === '') {
        disable && setConfirmAuthValidationErr(undefined)
      } else if (isEqual) {
        disable && setConfirmAuthValidationErr(undefined)
      } else {
        disable &&
          setConfirmAuthValidationErr(
            getString(
              'authMismatchErr',
              'The authentication passphrase did not match.Try again.'
            )
          )
      }
      return isEqual
    }

    const verifyPrivacyPass = (disable = true) => {
      const { enabled, pattern } = extractPwdComplexity(
        configSNMPv3,
        passwordConfigId.PRIV_PASSPHRASE
      )
      if (username === '' && authPassphrase === '' && privPassphrase === '') {
        disable && setUsernameValidationErr(null)
        disable && setAuthValidationErr(null)
        disable && setPrivacyValidationErr(null)
        return false
      }
      if (enabled) {
        const newPattern =
          pattern && pattern !== '' ? new RegExp(pattern) : defaultPattern
        if (newPattern.test(privPassphrase)) {
          disable && setPrivacyValidationErr(undefined)
          return true
        }
        disable &&
          setPrivacyValidationErr(
            getString(
              'privacyRequirementErr',
              'The privacy passphrase does not meet the requirements. Try again.'
            )
          )
      } else if (defaultPattern.test(privPassphrase)) {
        disable && setPrivacyValidationErr(undefined)
        return true
      } else {
        disable &&
          setPrivacyValidationErr(
            getString(
              'privacyRequirementErr',
              'The privacy passphrase does not meet the requirements. Try again.'
            )
          )
        return false
      }
    }

    const verifyConfirmPrivPass = (disable = true) => {
      const isEqual = privPassphrase === confirmPrivPassphrase
      if (privPassphrase === '' && confirmPrivPassphrase === '') {
        disable && setConfirmPrivValidationErr(undefined)
      } else if (isEqual) {
        disable && setConfirmPrivValidationErr(undefined)
      } else {
        disable &&
          setConfirmPrivValidationErr(
            getString(
              'privacyMismatchErr',
              'The privacy passphrase did not match.Try again.'
            )
          )
      }
      return isEqual
    }

    const validateFields = () => {
      if (
        authPassphrase !== '' &&
        privPassphrase !== '' &&
        confirmAuthPassphrase !== '' &&
        confirmPrivPassphrase !== ''
      ) {
        setDisableSave(
          !(
            verifyAuthPass(false) &&
            verifyPrivacyPass(false) &&
            verifyUsername(false) &&
            verifyConfirmPrivPass(false) &&
            verifyConfirmAuthPass(false)
          )
        )
      } else {
        setDisableSave(true)
      }
      if (username !== '') {
        verifyUsername()
      }
      if (authPassphrase !== '') {
        verifyAuthPass()
      }
      if (privPassphrase !== '') {
        verifyPrivacyPass()
      }
      if (confirmAuthPassphrase !== '') {
        verifyConfirmAuthPass()
      }
      if (confirmPrivPassphrase !== '') {
        verifyConfirmPrivPass()
      }
      if (
        username === '' &&
        authPassphrase === '' &&
        privPassphrase === '' &&
        confirmAuthPassphrase === '' &&
        confirmPrivPassphrase === ''
      ) {
        setUsernameValidationErr(null)
        setAuthValidationErr(null)
        setPrivacyValidationErr(null)
        setConfirmAuthValidationErr(null)
        setConfirmPrivValidationErr(null)
      }
    }
    const clearUsernameValidationErr = () => {
      setUsernameValidationErr(undefined)
    }

    const clearAuthValidationErr = () => {
      setAuthValidationErr(undefined)
    }

    const clearPrivacyValidationErr = () => {
      setPrivacyValidationErr(undefined)
    }

    const clearConfirmAuthValidationErr = () => {
      setConfirmAuthValidationErr(undefined)
    }

    const clearConfirmPrivValidationErr = () => {
      setConfirmPrivValidationErr(undefined)
    }

    const handleForm = () => {
      publishEvent(SnmpV3SaveButtonClicked, deviceUUID, productNumber)
      if (
        verifyUsername() &&
        verifyAuthPass() &&
        verifyPrivacyPass() &&
        verifyConfirmAuthPass() &&
        verifyConfirmPrivPass()
      ) {
        const reqPayload = {
          configSNMPv3: true,
          controlSNMPv3_UserName: username,
          controlSNMPv3_AuthenticationPassword: authPassphrase,
          controlSNMPv3_PrivacyPassword: privPassphrase
        }
        submitSnmpForm(reqPayload)
      }
    }

    const submitSnmpForm = async (reqPayload) => {
      let err
      setHasCallRemediation(true)
      setInProgress(true)
      try {
        // call Remediation API
        await client.setSNMPV3Config(activePrinterID, reqPayload)
        // setShowForm(false)
        cleanInputFields()
      } catch (error) {
        err = error
        console.log(err)
        setInProgress(false)
        addToast({
          id: ToastIDs.setSNMPV3.NEGATIVE + activePrinterID,
          type: 'negative', //ToastTypes.NEGATIVE
          ...getToastErrorStrings(err.data, t)
        })
      }
      // addToast(setUpToast(err))
      await requestAssessment()
    }

    useEffect(() => {
      if (
        remediationConfigSNMPV3.isLoading ||
        remediationConfigSNMPV3.remediationState === RemediationState.inprogress
      ) {
        setInProgress(true)
      } else if (isComplete) {
        setInProgress(false)
        setIsCompliant(compliancyForResult(configSNMPv3))
        setToggleState(!compliancyForResult(configSNMPv3))
        setShowForm(false)
      }
    }, [isComplete, remediationConfigSNMPV3, configSNMPv3])

    useEffect(() => {
      validateFields()
    }, [
      username,
      authPassphrase,
      privPassphrase,
      confirmAuthPassphrase,
      confirmPrivPassphrase
    ])

    // useEffect(() => {
    //   setpolicyLoading(isInProgress)
    // }, [isInProgress])

    const componentProps = {
      history,
      isCompliant,
      isInProgress,
      disableSave,
      isComplianceOff,
      toggleState,
      showForm,
      username,
      authPassphrase,
      privPassphrase,
      confirmAuthPassphrase,
      confirmPrivPassphrase,
      usernameValidationErr,
      authValidationErr,
      privacyValidationErr,
      confirmPrivValidationErr,
      confirmAuthValidationErr,
      getString,
      setUsername,
      onInputUsername,
      onInputAuthPass,
      onInputPrivPass,
      onInputConfirmAuthPassword,
      onInputConfirmPrivPassword,
      handleToggle,
      verifyUsername,
      verifyAuthPass,
      verifyPrivacyPass,
      verifyConfirmAuthPass,
      verifyConfirmPrivPass,
      clearUsernameValidationErr,
      clearAuthValidationErr,
      clearPrivacyValidationErr,
      clearConfirmAuthValidationErr,
      clearConfirmPrivValidationErr,
      submitSnmpForm,
      handleForm
    }
    return <Component componentProps={componentProps} />
  }
  return NewComponent
}

export default withSnmpV3
