import styled from 'styled-components'
// import Password from '@veneer/core/dist/scripts/password'
// import Button from '@veneer/core/dist/scripts/button'

//AdminPassword
export const PolicyCardItem = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.showForm ? '1fr 1fr' : '1fr')};
  grid-gap: 32px;
  @media (max-width: 576px) {
    grid-template-columns: 1fr;
    grid-gap: 32px;
  }
`
export const PolicyCardItemWrap = styled.div``

//AdminPasswordForm
export const PolicyFormWrap = styled.div`
  max-width: 352px;
  @media (max-width: 576px) {
    min-width: 100%;
  }
`
export const PolicyFormTitle = styled.p`
  font-family: ${({ font }) => font};
  color: ${({ isInProgress }) => (isInProgress ? '#adadad' : '#404040')};
  font-size: 16px;
  line-height: 20px;
  margin-bottom: ${({ isInProgress }) => (isInProgress ? '3px' : '6px')};
`
export const PolicyFormBody = styled.div`
  width: 352px;
  @media (max-width: 768px) {
    width: 100%;
  }
`
export const PolicyFormContent = styled.div`
  margin-bottom: 24px;
`
// export const PasswordField = styled(Password)`
//   padding-bottom: 16px;
//   font-size: 16px;
//   line-height: 20px;
//   &:last-child {
//     padding-bottom: 0px;
//   }
// `
// export const FormButton = styled(Button)`
//   font-size: 16px;
//   line-height: 20px;
//   width: 112px;
//   height: 36px;
//   padding: 5px 40px;
// `

export const PasswordHint = styled.label`
  font-family: ${({ font }) => font};
  color: #404040;
  font-size: 16px;
  line-height: 20px;
`
