import styled from 'styled-components';
import VeneerButton from '@veneer/core/dist/scripts/button';

export const ActionButton = styled(VeneerButton)`
  && {
    margin: ${props => `${props.theme.orbitalTwo} 0 0`};
  }
`;

export const Container = styled.div`
  align-items: center;
  column-gap: ${props => (props.inline ? '8px' : '')};
  display: flex;
  height: 100%;
  flex-direction: ${props => (props.inline ? 'row' : 'column')};
  justify-content: center;
  width: 100%;
`;

export const Icon = styled.div`
  align-items: center;
  background-color: rgb(33, 33, 33, 0.1);
  border-radius: 50%;
  display: flex;
  height: 36px;
  justify-content: center;
  width: 36px;
  align-self: ${props => (props.inline ? 'flex-end' : '')};
`;

export const Message = styled.div`
  display: flex;
  padding-top: ${props => props.theme.orbitalTwo};
`;
