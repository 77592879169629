import { useCallback, useMemo, useState } from 'react';
import * as R from 'ramda';

/* Picks a path from 'data' and returns only the picked path.
 * Example:
 * const data = { a: 'a', b: 'b', custom: { name: 'My Name' } };
 * pickPath(['custom', 'name'], data);
 * |
 * |> This returns { custom: { name: 'My Name' } }.
 */
function pickPath(path, data) {
  if (path.length === 1) {
    return { [path[0]]: R.prop(path[0], data) };
  }

  return {
    [path[0]]: {
      ...R.prop(path[0], data),
      ...pickPath(path.slice(1), R.prop(path[0], data)),
    },
  };
}

// Returns only the data the step will mutate.
function pickStepData(step, data) {
  return step.data.reduce((prev, path) => ({
    ...prev,
    ...pickPath(path, data),
  }), {});
}

const useStateManager = ({
  steps,
  initialData,
}) => {
  const stepMap = useMemo(() => steps.reduce((prev, step) => ({
    ...prev,
    [step.id]: {
      ...step,
    },
  }), {}), [steps]);

  // ====
  // Setup state
  const [currentStep, setCurrentStep] = useState(stepMap.initial);
  const [previousSteps, setPreviousSteps] = useState([]);
  const [data, setData] = useState(initialData);
  const [nextStep, setNextStep] = useState(null);
  const [setButtons, setButtonsCallback] = useState(() => () => {});
  const [dataByStep, setDataByStep] = useState(steps.reduce((prev, step) => ({
    ...prev,
    [step.id]: pickStepData(step, initialData),
  }), {}));
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [onStepperNext, setOnStepperNext] = useState(() => () => {});
  // ====

  const gotoNextStep = useCallback(() => {
    const step = stepMap[nextStep];
    setCurrentStep(step);
    setPreviousSteps([...previousSteps, currentStep.id]);
  }, [currentStep.id, nextStep, previousSteps, stepMap]);

  const gotoPreviousStep = useCallback(() => {
    const lastStepId = R.last(previousSteps);
    const lastStep = stepMap[lastStepId];
    const newPreviousSteps = previousSteps.slice(0, -1);

    setPreviousSteps(newPreviousSteps);
    setCurrentStep(lastStep);

    // Rewinds the global data object, applying the changes made on each step.
    let newData = initialData;
    newPreviousSteps.forEach(prevStepId => {
      const stepData = dataByStep[prevStepId];

      stepMap[prevStepId].data.forEach(val => {
        const lens = R.lensPath(val, stepData);
        newData = R.set(lens, R.path(val, stepData), newData);
      });
    });

    setData(newData);
  }, [dataByStep, initialData, previousSteps, stepMap]);

  const setStepData = useCallback(val => {
    const newData = R.mergeDeepLeft(val, data);
    setData(newData);
    setDataByStep({
      ...dataByStep,
      [currentStep.id]: val,
      [nextStep]: pickStepData(stepMap[nextStep], newData),
    });
  }, [currentStep.id, data, dataByStep, nextStep, stepMap]);

  // Data that will be mutated by the current step.
  const stepData = useMemo(() => pickStepData(currentStep, data), [data, currentStep]);
  const setOnStepperNextWrapper = useCallback(callback => setOnStepperNext(() => callback), [setOnStepperNext]);

  return {
    showCancelModal,
    setButtons,
    setButtonsCallback: callback => setButtonsCallback(() => callback),
    stepData,
    onStepperNext,
    setOnStepperNext: setOnStepperNextWrapper,
    setNextStep,
    gotoNextStep,
    gotoPreviousStep,
    setStepData,
    currentStep,
    data,
    dataByStep,
    previousSteps,
    setShowCancelModal,
  };
};

export default useStateManager;
