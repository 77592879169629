import styled from 'styled-components'
// import Button from '@veneer/core/dist/scripts/button'

// lastUpdate__container
export const LastUpdateContainer = styled.div`
  margin-bottom: 16px;
  height: 36px;
`
// lastUpdate__wrap
export const LastUpdateWrap = styled.div`
  display: inline-flex;
  align-items: center;
`

// lastUpdate__progress
export const LastUpdateProgress = styled.div`
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
`

// lastUpdate__refresh
// export const LastUpdateRefresh = styled(Button)`
//   width: 36px;
//   height: 36px;
//   top: 0px;
//   align-items: center;
//   cursor: pointer;
// `

// lastUpdate__label
export const LastUpdateLabel = styled.div`
  font-family: ${(props) => props?.font};
  font-size: 14px;
  line-height: 20px;
  color: #404040;
  margin-left: 8px;
`
