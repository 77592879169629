import styled from 'styled-components';
import { Modal } from '@veneer/core/';
import { lg } from '../../helpers/mixins/mediaQueries';

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
`;

export const VeneerModal = styled(Modal)`
  .vn-modal--content {
    ${lg(`
      width: 635px;
    `)}
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  column-gap: 5px;
  align-items: center;
`;

export const ModalBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;
