import React, { useState, useEffect, useContext } from 'react'
// import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator'
// Import Context
import { assessmentContext } from '../../contexts/assessmentContext'
import { assessmentHooksContext } from '../../contexts/assesmentHooksContext'
import { PrinterContext } from '../../provider/PrinterProvider'
import { useAssessmentId } from '../../hooks/useAssessmentId'
import { useDeviceConfigurationsAssessment } from '../../hooks/useDeviceConfigurationsAssessment'
import { useRemediation } from '../../hooks/useRemediation'
import { useRemediationStatus } from '../../hooks/useRemediationStatus'
// Import Components
import PolicySettings from '../PolicySettings'
import PrinterDetailsCard from '../PrinterDetailsCard'
import LastUpdate from '../LastUpdate'
import SecuritySetting from '../SecuritySetting'
import { PrinterDetailBreadcrumData } from '../../static/consts'
import {
  PrinterDetailsContainer,
  PrinterSettingsWrap,
  LoaderContainer,
  Loader,
  DividerContainer,
  Divider
} from './styles'
import { useI18n } from '@jarvis/react-portal-addons'
import { useParams, useHistory } from 'react-router-dom'
import {
  PrinterDetailLastUpdatedButton,
  PrinterDetailsScreenDisplayed,
  publishEvent
} from '../../utils/analytics'
import GeneralContext from '../../contexts/GeneralContext'
import { pickVeneerVersion } from '../../font'

const PrinterDetails = (props) => {
  // TODO: font change: delete
  const { fontNewEnable } = useContext(GeneralContext)
  const VeneerCore = pickVeneerVersion(fontNewEnable)
  const { ProgressIndicator } = VeneerCore.core

  const { t } = useI18n()
  const { devID } = useParams()
  const history = props.navigation || useHistory()
  const { setBreadcrumbs, setActivePrinterID, activePrinterID } = useContext(
    PrinterContext
  )

  const [hasCallRemediation, setHasCallRemediation] = useState(false)
  const [printerName, setPrinterName] = useState('')
  const [updateLoading, setUpdateLoading] = useState(false)
  const [deviceAssLoading, setDeviceAssLoading] = useState(false)
  const [solutionMonitorState, setSolutionMonitorState] = useState('')
  const [solutionAssResult, setSolutionAssResult] = useState('')
  const [activeState, setActiveState] = useState('')
  const [deviceCardDetails, setDeviceCardDetails] = useState({
    deviceName: '',
    deviceConnectionStatus: '',
    solutionMetadata: {},
    deviceUUID: '',
    productNumber: ''
  })

  const onRemediationReloadDevice = () => {
    remediationFetch.current()
  }

  const onAssessmentReloadDevice = () => {
    childFunc.current()
  }

  const {
    assessment,
    requestAssessment,
    securityAssessmentState,
    loadingAssessment,
    setIsMonitoringInProgress,
    isMonitoringInProgress,
    errorMessage,
    showButton,
    linkText,
    showLink,
    errorToLoad,
    result
  } = useAssessmentId({
    setHasCallRemediation,
    onRemediationReloadDevice
  })

  useEffect(() => {
    setBreadcrumbs(PrinterDetailBreadcrumData(printerName, t))
  }, [printerName])

  useEffect(() => {
    if (devID !== undefined) {
      setActivePrinterID(devID)
    }
  }, [devID])

  useEffect(() => {
    if (deviceCardDetails?.deviceUUID && deviceCardDetails?.productNumber)
      publishEvent(
        PrinterDetailsScreenDisplayed,
        deviceCardDetails.deviceUUID,
        deviceCardDetails.productNumber
      )
  }, [deviceCardDetails])

  const useDeviceConfigurationsAssessmentHook = useDeviceConfigurationsAssessment(
    assessment
  )

  const useRemediationHook = useRemediation(result)
  const useRemediationStatusHook = useRemediationStatus()

  const values = {
    useDeviceConfigurationsAssessmentHook,
    useSecurityAssessmentStatusHook: result,
    userem: useRemediationHook,
    useRemediationHook: useRemediationStatusHook,
    hasCallRemediation,
    setHasCallRemediation
  }

  const childFunc = React.useRef(null)
  const remediationFetch = React.useRef(null)
  const [getDateTime, setgetDateTime] = useState('')

  const onHandleReloadClick = () => {
    childFunc.current()
    requestAssessment()
  }

  return (
    <div>
      <assessmentContext.Provider
        value={{
          history,
          assessment,
          securityAssessmentState,
          loadingAssessment,
          requestAssessment,
          isMonitoringInProgress,
          setIsMonitoringInProgress,
          hasCallRemediation,
          errorToLoad,
          errorMessage,
          showButton,
          linkText,
          showLink,
          activePrinterID,
          onHandleReloadClick,
          solutionMonitorState,
          onAssessmentReloadDevice,
          solutionAssResult,
          activeState,
          deviceEventData: {
            deviceUUID: deviceCardDetails?.deviceUUID,
            productNumber: deviceCardDetails?.productNumber
          }
        }}
      >
        <assessmentHooksContext.Provider value={values}>
          <PrinterDetailsContainer>
            <LastUpdate
              handleReloadPage={onHandleReloadClick}
              dateAndTime={getDateTime}
              updateLoader={
                updateLoading ||
                loadingAssessment ||
                hasCallRemediation ||
                isMonitoringInProgress
              }
              event={PrinterDetailLastUpdatedButton}
              deviceUUID={deviceCardDetails?.deviceUUID}
              productNumber={deviceCardDetails?.productNumber}
            />
            <PrinterDetailsCard
              deviceID={devID}
              childFunc={childFunc}
              remediationFetch={remediationFetch}
              setgetDateTime={setgetDateTime}
              setPrinterName={setPrinterName}
              setUpdateLoading={setUpdateLoading}
              setDeviceAssLoading={setDeviceAssLoading}
              setSolutionMonitorState={setSolutionMonitorState}
              setSolutionAssResult={setSolutionAssResult}
              setActiveState={setActiveState}
              deviceCardDetails={deviceCardDetails}
              setDeviceCardDetails={setDeviceCardDetails}
            />
            <PrinterSettingsWrap>
              {deviceAssLoading ||
              ((loadingAssessment || isMonitoringInProgress) &&
                !hasCallRemediation) ? (
                <LoaderContainer>
                  <SecuritySetting />
                  <Loader>
                    <ProgressIndicator />
                  </Loader>
                </LoaderContainer>
              ) : (
                <PolicySettings />
              )}
            </PrinterSettingsWrap>
            <DividerContainer>
              <Divider />
            </DividerContainer>
          </PrinterDetailsContainer>
        </assessmentHooksContext.Provider>
      </assessmentContext.Provider>
    </div>
  )
}

export default PrinterDetails
