import { path, propOr } from 'ramda';

const getOfferingOrEntitlement = (programInfosPayload, serviceId, filter = {}) => {
  const programLevel = path(['planInfo', 'programLevel'], programInfosPayload);

  if (!programLevel) {
    return null;
  }
  const programInfoMapEntry = programLevel === 'HP+' ? 'YETI' : 'UCDE';

  const entries = path(['programInfoMap', programInfoMapEntry], programInfosPayload)
    || programInfosPayload;

  if (!entries) {
    return null;
  }

  const offerings = propOr([], 'subscriptionOfferingList', entries);
  const entitlements = propOr([], 'entitlementList', entries);

  const result = [
    ...offerings,
    ...entitlements,
  ].filter(item => item.serviceId === serviceId).pop();

  if (!result) {
    return null;
  }

  if (Array.isArray(filter.states)) {
    return filter.states.includes(result.state) ? result : null;
  }

  return result;
};

export default getOfferingOrEntitlement;
