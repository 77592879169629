import styled from 'styled-components'

export const PolicyCardItem = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`
export const PolicyCardItemWrap = styled.div`
  flex: 0 0 50%;
  flex-grow: 1;
  @media (max-width: 768px) {
    flex: 0 0 50%;
    flex-grow: 1;
  }
`
