import React, { useContext } from 'react'
import {
  PolicyFormWrap,
  PolicyFormTitle,
  PolicyFormBody,
  PolicyFormContent,
  // PasswordField,
  // FormButton,
  PasswordHint
} from './styles'
import { UnsavedModal } from '../UnsavedModal'
import { withRouter } from 'react-router-dom'
import { useI18n } from '@jarvis/react-portal-addons'
import styled from 'styled-components'
import GeneralContext from '../../contexts/GeneralContext'
import { pickVeneerVersion } from '../../font'

const AdminPasswordForm = ({
  history,
  getString,
  confirmationPasswordMatchMessage,
  passwordMeetingRequirementsMessage,
  password,
  isInProgress,
  isPasswordCompliantWithRequirements,
  confirmationPassword,
  onInputPassword,
  onInputConfirmationPassword,
  verifyConfirmationPwdInput,
  verifyPasswordInput,
  verifyPassword,
  disableSave,
  printerID,
  clearPwdMsg,
  clearPwdConfirmMsg,
  isConfirmPwdCompliant
}) => {
  // TODO: font change: delete
  const { fontNewEnable } = useContext(GeneralContext)
  const VeneerCore = pickVeneerVersion(fontNewEnable)
  const { Password, Button } = VeneerCore.core
  const { fontFamilyRegular, fontFamilyLight } = VeneerCore.token

  const FormButton = styled(Button)`
    font-size: 16px;
    line-height: 20px;
    width: 112px;
    height: 36px;
    padding: 5px 40px;
    font-family: ${fontFamilyRegular};
  `

  const { t } = useI18n()
  return (
    <PolicyFormWrap>
      <PolicyFormTitle
        font={fontFamilyRegular}
        data-testid="password-noncompliant-desc"
        isInProgress={isInProgress}
      >
        {getString('formTitle', 'Admin password*')}
      </PolicyFormTitle>
      <PolicyFormBody>
        <PolicyFormContent className="custom-form">
          <Password
            value={password}
            onChange={onInputPassword}
            onFocus={clearPwdMsg}
            onBlur={verifyPasswordInput}
            error={!isPasswordCompliantWithRequirements}
            disabled={isInProgress}
            helperText={passwordMeetingRequirementsMessage}
            placeholder={getString('passwordPlaceholder', 'Set password')}
            aria-label={getString('passwordPlaceholder', 'Set password')}
            name="password"
            id={`password${printerID}`}
            // style={{ color: '#404040' }}
            data-testid="password-input"
            className="custom-input"
          />
          <Password
            value={confirmationPassword}
            onFocus={clearPwdConfirmMsg}
            onBlur={verifyConfirmationPwdInput}
            onChange={onInputConfirmationPassword}
            error={!isConfirmPwdCompliant}
            helperText={confirmationPasswordMatchMessage}
            disabled={isInProgress}
            placeholder={getString('confirmPwdPlaceholder', 'Confirm password')}
            aria-label={getString('confirmPwdPlaceholder', 'Confirm password')}
            name="password-confirmation"
            id={`password-confirmation${printerID}`}
            // style={{ color: '#404040' }}
            data-testid="confirm-password-input"
            className="custom-input"
          />
          <PasswordHint
            data-testid="admin-password-hint"
            font={fontFamilyLight}
          >
            {t(
              'smbDevices.configurations.configAdminPasswordSet.hint',
              'Use 8 or more characters with a mix of letters, numbers, and symbols.'
            )}
          </PasswordHint>
        </PolicyFormContent>
        <FormButton
          disabled={disableSave || isInProgress}
          onClick={verifyPassword}
          data-testid="admin-form-submit"
        >
          {t('smbDevices.common.formBtnText', 'Save')}
        </FormButton>
      </PolicyFormBody>
      <UnsavedModal
        history={history}
        inputValues={[password, confirmationPassword]}
      />
    </PolicyFormWrap>
  )
}

export default withRouter(AdminPasswordForm)
