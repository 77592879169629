import Tokens from '@veneer/tokens';

const CustomBreakpoints = {
  xx: '1680px',
};

export const mediaQueries = {
  xs: `(max-width: ${Tokens.xsMax})`,
  sm: `(min-width: ${Tokens.smMin})`,
  md: `(min-width: ${Tokens.mdMin})`,
  lg: `(min-width: ${Tokens.lgMin})`,
  xl: `(min-width: ${Tokens.xlMin})`,
  xx: `(min-width: ${CustomBreakpoints.xx})`,
};

export const xs = rules => (
  `
  @media ${mediaQueries.xs} {
    ${rules}
  }
  `
);

export const sm = rules => (
  `
  @media ${mediaQueries.sm} {
    ${rules}
  }
  `
);

export const md = rules => (
  `
  @media ${mediaQueries.md} {
    ${rules}
  }
  `
);

export const lg = rules => (
  `
  @media ${mediaQueries.lg} {
    ${rules}
  }
  `
);

export const xl = rules => (
  `
  @media ${mediaQueries.xl} {
    ${rules}
  }
  `
);

export const xx = rules => (
  `
  @media ${mediaQueries.xx} {
    ${rules}
  }
  `
);

export default {
  mediaQueries,
  xs,
  sm,
  md,
  lg,
  xl,
  xx,
};
