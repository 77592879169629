import { JarvisWebHttpClient } from '@jarvis/web-http'
import { DeviceCacheApiClient } from '@jarvis/web-stratus-client'

class DeviceClient {
  stack
  baseUrl
  authProvider

  constructor(stack, baseUrl, authProvider) {
    this.stack = stack
    this.baseUrl = baseUrl + '/v1'
    this.authProvider = authProvider
  }

  exportDeviceCountApi = async () => {
    const client = new DeviceCacheApiClient(this.baseUrl, this.authProvider)
    const response = await client.count({
      url: `/devices?serviceId=ws-hp.com%2Fsmartsecurity`
    })
    const totalCount = response?.headers["x-total-count"];
    return totalCount;
  }

  exportDevicesApi = async (deviceOptions) => {
    const client = new DeviceCacheApiClient(this.baseUrl, this.authProvider)
    const response = await client.list({
      params: deviceOptions
    })
    const records = {
      devices: response?.data,
      count: parseInt(response?.headers["x-total-count"] ? response?.headers["x-total-count"] : response?.data.length)
    };
    return records;
  }

  exportDeviceDetailsApi = async deviceId => {
    const client = new DeviceCacheApiClient(this.baseUrl, this.authProvider);
    const response = await client.show(deviceId);
    const records = response?.data;
    return records;
  };

  isStatusCodeOk(status) {
    return status > 199 && status < 400
  }
}

export default DeviceClient
