import React, { useState, useEffect, useContext } from 'react'
import {
  DeviceConfigurationsValue,
  DeviceConfigurationResult
} from '../static/consts'
import { compliancyForResult } from '../utils/shared'
import { assessmentHooksContext } from '../contexts/assesmentHooksContext'

const withConfigWifiNetwork = (Component) => {
  const NewComponent = () => {
    const { useDeviceConfigurationsAssessmentHook } = useContext(
      assessmentHooksContext
    )
    const { configWifiNetwork } = useDeviceConfigurationsAssessmentHook
    const assessmentDataResult = configWifiNetwork.assessmentData.result
    const isUnknown =
      configWifiNetwork.value === DeviceConfigurationsValue.unknownValue
    const isComplianceOff =
      assessmentDataResult === DeviceConfigurationResult.complianceOff

    const [isCompliant, setIsCompliant] = useState(
      compliancyForResult(configWifiNetwork)
    )

    useEffect(() => {
      const newState = compliancyForResult(configWifiNetwork)
      setIsCompliant(newState)
    }, [configWifiNetwork])

    const componentProps = {
      configWifiNetwork,
      isCompliant,
      isUnknown,
      isComplianceOff
    }

    return <Component componentProps={componentProps} />
  }

  return NewComponent
}

export default withConfigWifiNetwork
