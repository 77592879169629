import styled from 'styled-components'

// smb-pd-card__container
export const DeviceDetailsContainer = styled.div`
  flex: 1;
`

// smb-pd-card__desc
export const DeviceDetailsWrapper = styled.div`
  display: flex;
  @media (max-width: 767px) {
    border-right: none;
    border-bottom: 2px solid #2121211a;
    padding-right: 0px;
    padding-bottom: 32px;
  }
  @media (max-width: 320px) {
    flex-wrap: wrap;
  }
`

// smb-pd-card__imageWrap
export const DeviceImageWrapper = styled.div`
  width: 128px;
  height: 128px;
  max-width: 128px;
  max-height: 128px;
`

// smb-pd-card__content
export const DeviceDetails = styled.div`
  margin-left: 24px;
  width: 100%;
`

// smb-pd-card__title
export const DeviceFriendlyName = styled.h6`
  font-family: ${(props) => props?.font};
  color: #212121;
  font-size: 20px;
  line-height: 28px;
`

// smb-pd-card__location
export const DeviceLocation = styled.p`
  font-family: ${(props) => props?.font};
  color: #212121;
  font-size: 16px;
  line-height: 24px;
`

// smb-pd-card__name
export const DeviceName = styled(DeviceLocation)`
  font-family: ${(props) => props?.font};
  margin-top: 4px;
`

// smb-pd-card__statusWrap
export const DeviceStatusList = styled.ul`
  margin: 16px 0px;
`

// smb-pd-card__status
export const DeviceStatus = styled.li`
  display: flex;
  align-items: center;
  height: 24px;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`

// smb-pd-card__statusValue
export const DeviceStatusLabel = styled.h6`
  font-family: ${(props) => props?.font};
  color: #212121;
  font-size: 16px;
  line-height: 20px;
  margin-left: 8px;
  flex-grow: 100;
`

// smb-pd-card__link
export const RedirectButton = styled.div`
  font-family: ${(props) => props?.font};
  display: inline-flex;
  align-items: center;
  padding: 6px 8px 6px 12px;
  outline: none;
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
`

// smb-pd-card__link
export const RedirectButtonIcon = styled.div`
  display: flex;
  align-items: center;
`
