import React, { useContext } from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
// Import Veneer Component
// import { IconWarningAlt } from '@veneer/core/dist/scripts/icons'
// Import Styled Components
import {
  EmptyTableContainer,
  EmptyTableIcon,
  EmptyTableContent,
  EmptyTableButton
} from './styles'
import GeneralContext from '../../contexts/GeneralContext'
import { pickVeneerVersion } from '../../font'

const EmptyTable = (props) => {
  // TODO: font change: delete
  const { fontNewEnable } = useContext(GeneralContext)
  const VeneerCore = pickVeneerVersion(fontNewEnable)
  const { IconWarningAlt } = VeneerCore.core
  const { fontFamilyRegular } = VeneerCore.token

  const { t } = useI18n()
  const { handleReloadPage } = props
  return (
    <EmptyTableContainer>
      <EmptyTableIcon>
        <IconWarningAlt size={24} data-testid="empty-warning-icon" />
      </EmptyTableIcon>
      <EmptyTableContent font={fontFamilyRegular} data-testid="load-err">
        {t('smbDevices.emptyTable.message', 'Unable to load data')}
      </EmptyTableContent>
      <EmptyTableButton
        onClick={() => handleReloadPage()}
        font={fontFamilyRegular}
        data-testid="retry"
      >
        {t('smbDevices.emptyTable.btnLabel', 'Retry')}
      </EmptyTableButton>
    </EmptyTableContainer>
  )
}

export default EmptyTable
