import React from 'react'

// Import Components
import StatusNotification from '../StatusNotification'
import MonitorSettings from '../MonitorSettings'

import { DeviceDetailsContainer } from './styles'

// Functional Component
const MonitorStatus = (props) => {
  const {
    deviceConnectionStatus,
    solutionMetadata,
    solutionMetaResult,
    isActive,
    outOfComplaint
  } = props
  return (
    <DeviceDetailsContainer>
      <div>
        {deviceConnectionStatus === 'online' &&
        solutionMetadata &&
        Object.keys(solutionMetadata).length !== 0 ? (
          <MonitorSettings isActive={isActive} />
        ) : null}
        <StatusNotification
          deviceConnectionStatus={deviceConnectionStatus}
          solutionMetadata={solutionMetadata}
          solutionMetaResult={solutionMetaResult}
          isActive={isActive}
          outOfComplaint={outOfComplaint}
        />
      </div>
    </DeviceDetailsContainer>
  )
}
export default MonitorStatus
