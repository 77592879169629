import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import {
  Container,
} from './styles';

const Overlay = ({
  active,
  children,
  onFadeIn,
  onFadeOut,
  container,
}) => {
  const [fade, setFade] = useState('fadeIn');
  const [show, setShow] = useState(active);

  const onFadeEnd = () => {
    if (fade === 'fadeOut') {
      setShow(false);
      onFadeOut();
    } else if (fade === 'fadeIn') {
      onFadeIn();
    }
  };

  useEffect(() => {
    setFade(active ? 'fadeIn' : 'fadeOut');
    if (active) {
      setShow(true);
    }
  }, [active, setFade]);

  return createPortal(
    <Container
      show={show}
      fade={fade}
      onAnimationEnd={onFadeEnd}
    >
      {active && children}
    </Container>, container,
  );
};

Overlay.defaultProps = {
  active: false,
  onFadeIn: () => {},
  onFadeOut: () => {},
  container: document.body,
};

Overlay.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.element.isRequired,
  onFadeIn: PropTypes.func,
  onFadeOut: PropTypes.func,
  container: PropTypes.instanceOf(Element),
};

export default Overlay;
