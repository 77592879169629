import styled from 'styled-components'

export const PolicyDetailsCardWrap = styled.div`
  border-top: 1px solid #2121211a;
  padding: 0px 16px;
  div[data-testid='ss-accordion'] > div {
    border-top-width: 0px;
    border-bottom-width: 0px;
  }

  .vn-animated-height__wrapper-inner > div {
    margin: 0px;
    padding: 0px;
  }
`
export const PolicyTitle = styled.div`
  font-family: ${({ font }) => font};
  font-size: 16px;
  line-height: 20px;
`
export const AccordionCentralArea = styled.div`
  display: flex;
  align-items: center;
`
export const AccordionEndArea = styled.div`
  display: inline-flex;
  padding-left: 14px;
`
export const AccordionContent = styled.div`
  padding: 24px 7px;
`
