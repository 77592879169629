import React, { useContext } from 'react'
import PolicyDetailsCard from '../PolicyDetailsCard'
import PolicyStatusCard from '../PolicyStatusCard'
import { DeviceConfigurationsId } from '../../static/consts'
// import Toggle from '@veneer/core/dist/scripts/toggle'
import withSnmpV1V2 from '../../hocs/withSnmpV1V2'
import {
  PolicyCardItem,
  PolicyCardItemWrap,
  TitleWrap,
  PolicyCardInfo,
  Title,
  TitleDisable
} from './styles'
import { SnmpV1V2HelpButtonClicked } from '../../utils/analytics'
import GeneralContext from '../../contexts/GeneralContext'
import { pickVeneerVersion } from '../../font'

const SnmpV1V2 = ({ componentProps }) => {
  // TODO: font change: delete
  const { fontNewEnable } = useContext(GeneralContext)
  const VeneerCore = pickVeneerVersion(fontNewEnable)
  const { Toggle } = VeneerCore.core
  const { fontFamilyRegular } = VeneerCore.token

  const {
    getString,
    isComplianceOff,
    onChangeSnmpV1V2,
    toggleState,
    isCompliant,
    isInProgress
  } = componentProps

  const componentProp = {
    ...componentProps,
    id: DeviceConfigurationsId.CONFIG_SNMP_V1V2,
    event: SnmpV1V2HelpButtonClicked
  }
  return (
    !isComplianceOff && (
      <PolicyDetailsCard {...componentProp}>
        <PolicyCardItem>
          <PolicyCardItemWrap>
            <TitleWrap>
              <Toggle
                id="snmpV1V2"
                disabled={isInProgress}
                onChange={onChangeSnmpV1V2}
                on={toggleState}
                data-testid="snmpv1v2-toggle"
              />
              {isInProgress ? (
                <TitleDisable
                  data-testid="snmpv1v2-title"
                  font={fontFamilyRegular}
                >
                  {getString('toggleLabel', 'Enable SNMP V1/V2 (Write)')}
                </TitleDisable>
              ) : (
                <Title data-testid="snmpv1v2-title" font={fontFamilyRegular}>
                  {getString('toggleLabel', 'Enable SNMP V1/V2 (Write)')}
                </Title>
              )}
            </TitleWrap>
            <PolicyCardInfo
              isCompliant={isCompliant}
              isInProgress={isInProgress}
            >
              <PolicyStatusCard
                isCompliant={isCompliant}
                showCompliantStatus={false}
                isInProgress={isInProgress}
                id={componentProp.id}
              />
            </PolicyCardInfo>
          </PolicyCardItemWrap>
        </PolicyCardItem>
      </PolicyDetailsCard>
    )
  )
}

export default withSnmpV1V2(SnmpV1V2)
