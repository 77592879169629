import styled from 'styled-components'

const DeviceNotifyCard = styled.div`
  border-radius: 12px;
  padding: 12px;
  display: grid;
  grid-template-columns: 24px 1fr;
  margin-top: 12px;
`

// __notify __notify--progress
export const DeviceNotifyLoader = styled(DeviceNotifyCard)``

// __notify __notify--good
export const DeviceMonitorNotifyCard = styled(DeviceNotifyCard)`
  background-color: #1c7a171a;
`

// __notify __notify--bad __notify--center
export const DeviceAttentionNotifyCard = styled(DeviceNotifyCard)`
  background-color: #d067021a;
  align-items: center;
`

// __notify __notify--not
export const DeviceNotMonitorNotifyCard = styled(DeviceNotifyCard)`
  background-color: #2121211a;
`

// __notify __notify--bad
export const DeviceUnknownNotifyCard = styled(DeviceNotifyCard)`
  background-color: #d067021a;
`

// smb-pd-card__not-connect-card
export const DeviceNotConnectCard = styled.div`
  display: flex;
  justify-content: center;
  color: #737373;
  font-family: ${(props) => props?.font};
  font-size: 16px;
  line-height: 20px;
`

// smb-pd-card__notifyDesc
export const DeviceStatusNotifyWrap = styled.div`
  padding: 2px 0px;
  padding-left: 12px;
`

// smb-pd-card__notifyHead
export const DeviceStatusNotifyTitle = styled.h6`
  font-family: ${(props) => props?.font};
  color: #212121;
  font-size: 16px;
  line-height: 20px;
`

// smb-pd-card__notifyContent
export const DeviceStatusNotifyBody = styled.p`
  font-family: ${(props) => props?.font};
  color: #212121;
  font-size: 14px;
  line-height: 16px;
`
