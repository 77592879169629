import { useState, useEffect } from 'react'
import { DeviceConfigurationsId } from '../static/consts'
// import { assessmentContext } from '../contexts/assessmentContext'

export const useDeviceConfigurationsAssessment = (assessment) => {
  let configurations = []
  // const { assessment } = useContext(assessmentContext)
  if (
    assessment &&
    assessment?.deviceconfigurationsAssessment &&
    assessment?.deviceconfigurationsAssessment?.configurations
  ) {
    configurations = assessment?.deviceconfigurationsAssessment?.configurations
  }

  const getConfigurationById = (deviceConfigurationId) => {
    const selectedConfiguration = configurations.find(
      (conf) => conf.id === deviceConfigurationId
    )

    if (
      selectedConfiguration &&
      selectedConfiguration.assessmentData &&
      selectedConfiguration.assessmentData.result
    ) {
      const { result } = selectedConfiguration.assessmentData
      selectedConfiguration.assessmentData.result = result.toLowerCase()
    }

    const unknownConfiguration = {
      id: deviceConfigurationId,
      value: 'unknown',
      assessmentData: {
        result: 'unknown'
      }
    }
    return selectedConfiguration || unknownConfiguration
  }

  const configs = () => {
    const configAdminPasswordSet = getConfigurationById(
      DeviceConfigurationsId.CONFIG_ADMIN_PASSWORD_SET
    )
    const configWifiNetwork = getConfigurationById(
      DeviceConfigurationsId.CONFIG_WIFI_NETWORK
    )
    const configAutoFWUpdate = getConfigurationById(
      DeviceConfigurationsId.CONFIG_AUTO_FW_UPDATE
    )
    const configSNMPv1v2Write = getConfigurationById(
      DeviceConfigurationsId.CONFIG_SNMP_V1V2
    )
    const configSNMPv3 = getConfigurationById(
      DeviceConfigurationsId.CONFIG_SNMP_V3
    )

    return {
      configAdminPasswordSet,
      configWifiNetwork,
      configAutoFWUpdate,
      configSNMPv1v2Write,
      configSNMPv3
    }
  }

  const [deviceConfigurations, setDeviceConfigurations] = useState(configs())

  useEffect(() => {
    const newConfigurations = configs()

    setDeviceConfigurations(newConfigurations)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessment])

  return deviceConfigurations
}
