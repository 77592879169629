import styled from 'styled-components'

export const MonitorStatusInfoWrap = styled.div`
  display: flex;
  align-items: center;
`
export const MonitorStatusInfo = styled.h4`
  font-family: ${(props) => props?.font};
  color: ${(props) => props.Color};
  font-size: 16px;
  line-height: 20px;
  padding-left: 12px;
`
