import styled from 'styled-components';

export const Container = styled.div`
  @keyframes fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes fade-out {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  display: flex;
  flex-direction: column;
  position: fixed;
  display: flex;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #F8F8F8;
  z-index: 1200;
  animation: ${props => (props.fade === 'fadeIn' ? 'fade-in 125ms' : 'fade-out 125ms')};
  display: ${props => (props.show ? '' : 'none')};
  overflow: hidden;
`;

export default { Container };
