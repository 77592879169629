import React, { useContext } from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
// TODO: font change: add
// import IconReload from '@veneer/core/dist/scripts/icons/icon_reload'
// import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator'
// Global Custom Methods
import { getDateAndTime } from '../../static/consts'
// Style component
import {
  LastUpdateContainer,
  LastUpdateWrap,
  LastUpdateProgress,
  LastUpdateLabel
  // LastUpdateRefresh
} from './styles'
import { publishEvent } from '../../utils/analytics'
import GeneralContext from '../../contexts/GeneralContext'
import { pickVeneerVersion } from '../../font'
import styled from 'styled-components'
interface PageProps {
  updateLoader: Boolean
  handleReloadPage: any
  dateAndTime: any
  event: any
  deviceUUID?: string
  productNumber?: string
}

const LastUpdate = (props) => {
  const {
    updateLoader,
    handleReloadPage,
    dateAndTime,
    event,
    deviceUUID,
    productNumber
  }: PageProps = props
  const { t } = useI18n()
  // TODO: font change: delete
  const { fontNewEnable } = useContext(GeneralContext)
  const VeneerCore = pickVeneerVersion(fontNewEnable)
  const { IconReload, ProgressIndicator, Button } = VeneerCore.core
  const { fontFamilyLight } = VeneerCore.token
  const LastUpdateRefresh = styled(Button)`
    width: 36px;
    height: 36px;
    top: 0px;
    align-items: center;
    cursor: pointer;
  `

  return (
    <LastUpdateContainer>
      {updateLoader ? (
        <LastUpdateWrap>
          <LastUpdateProgress>
            <ProgressIndicator />
          </LastUpdateProgress>
          <LastUpdateLabel
            font={fontFamilyLight}
            data-testid="last-status-updating"
          >
            {t('smbDevices.lastUpdate.progressLabel', 'Updating status...')}
          </LastUpdateLabel>
        </LastUpdateWrap>
      ) : (
        <LastUpdateWrap>
          <LastUpdateRefresh
            appearance="tertiary"
            style={{ color: '#027aae' }}
            leadingIcon={
              <IconReload
                size={24}
                color={'colorHpBlue6'}
                data-testid="last-update-icon"
              />
            }
            onClick={() => {
              handleReloadPage()
              deviceUUID && productNumber
                ? publishEvent(event, deviceUUID, productNumber)
                : publishEvent(event)
            }}
          />
          <LastUpdateLabel
            font={fontFamilyLight}
            data-testid="last-updated-status"
          >
            {t('smbDevices.lastUpdate.label', 'Last updated')}&nbsp;
            {dateAndTime ? dateAndTime : getDateAndTime(t)}
          </LastUpdateLabel>
        </LastUpdateWrap>
      )}
    </LastUpdateContainer>
  )
}

export default LastUpdate
