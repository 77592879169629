import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tokens from '@veneer/tokens';
import useScrollInfo from 'react-element-scroll-hook';
import useMediaQuery from '../../hooks/useMediaQuery';
import {
  Container,
  Content,
  Header,
  PageArea,
  Separator,
} from './styles';
import UnsavedChangesModal from '../UnsavedChangesModal';
import Footer from './Footer';
import Stepper from './Stepper';
import { WizardContext } from './context';
import useStateManager from './useStateManager';

const Wizard = ({
  footer,
  i18n,
  initialData,
  onCancel,
  onExit,
  onFinish,
  stepper,
  steps,
  title,
  unsavedChanges,
}) => {
  const tablet = useMediaQuery(`(max-width: ${Tokens.lgMin}`);
  const [scrollInfo, setRef] = useScrollInfo();
  const [shadow, setShadow] = useState(false);

  const {
    currentStep,
    data,
    dataByStep,
    gotoNextStep,
    gotoPreviousStep,
    onStepperNext,
    previousSteps,
    setButtons,
    setButtonsCallback,
    setNextStep,
    setOnStepperNext,
    setShowCancelModal,
    setStepData,
    showCancelModal,
    stepData,
  } = useStateManager({
    initialData,
    stepper,
    steps,
  });

  useEffect(() => {
    setShadow(!!scrollInfo.y.total);
  }, [scrollInfo]);

  return (
    <WizardContext.Provider value={{
      setButtons,
      stepData,
      setNextStep,
      gotoNextStep,
      setStepData,
      setShowCancelModal,
      gotoPreviousStep,
      setOnStepperNext,
      i18n,
    }}
    >
      <Container>
        <Stepper
          onStepperBack={gotoPreviousStep}
          onStepperNext={onStepperNext}
          stepNumber={previousSteps.length}
          steps={stepper}
          title={title}
        />

        <PageArea>
          {!tablet && (
            <Header>
              <p className="subtitle-large">{currentStep.label}</p>
              <Separator />
            </Header>
          )}
          <Content ref={setRef}>
            <currentStep.component
              data={data}
              gotoNextStep={() => {
                footer?.onNext();
                gotoNextStep();
              }}
              gotoPreviousStep={gotoPreviousStep}
              initialData={dataByStep[currentStep.id]}
              onCancel={() => setShowCancelModal(true)}
              onFinish={onFinish}
              previousSteps={previousSteps}
              setNextStep={setNextStep}
              setStepData={setStepData}
              stepData={stepData}
            />
          </Content>

          <Footer
            i18n={i18n.navigation}
            setButtonsCallback={setButtonsCallback}
            shadow={shadow}
            stepId={currentStep.id}
            {...footer}
          />
        </PageArea>

        <UnsavedChangesModal
          onCancel={() => {
            unsavedChanges?.onCancel?.(currentStep.id);
            setShowCancelModal(false);
          }}
          onLeave={() => {
            unsavedChanges?.onLeave?.(currentStep.id);
            onCancel();
            onExit();
          }}
          onShow={() => unsavedChanges?.onShow?.(currentStep.id)}
          show={showCancelModal}
          i18n={i18n.cancelModal}
        />
      </Container>
    </WizardContext.Provider>
  );
};

Wizard.defaultProps = {
  footer: {},
  i18n: {
    navigation: {
      apply: 'Apply',
      back: 'Back',
      cancel: 'Cancel',
      next: 'Next',
    },
    cancelModal: {
      body: `Are you sure you want to leave this page?
        Your changes have not been saved and will be discarded if you leave now.`,
      cancel: 'Cancel',
      leave: 'Leave',
      title: 'Unsaved Changes',
    },
  },
  onCancel: () => {},
  onExit: () => {},
  onFinish: () => {},
  unsavedChanges: {},
};

Wizard.propTypes = {
  footer: PropTypes.shape({
    onApply: PropTypes.func,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onNext: PropTypes.func,
  }),
  i18n: PropTypes.shape({
    cancelModal: PropTypes.shape({
      body: PropTypes.string,
      cancel: PropTypes.string,
      leave: PropTypes.string,
      title: PropTypes.string,
    }),
    navigation: PropTypes.shape({
      apply: PropTypes.string,
      back: PropTypes.string,
      cancel: PropTypes.string,
      next: PropTypes.string,
    }),
  }),
  initialData: PropTypes.objectOf(PropTypes.any).isRequired,
  onCancel: PropTypes.func,
  onExit: PropTypes.func,
  onFinish: PropTypes.func,
  title: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape({
    component: PropTypes.elementType.isRequired,
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  stepper: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
  })).isRequired,
  unsavedChanges: PropTypes.shape({
    onCancel: PropTypes.func,
    onLeave: PropTypes.func,
    onShow: PropTypes.func,
  }),
};

export default React.memo(Wizard);
