import React from 'react';
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator';
import PropTypes from 'prop-types';
import { Container } from './styles';

const LoaderWidget = ({
  className,
  dataTestId,
  fullScreen,
}) => (
  <Container
    className={className}
    data-testid={dataTestId}
    fullScreen={fullScreen}
  >
    <ProgressIndicator />
  </Container>
);

LoaderWidget.defaultProps = {
  className: null,
  dataTestId: null,
  fullScreen: false,
};

LoaderWidget.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fullScreen: PropTypes.bool,
};

export default LoaderWidget;
