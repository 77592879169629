import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider as VeneerThemeProvider, useTheme } from '@veneer/theme';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

const Wrapper = ({ children }) => {
  const theme = useTheme();
  return (
    <StyledThemeProvider theme={theme}>
      {children}
    </StyledThemeProvider>
  );
};

const BaseComponent = props => (
  <VeneerThemeProvider>
    <Wrapper {...props} />
  </VeneerThemeProvider>
);

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BaseComponent;
