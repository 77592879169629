import styled from 'styled-components';
import Tokens from '@veneer/tokens';

export const Container = styled.div`
  background-color: white;
  width: 280px;
  min-width: 280px;
  outline: 2px solid #EBEBEB;
  padding: ${props => props.theme.orbitalEight};
  z-index: 2;
  float: left;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  row-gap: 48px;

  @media (max-width: ${Tokens.lgMin}) {
    width: 100%;
    align-items: center;
    justify-content: center;
    row-gap: ${props => props.theme.orbitalSix};
    min-width: unset;
  }
`;

export default { Container };
