class SSException extends Error {
  constructor(message, data) {
    super(message)
    this.name = this.constructor.name
    Error.captureStackTrace(this, this.constructor)
    this.data = data
  }
}

export default SSException

export const defaultExceptionData = {
  httpCode: '500',
  errorCode: 'UnknownError',
  description: 'Unknown error'
}
