import React, { useContext } from 'react'
// import { IconWarningAlt } from '@veneer/core/dist/scripts/icons'
import { useI18n } from '@jarvis/react-portal-addons'
// Import Context
import { useSecurityMonitor } from '../../hooks/useSecurityMonitor'
import { PrinterContext } from '../../provider/PrinterProvider'

import {
  RetryCardContainer,
  RetryCardIcon,
  RetryCardContent,
  RetryCardButton
} from './styles'
import GeneralContext from '../../contexts/GeneralContext'
import { pickVeneerVersion } from '../../font'

// Functional Component
const RetryCard = () => {
  // TODO: font change: delete
  const { fontNewEnable } = useContext(GeneralContext)
  const VeneerCore = pickVeneerVersion(fontNewEnable)
  const { IconWarningAlt } = VeneerCore.core
  const { fontFamilyRegular } = VeneerCore.token

  const { t } = useI18n()
  const { activePrinterID, client } = useContext(PrinterContext)
  const { toggleMonitoring } = useSecurityMonitor({
    client,
    activePrinterID
  })
  return (
    <RetryCardContainer>
      <RetryCardIcon>
        <IconWarningAlt size={24} data-testid="unknown-icon" />
      </RetryCardIcon>
      <RetryCardContent font={fontFamilyRegular}>
        {t(
          'smbDevices.retry.message2',
          'Unable to load data. Connect your device to the network and try again.'
        )}
      </RetryCardContent>
      <RetryCardButton
        className="retryCard__button"
        onClick={() => toggleMonitoring(true)}
        data-testid="unknown-link"
        font={fontFamilyRegular}
      >
        {t('smbDevices.common.tryAgainBtnText', 'Try Again')}
      </RetryCardButton>
    </RetryCardContainer>
  )
}

export default RetryCard
