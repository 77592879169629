import styled from 'styled-components'

export const InfoModalContent = styled.div`
  padding: 0px 7px;
  h2 {
    marginbottom: 20px;
    paddingbottom: 0px;
  }
  p {
    font-family: ${({ font }) => font};
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 28px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
`
