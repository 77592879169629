import React, { useContext } from 'react'
// Import Context
import { assessmentContext } from '../../contexts/assessmentContext'
import { PrinterContext } from '../../provider/PrinterProvider'
// Import Components
import SecuritySetting from '../SecuritySetting'
import AdminPassword from '../../components/AdminPassword'
import SecureWifi from '../SecureWifi'
// import AutoFWUpdate from '../AutoFWUpdate'
import SnmpV1V2 from '../SnmpV1V2'
import SnmpV3 from '../SnmpV3'
import RetryCard from '../RetryCard'
import FailToLoad from '../FailToLoad'
import { SecurityAssessmentState, AssessmentResult } from '../../static/consts'

const PolicySettings = () => {
  const { connectState, solutionMetadataVal } = useContext(PrinterContext)

  const { errorToLoad, solutionMonitorState, solutionAssResult, activeState } =
    useContext(assessmentContext)

  return (
    <>
      {connectState === 'online' &&
      solutionMetadataVal &&
      Object.keys(solutionMetadataVal).length !== 0 ? (
        activeState === 'true' ? (
          solutionMonitorState.toLowerCase() ==
          SecurityAssessmentState.enabled ? (
            solutionAssResult == AssessmentResult.unknown ? (
              <>
                <SecuritySetting />
                <RetryCard />
              </>
            ) : !errorToLoad ? (
              <>
                <SecuritySetting />
                <AdminPassword />
                <SecureWifi />
                {/* <AutoFWUpdate /> */}
                <SnmpV1V2 />
                <SnmpV3 />
              </>
            ) : (
              <FailToLoad />
            )
          ) : null
        ) : null
      ) : (
        <>
          <SecuritySetting />
          <RetryCard />
        </>
      )}
    </>
  )
}

export default PolicySettings
