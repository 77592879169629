import { useState, useContext, useEffect } from 'react'
import { PrinterContext } from '../provider/PrinterProvider'
import {
  SecurityAssessmentState,
  AssessmentStatus,
  DeviceConfigurationsId,
  DeviceConfigurationResult,
  ToastIDs,
  ToastTypes,
  RemediationResults
} from '../static/consts'
import { getErrorMessage } from '../strings/Errors/index'
import { assessmentStatus } from '../utils/shared'
// TODO: font change: add
// import { useToast } from '@veneer/core'
import { useDeviceConfigurationsAssessment } from '../utils/deviceConfig'
import { useI18n } from '@jarvis/react-portal-addons'
import { useParams } from 'react-router-dom'
import GeneralContext from '../contexts/GeneralContext'
import { getValuesFromAssessment } from './useSecurityAssessmentStatus'
import { pickVeneerVersion } from '../font'

export const useAssessmentId = ({
  setHasCallRemediation,
  onRemediationReloadDevice
}) => {
  // TODO: font change: delete
  const { fontNewEnable } = useContext(GeneralContext)
  const VeneerCore = pickVeneerVersion(fontNewEnable)
  const { useToast } = VeneerCore.core

  const { t } = useI18n()
  const { devID } = useParams()
  const [assessment, setAssessment] = useState({})
  const [loadingAssessment, setloadingAssessment] = useState(true)
  const [securityAssessmentState, setSecurityAssessmentState] = useState()
  const [isMonitoringInProgress, setIsMonitoringInProgress] = useState(false)
  const { client } = useContext(PrinterContext)
  const { remediateComplete, setRemediateComplete } = useContext(GeneralContext)
  const RETRY_TIMEOUT = 3000
  const [, setTimerId] = useState(undefined)
  const [errorMessage, setErrorMessage] = useState('')
  const [showButton, setShowButton] = useState(false)
  const [linkText, setLinkText] = useState('')
  const [showLink, setShowLink] = useState(false)
  const [errorToLoad, setErrorToLoad] = useState(false)
  const [result, setResult] = useState({})

  let hasRequestInProgress = false
  let assessmentUpdateRequired = false
  const { addToast } = useToast()

  const fetchAssessmentByID = async (toastCallBack?: any) => {
    setloadingAssessment(true)
    hasRequestInProgress = true
    assessmentUpdateRequired = false
    try {
      const result = await client.getSecurityAssessment(devID)
      if (result?.data?.securityAssessmentstatus) {
        setAssessment(result.data)
        setResult(getValuesFromAssessment(result.data || {}))

        const securityAssessmentStateVal =
          result?.data?.securityAssessmentstatus?.securityAssessmentState
        setSecurityAssessmentState(securityAssessmentStateVal)

        if (
          assessmentStatus(result.data, AssessmentStatus.inProgressRemediation)
        ) {
          setHasCallRemediation(true)
        }

        if (assessmentUpdateRequired) {
          fetchAssessmentByID(toastCallBack)
        } else {
          hasRequestInProgress = false
        }

        if (
          assessmentStatus(result.data, AssessmentStatus.inProgressAssessment)
        ) {
          scheduleAssessmentRefresh(toastCallBack)
        } else if (
          assessmentStatus(result.data, AssessmentStatus.complete) ||
          assessmentStatus(result.data, AssessmentStatus.unknown)
        ) {
          setloadingAssessment(false)
          setIsMonitoringInProgress(false)
          setHasCallRemediation(false)

          setRemediateComplete({
            deviceID: devID,
            current: false,
            toastData: null,
            remediateData: null
          })
          if (toastCallBack && typeof toastCallBack === 'function') {
            toastCallBack(
              securityAssessmentStateVal.toLowerCase() ===
                SecurityAssessmentState.enabled
            )
          } else if (
            securityAssessmentStateVal === SecurityAssessmentState.enabled
          ) {
            if (result?.data?.deviceconfigurationsAssessment?.configurations) {
              const deviceConfigurations = useDeviceConfigurationsAssessment(
                result.data
              )
              handleToast(toastCallBack, deviceConfigurations)
            }
          }
        }
      } else {
        setResult({ assessmentDataNull: true })
        setloadingAssessment(false)
        setIsMonitoringInProgress(false)
        setHasCallRemediation(false)
        setRemediateComplete({
          deviceID: devID,
          current: false,
          toastData: null,
          remediateData: null
        })
      }
    } catch (error) {
      console.log(error)
      setloadingAssessment(false)
      setIsMonitoringInProgress(false)
      setHasCallRemediation(false)
      setRemediateComplete({
        deviceID: devID,
        current: false,
        toastData: null,
        remediateData: null
      })
      hasRequestInProgress = false

      const data = error.data || {}

      if (data.status) {
        setErrorToLoad(true)
      }

      const messageControl = getErrorMessage(data, t)
      setErrorMessage(messageControl.message)
      setShowButton(messageControl.showButton)
      setLinkText(messageControl.linkText)
      setShowLink(messageControl.showLink)
    }
  }

  const toastStrings = (updatedConfig, id) => {
    const updatedState =
      updatedConfig?.assessmentData?.result.toLowerCase() ===
      DeviceConfigurationResult.compliant
    // const toastString = {
    // text: updatedState
    // ? t(`smbDevices.configurations.${id}.toastGoodTitle`)
    // : t(`smbDevices.configurations.${id}.toastBadTitle`)
    // subtitle: updatedState
    //   ? t(`smbDevices.configurations.${id}.toastGood`)
    //   : t(`smbDevices.configurations.${id}.toastBad`)
    // }

    let toastString
    if (id == 'configAdminPasswordSet') {
      toastString = {
        text: updatedState
          ? t(
              `smbDevices.common.toast.toastGoodTitle`,
              'Changes on Smart Security settings were saved successfully.'
            )
          : t(
              `smbDevices.common.toast.toastBadTitle`,
              'Unable to save changes on Smart Security settings. Try again later.'
            )
      }
    } else {
      toastString = {
        text: updatedState
          ? t(
              `smbDevices.common.toast.toastGoodTitle`,
              'Changes on Smart Security settings were saved successfully.'
            )
          : t(
              `smbDevices.common.toast.toastGoodTitle`,
              'Changes on Smart Security settings were saved successfully.'
            )
      }
    }
    return toastString
  }

  const setUpToast = (updatedConfig, id, toastData) => {
    const toastObj: any = {
      id: toastData.POSITIVE,
      type: ToastTypes.POSITIVE,
      ...toastStrings(updatedConfig, id)
    }
    addToast(toastObj)
  }

  const handleToast = (toastData, updatedConfig) => {
    if (toastData && toastData.length > 0) {
      toastData.map((item) => {
        if (item.remediationdata.remediationresult !== RemediationResults.ERROR)
          switch (item.configItem) {
            case DeviceConfigurationsId.CONFIG_ADMIN_PASSWORD_SET:
              setUpToast(
                updatedConfig[DeviceConfigurationsId.CONFIG_ADMIN_PASSWORD_SET],
                DeviceConfigurationsId.CONFIG_ADMIN_PASSWORD_SET,
                ToastIDs.setAdminPassword
              )
              break
            case DeviceConfigurationsId.CONFIG_AUTO_FW_UPDATE:
              setUpToast(
                updatedConfig[DeviceConfigurationsId.CONFIG_AUTO_FW_UPDATE],
                DeviceConfigurationsId.CONFIG_AUTO_FW_UPDATE,
                ToastIDs.setAutoFWUpdate
              )
              break
            case DeviceConfigurationsId.CONFIG_SNMP_V1V2:
              setUpToast(
                updatedConfig[DeviceConfigurationsId.CONFIG_SNMP_V1V2],
                DeviceConfigurationsId.CONFIG_SNMP_V1V2,
                ToastIDs.setSNMPV1V2
              )
              break
            case DeviceConfigurationsId.CONFIG_SNMP_V3:
              setUpToast(
                updatedConfig[DeviceConfigurationsId.CONFIG_SNMP_V3],
                DeviceConfigurationsId.CONFIG_SNMP_V3,
                ToastIDs.setSNMPV3
              )
              break
            default:
              break
          }
      })
    }
  }
  function scheduleAssessmentRefresh(toastCallBack?: Function | Array<any>) {
    const to = setTimeout(() => {
      requestAssessment(toastCallBack)
    }, RETRY_TIMEOUT)

    setTimerId((t) => {
      if (t) {
        clearTimeout(t)
      }
      return to
    })
  }

  function requestAssessment(toastCallBack?: Function | Array<any>) {
    if (hasRequestInProgress) {
      assessmentUpdateRequired = true
    } else {
      fetchAssessmentByID(toastCallBack)
    }
  }

  useEffect(() => {
    fetchAssessmentByID()
  }, [])

  useEffect(() => {
    if (remediateComplete.current) {
      fetchAssessmentByID(remediateComplete.toastData)
      onRemediationReloadDevice()
    }
  }, [remediateComplete])

  return {
    requestAssessment,
    assessment,
    securityAssessmentState,
    loadingAssessment,
    isMonitoringInProgress,
    setIsMonitoringInProgress,
    errorMessage,
    showButton,
    linkText,
    showLink,
    errorToLoad,
    result
  }
}
