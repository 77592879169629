import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import * as R from 'ramda';
import { useWizardContext } from './context';
import { buttonNames } from './buttons';

const useWizardStep = ({
  dataValidation,
  buttons,
  getStepData,
  nextStep,
}) => {
  const {
    setButtons,
    stepData,
    setNextStep,
    gotoNextStep,
    setStepData,
    setOnStepperNext,
  } = useWizardContext();

  const hasChanges = useMemo(() => !R.equals(stepData, getStepData(),
    [getStepData, stepData]), [getStepData, stepData]);
  const hasData = useMemo(() => !(R.empty(stepData) && R.empty(getStepData())), [getStepData, stepData]);
  const isDataValid = useMemo(() => !!dataValidation(), [dataValidation]);
  const [stepperNextClicked, setStepperNextClicked] = useState(false);

  const onNext = useCallback(() => {
    setStepData(getStepData());
    gotoNextStep();
  }, [getStepData, gotoNextStep, setStepData]);

  useEffect(() => {
    setButtons(buttons.reduce((prev, button) => {
      const currentButton = {
        ...button,
        props: button.props || {},
      };

      switch (currentButton.name) {
        case buttonNames.NEXT_BUTTON:
          return [
            ...prev,
            {
              ...currentButton,
              props: {
                ...currentButton.props,
                onClick: onNext,
                disabled: !hasChanges || !isDataValid,
              },
            }];
        case buttonNames.APPLY_BUTTON:
          return [
            ...prev,
            {
              ...currentButton,
              props: {
                ...currentButton.props,
                disabled: hasData && (!hasChanges || !isDataValid),

              },
            },
          ];
        default: return [...prev, currentButton];
      }
    }, []));
  }, [buttons, hasChanges, hasData, isDataValid, onNext, setButtons]);

  useEffect(() => {
    setNextStep(nextStep);
  }, [nextStep, setNextStep]);

  useEffect(() => {
    if (stepperNextClicked) {
      const isDataCorrect = dataValidation();

      if (isDataCorrect && hasChanges) {
        onNext();
      }

      setStepperNextClicked(false);
    }
  }, [stepperNextClicked, onNext, dataValidation, hasChanges]);

  useEffect(() => {
    setOnStepperNext(() => {
      setStepperNextClicked(true);
    });
  }, [stepperNextClicked, setOnStepperNext]);
};

export default useWizardStep;
