// import * as veneernewversion from '@veneer-core-latest/core'
import * as veneernewversion from '@veneer/core'
// import * as veneernewtokens from '@veneer-core-latest/tokens/dist/tokens/tokens'
import * as veneernewtokens from '@veneer/tokens/dist/tokens/tokens'

export const pickVeneerVersion = (fontNewEnable) => {
  return fontNewEnable
    ? { core: veneernewversion, token: veneernewtokens }
    : { core: veneernewversion, token: veneernewtokens }
}
// TODO: font change: Delete file when done
