import styled from 'styled-components'

// printer-list-wrapper
export const PrinterListContainer = styled.div`
  padding: 24px;
  padding-top: 0px;
  @media (max-width: 768px) {
    padding: 2em;
  }
`
export const DeviceName = styled.div`
  color: #027aae;
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
`
export const DeviceSecondaryName = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #212121;
`
