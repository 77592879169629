import { useCallback, useEffect, useState } from 'react';

function usePolling({
  fnToPoll,
  pollTimeout,
  enable,
}) {
  const [shouldPoll, setShouldPoll] = useState(enable);
  const [isPolling, setIsPolling] = useState(enable);
  const [pollInterval, setPollInterval] = useState(1000);
  const [timesPolled, setTimesPolled] = useState(0);
  const [timeSpentPolling, setTimeSpentPolling] = useState(0);
  const [timeoutId, setTimeoutId] = useState(null);

  // If we are polling for more time than the specified timeout, stop.
  useEffect(() => {
    const msTimeout = pollTimeout * 1000;

    if (timeSpentPolling > msTimeout) {
      setShouldPoll(false);
      setIsPolling(false);
    }
  }, [timeSpentPolling, pollTimeout, setShouldPoll]);

  // Called by the user of the hook to run the polled function again.
  const poll = useCallback(() => {
    const isWaiting = timeoutId !== null;

    if (shouldPoll && !isWaiting) {
      setIsPolling(true);

      const id = setTimeout(() => {
        fnToPoll();

        setTimeSpentPolling(timeSpentPolling + pollInterval);
        setTimesPolled(timesPolled + 1);
        setPollInterval((2 ** timesPolled) * 1000);
        setTimeoutId(null);
      }, pollInterval);

      setTimeoutId(id);
    }
  }, [
    timeoutId,
    fnToPoll,
    timesPolled,
    timeSpentPolling,
    pollInterval,
    setIsPolling,
    setTimeSpentPolling,
    setTimesPolled,
    setPollInterval,
    shouldPoll,
    setTimeoutId,
  ]);

  const stopPolling = useCallback(() => {
    setIsPolling(false);
    setShouldPoll(false);
  }, [setIsPolling, setShouldPoll]);

  // Resets all the state; must be called to be able to poll again.
  const resetPolling = useCallback(() => {
    clearTimeout(timeoutId);
    setTimesPolled(0);
    setTimeSpentPolling(0);
    setTimeoutId(null);
    setPollInterval(1000);
    setIsPolling(false);

    setShouldPoll(true);
  }, [
    timeoutId,
    setTimesPolled,
    setTimeSpentPolling,
    setTimeoutId,
    setPollInterval,
    setIsPolling,
    setShouldPoll,
  ]);

  // Stops any setTimeout delay when the hook is destroyed.
  useEffect(() => () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  }, [timeoutId]);

  useEffect(() => {
    setShouldPoll(enable);
  }, [enable, setShouldPoll]);

  return {
    isPolling,
    poll,
    stopPolling,
    resetPolling,
  };
}

export default usePolling;
