import React, { useState } from 'react'
// TODO: font change: add
// import { ToastProvider } from '@veneer/core'
import { RootProvider } from '@jarvis/react-portal-addons'
import PrinterDetails from './components/PrinterDetails'
import Breadcrumb from './components/Breadcrumb'
import PrinterList from './components/PrinterList'
import { PrinterProvider } from './provider/PrinterProvider'
import GeneralContext from './contexts/GeneralContext'
import { getEnvConfig } from './static/consts'
import { createBrowserHistory } from 'history'
import SsClient from './client/SsClient'
//Use Router when deploying & BrowserRouter while running locally
import { Router, Route, Switch } from 'react-router-dom'
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { SmbContainer } from './styles'
import resources from './assets/locale/index'
export const history = createBrowserHistory()
import { pickVeneerVersion } from '../src/font'
import '../src/styles/global.scss'
import { useFlags } from 'launchdarkly-react-client-sdk'

const SmbSecurity = (props) => {
  // TODO: font change: delete
  const fontNewEnable = useFlags()?.fontNewEnable ?? true
  const VeneerCore = pickVeneerVersion(fontNewEnable)
  const { ToastProvider } = VeneerCore.core
  const { fontFamilyRegular } = VeneerCore.token

  const { stack, authProvider, navigation, localization } = props
  const config = getEnvConfig(stack)
  const smbClient = new SsClient(config.smbApiUrl, authProvider)

  const [remediateComplete, setRemediateComplete] = useState({
    current: false,
    toastData: null,
    remediateData: null
  })

  return (
    <SmbContainer font={fontFamilyRegular}>
      <RootProvider localization={localization} resources={resources}>
        <ToastProvider>
          <GeneralContext.Provider
            value={{
              client: smbClient,
              stack,
              authProvider,
              remediateComplete,
              setRemediateComplete,
              fontNewEnable
            }}
          >
            <PrinterProvider>
              <Router history={navigation || history}>
                <Breadcrumb
                  navigation={navigation}
                  fontNewEnable={fontNewEnable}
                />
                <Switch>
                  <Route
                    exact
                    path="/solutions/smartsecurity"
                    component={PrinterList}
                  />
                  <Route
                    path="/solutions/smartsecurity/:devID"
                    component={PrinterDetails}
                  />
                </Switch>
              </Router>
            </PrinterProvider>
          </GeneralContext.Provider>
        </ToastProvider>
      </RootProvider>
    </SmbContainer>
  )
}

export default SmbSecurity
