import React from 'react'
import PolicyDetailsCard from '../PolicyDetailsCard'
import PolicyStatusCard from '../PolicyStatusCard'
import withConfigWifiNetwork from '../../hocs/withConfigWifiNetwork'
import { PolicyCardItem, PolicyCardItemWrap } from './styles'
import { WifiHelpButtonClicked } from '../../utils/analytics'

const SecureWifi = ({ componentProps }) => {
  const { isComplianceOff, isCompliant } = componentProps
  const componentProp = {
    ...componentProps,
    id: 'configWifiNetwork', // Response Id != from string file
    event: WifiHelpButtonClicked
  }
  return (
    !isComplianceOff && (
      <PolicyDetailsCard {...componentProp}>
        <PolicyCardItem>
          <PolicyCardItemWrap>
            <PolicyStatusCard isCompliant={isCompliant} id={componentProp.id} />
          </PolicyCardItemWrap>
        </PolicyCardItem>
      </PolicyDetailsCard>
    )
  )
}

export default withConfigWifiNetwork(SecureWifi)
