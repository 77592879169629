import React, { useContext } from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
import { SecuritySettingWrap, SecuritySettingContent } from './styles'
import GeneralContext from '../../contexts/GeneralContext'
import { pickVeneerVersion } from '../../font'

const SecuritySetting = () => {
  const { t } = useI18n()
  // TODO: font change: delete
  const { fontNewEnable } = useContext(GeneralContext)
  const VeneerCore = pickVeneerVersion(fontNewEnable)
  const { fontFamilyRegular, fontFamilyLight } = VeneerCore.token

  return (
    <SecuritySettingWrap>
      <SecuritySettingContent
        font={fontFamilyRegular}
        fontLight={fontFamilyLight}
      >
        <h4 data-testid="security-setting-title">
          {t('smbDevices.securitySettings.titleProtected', 'Security Settings')}
        </h4>
        <p data-testid="security-setting-desc">
          {t(
            'smbDevices.securitySettings.descriptionProtected',
            'The security status of this printer is determined by these settings.'
          )}
        </p>
      </SecuritySettingContent>
    </SecuritySettingWrap>
  )
}

export default SecuritySetting
