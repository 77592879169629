// import { OptionInterface } from '@veneer/core/dist/scripts/contextual_menu'

const environmentVariables = {
  local: {
    deviceApiUrl: 'http://localhost:3001/devicecache',
    smbApiUrl: 'http://localhost:3001',
    printPlanHyperLink: 'https://www.hpsmartpie.com/us/en/ucde/print_plans',
    clientId: 'g1PC7Nw3GtkekSaPOKBrEoVkw9dKbpjo',
    LDClientId: '61a5e1c8df185e111101eb6c'
  },
  proxy: {
    deviceApiUrl: 'http://localhost:3001',
    smbApiUrl: 'https://smartsecurity-dev.tropos-rnd.com/securitymgrfleetlite',
    // smbApiUrl: 'https://smartsecurity-pie.tropos-rnd.com/securitymgr',
    printPlanHyperLink: 'https://www.hpsmartpie.com/us/en/ucde/print_plans',
    LDClientId: '61a5e1c8df185e111101eb6c'
  },
  dev: {
    deviceApiUrl: 'https://pie-us1.api.ws-hp.com/devicecache',
    smbApiUrl: 'https://pie-us1.api.ws-hp.com/securitymgr',
    printPlanHyperLink: 'https://www.hpsmartpie.com/us/en/ucde/print_plans',
    clientId: 'g1PC7Nw3GtkekSaPOKBrEoVkw9dKbpjo',
    LDClientId: '61a5e1c8df185e111101eb6c'
  },
  pie: {
    deviceApiUrl: 'https://pie-us1.api.ws-hp.com/devicecache',
    smbApiUrl: 'https://pie-us1.api.ws-hp.com/securitymgr',
    printPlanHyperLink: 'https://www.hpsmartpie.com/us/en/ucde/print_plans',
    clientId: 'g1PC7Nw3GtkekSaPOKBrEoVkw9dKbpjo',
    LDClientId: '61a5e1c8c51bb81118631a45'
  },
  stage: {
    deviceApiUrl: 'https://stage-us1.api.ws-hp.com/devicecache',
    smbApiUrl: 'https://stage-us1.api.ws-hp.com/securitymgr',
    printPlanHyperLink: 'https://www.hpsmartstage.com/us/en/ucde/print_plans',
    clientId: 'g1PC7Nw3GtkekSaPOKBrEoVkw9dKbpjo',
    LDClientId: '61a5e1c89ef8801188f3447e'
  },
  prod: {
    deviceApiUrl: 'https://us1.api.ws-hp.com/devicecache',
    smbApiUrl: 'https://us1.api.ws-hp.com/securitymgr',
    printPlanHyperLink: 'https://www.hpsmart.com/ucde/print_plans',
    clientId: 'g1PC7Nw3GtkekSaPOKBrEoVkw9dKbpjo',
    LDClientId: '61a5e1c82e5f1210de97acb4'
  }
}

export const Sizes = {
  MOBILE_VERSION_MAX_WIDTH: 576
}

export const imageSizes = {
  large: 'large',
  medium: 'medium',
  small: 'small',
  extrasmall: 'extrasmall'
}

export function getEnvConfig(env) {
  const tlcEnv = env instanceof String ? env.toLowerCase() : env
  switch (tlcEnv) {
    case 'local':
      return environmentVariables.local
    case 'proxy':
      return environmentVariables.proxy
    case 2:
    case 'stage':
    case 'stg':
      return environmentVariables.stage
    case 3:
    case 'prod':
    case 'production':
      return environmentVariables.prod
    case 1:
    case 'pie':
      return environmentVariables.pie
    case 0:
    case 'dev':
      return environmentVariables.dev
    default:
      return environmentVariables.pie
  }
}

export const fetchLDClientSideID = () => {
  const host = window.location.hostname

  return {
    localhost: getEnvConfig(0).LDClientId,
    'smb.local.portalshell.com': getEnvConfig(1).LDClientId,
    'smb.dev.portalshell.com': getEnvConfig(0).LDClientId,
    'smb.pie.portalshell.com': getEnvConfig(1).LDClientId,
    'smb.local.portalshell.int.hp.com': getEnvConfig(1).LDClientId,
    'smb.dev.portalshell.int.hp.com': getEnvConfig(0).LDClientId,
    'smb.pie.portalshell.int.hp.com': getEnvConfig(1).LDClientId,
    'smb.stage.portalshell.int.hp.com': getEnvConfig(2).LDClientId,
    'admin.hpsmart.com': getEnvConfig(3).LDClientId
  }[host]
}

export const bizModelType = {
  E2E: 'E2E'
}
export const SecurityAssessmentStatus = {
  trueValue: 'true',
  falseValue: 'false'
}

export const DeviceConfigurationsValue = {
  trueValue: 'true',
  falseValue: 'false',
  unknownValue: 'unknown'
}

export const ToastTypes = {
  POSITIVE: 'positive',
  NEGATIVE: 'negative'
}

export const ToastIDs = {
  setAdminPassword: {
    id: 'setAdminPassword',
    POSITIVE: 'setAdminPasswordOnToast',
    NEGATIVE: 'errorSetAdminPasswordOnToast'
  },
  setAutoFWUpdate: {
    id: 'setAutoFWUpdate',
    POSITIVE: 'setAutoFwUpdateOnToast',
    NEGATIVE: 'errorSetAutoFwUpdateOnToast'
  },
  setSNMPV1V2: {
    id: 'setSNMPV1V2',
    POSITIVE: 'setSNMPV1V2OnToast',
    NEGATIVE: 'errorSetSNMPV1V2OnToast'
  },
  setSNMPV3: {
    id: 'setSNMPV3',
    POSITIVE: 'setSNMPV3OnToast',
    NEGATIVE: 'errorSetSNMPV3OnToast'
  },
  setMonitoringSecurity: {
    id: 'setMonitoringSecurity',
    POSITIVE: 'setMonitoringOnToast',
    NEGATIVE: 'errorSetMonitoringOnToast'
  },
  setRemediationCheck: {
    id: 'setRemediationCheck',
    POSITIVE: 'setRemediationCheckOnToast',
    NEGATIVE: 'errorSetRemediationCheck'
  }
}

export const DeviceConfigurationResult = {
  compliant: 'compliant',
  nonCompliant: 'noncompliant',
  complianceOff: 'complianceoff',
  unknown: 'unknown'
}

export const AssessmentStatus = {
  unknown: 'unknown',
  inProgressAssessment: 'inprogress_assessment',
  inProgressRemediation: 'inprogress_remediation',
  complete: 'complete'
}

export const AssessmentResult = {
  securityMonitored: 'securitymonitored',
  needsAttention: 'needsattention',
  notMonitored: 'notmonitored',
  unknown: 'unknown'
}

export const SolutionActive = {
  true: 'true',
  false: 'false'
}

export const RemediationState = {
  completed: 'completed',
  inprogress: 'inprogress',
  unknown: 'unknown'
}

export const RemediationResults = {
  ERROR: 'error',
  SUCCESS: 'success'
}

export const SecurityAssessmentState = {
  enabled: 'enabled',
  disabled: 'disabled'
}

export const DeviceConfigurationsId = {
  CONFIG_ADMIN_PASSWORD_SET: 'configAdminPasswordSet',
  CONFIG_WIFI_NETWORK: 'configWifi0Network',
  CONFIG_AUTO_FW_UPDATE: 'configAutoFWUpdate',
  CONFIG_SNMP_V1V2: 'configSNMPv1v2Write',
  CONFIG_SNMP_V3: 'configSNMPv3'
}

export const passwordConfigId = {
  ADMIN_PASSWORD: 'controlAdminPasswordSet_PasswordText',
  AUTH_PASSPHRASE: 'controlSNMPv3_AuthenticationPassword',
  PRIV_PASSPHRASE: 'controlSNMPv3_PrivacyPassword',
  SNMP_USERNAME: 'controlSNMPv3_UserName'
}

export const PrinterListBreadcrumData = (t) => [
  {
    text: t('smbDevices.breadcrum.home', 'Home'),
    url: `/`
  },
  {
    text: t('smbDevices.breadcrum.solutions', 'Solutions'),
    url: `/solutions`
  },
  {
    text: t('smbDevices.header.title', 'Smart Security')
  }
]

export const PrinterDetailBreadcrumData = (printerName, t) => [
  {
    text: t('smbDevices.breadcrum.home', 'Home'),
    url: `/`
  },
  {
    text: t('smbDevices.breadcrum.solutions', 'Solutions'),
    url: `/solutions`
  },
  {
    text: t('smbDevices.header.title', 'Smart Security'),
    url: `/solutions/smartsecurity`
  },
  {
    text: printerName
  }
]

export const ConstraintsDataTypesKeys = {
  power: 'power',
  firmware: 'firmware',
  memory: 'memory',
  network: 'network'
}

// Constraints are displayed in the following order:
export const ConstraintsDataTypes = [
  ConstraintsDataTypesKeys.power,
  ConstraintsDataTypesKeys.firmware,
  ConstraintsDataTypesKeys.memory,
  ConstraintsDataTypesKeys.network
]

export const defaultPrinterIcon =
  'https://s3-us-west-2.amazonaws.com/wpp-printers-images-prod/5f0a80bd7299dcedd856d87dd30a9f7b/170x128.png'

export const HelperLinks = {
  link1: (stack) => {
    let baseUrl = 'https://www.hpsmart.com'
    switch (stack) {
      case 'stage':
      case 'stg':
        baseUrl = 'https://www.hpsmartstage.com'
        break
      case 'prod':
      case 'production':
        baseUrl = 'https://www.hpsmart.com'
        break
      case 'dev':
        baseUrl = 'https://www.hpsmartdev.com'
        break
      case 'pie':
      default:
        baseUrl = 'https://www.hpsmartpie.com'
        break
    }

    return `${baseUrl}/ucde/help/hp-plus/hpsmartsecurity`
  },
  link2:
    'https://support.hp.com/in-en/topic/diagnostics?jumpid=in_r11839_hk/en/covid/diag'
}

export const filterOptions = {
  sortBy: 'deviceName',
  order: 'ascending',
  offset: 0,
  limit: 10,
  search: '',
  serviceId: 'ws-hp.com/smartsecurity'
}

export const getDateAndTime = (t, date_time) => {
  const today = date_time ? new Date(date_time) : new Date()
  const locale = 'en'
  let dateAndTime =
    `${(1 + today.getMonth()).toString().padStart(2, '0')}` +
    `/` +
    `${today.getDate()}` +
    `/` +
    `${today.getFullYear()}` +
    ` ` +
    t('smbDevices.lastUpdate.conjuction', 'at') +
    ` ` +
    `${today.toLocaleTimeString(locale, {
      hour: '2-digit',
      hour12: true,
      minute: 'numeric',
      second: 'numeric'
    })}`
  return dateAndTime
}

export const columns = (t) => [
  {
    id: 'deviceName',
    label: t('smbDevices.deviceList.tableHeader.deviceName', 'Printer Name'),
    sortable: true
  },
  {
    id: 'location',
    label: t('smbDevices.deviceList.tableHeader.deviceLocation', 'Location'),
    sortable: false
  },
  {
    id: 'deviceConnectionState',
    label: t('smbDevices.deviceList.tableHeader.connectivity', 'Connectivity'),
    sortable: false
  },
  {
    id: 'deviceStatus',
    label: t(
      'smbDevices.deviceList.tableHeader.securityStatus',
      'Security Status'
    ),
    sortable: false
  }
]
