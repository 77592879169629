// import { useState, useEffect } from 'react'
// import GeneralContext from '../contexts/GeneralContext'
import {
  AssessmentResult,
  SecurityAssessmentState,
  AssessmentStatus,
  SecurityAssessmentStatus
} from '../static/consts'
// import { assessmentContext } from '../contexts/assessmentContext'

export function _compare(s1, s2) {
  return s1 && s2 && s1.toLowerCase() === s2.toLowerCase()
}

export function getValuesFromAssessment(assessment) {
  const securityAssessmentStatus = assessment.securityAssessmentstatus || {}
  // securityAssessmentStatus
  const active = String(securityAssessmentStatus.active || 'false')
  const securityAssessmentState =
    securityAssessmentStatus.securityAssessmentState || ''
  const assessmentStatus = securityAssessmentStatus.assessmentStatus || ''
  const assessmentResult =
    securityAssessmentStatus.assessmentResult || AssessmentResult.unknown
  const lastAssessed = securityAssessmentStatus.lastAssessed || ''
  const securityPolicy = securityAssessmentStatus.securityPolicy || ''
  // assessmentStatus
  const isAssessmentInProgress = _compare(
    assessmentStatus,
    AssessmentStatus.inProgressAssessment
  )
  const isRemediationInProgress = _compare(
    assessmentStatus,
    AssessmentStatus.inProgressRemediation
  )
  const isComplete = _compare(assessmentStatus, AssessmentStatus.complete)
  // assessmentResult
  const isSecurityMonitored = _compare(
    assessmentResult,
    AssessmentResult.securityMonitored
  )
  const isNeedsAttention = _compare(
    assessmentResult,
    AssessmentResult.needsAttention
  )
  const isNotMonitored = _compare(
    assessmentResult,
    AssessmentResult.notMonitored
  )
  const isUnknown = _compare(assessmentResult, AssessmentResult.unknown)
  // securityAssessmentState
  const isEnabled = _compare(
    securityAssessmentState,
    SecurityAssessmentState.enabled
  )

  const isDisabled = _compare(
    securityAssessmentState,
    SecurityAssessmentState.disabled
  )

  const isActive = active === SecurityAssessmentStatus.trueValue

  return {
    active,
    isActive,
    securityAssessmentState,
    assessmentStatus,
    assessmentResult,
    lastAssessed,
    securityPolicy,
    isAssessmentInProgress,
    isRemediationInProgress,
    isComplete,
    isSecurityMonitored,
    isNeedsAttention,
    isNotMonitored,
    isUnknown,
    isEnabled,
    isDisabled,
    assessmentDataNull: false
  }
}

// export const useSecurityAssessmentStatus = (assessment) => {
//   // const { assessment } = useContext(assessmentContext)
//   // const { remediateComplete } = useContext(GeneralContext)
//   const [result, setResult] = useState(
//     getValuesFromAssessment(assessment || {})
//   )

//   useEffect(() => {
//     setResult(getValuesFromAssessment(assessment || {}))
//   }, [assessment])

//   return result
// }
