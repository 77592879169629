/* eslint-disable no-use-before-define */
import { useContext, useEffect } from 'react'
import GeneralContext from '../contexts/GeneralContext'
import { useParams } from 'react-router-dom'
declare global {
  interface Window {
    timerId: any
  }
}
export const useRemediation = (useSecurityAssessmentStatusHook) => {
  const RETRY_TIMEOUT = 3000
  const { devID } = useParams()

  const {
    client,
    setRemediateComplete
    // timerId, setTimerId
  } = useContext(GeneralContext)
  const { isRemediationInProgress } = useSecurityAssessmentStatusHook
  const isRemediationCompleted = (data) => {
    const remediation = data || {}
    return remediation.remediationstate === 'completed'
  }
  // let timerId = null

  useEffect(() => {
    // window.timerId = null
    // clearTimeout(window.timerId)
    clearTimeout(window.timerId)
    const mounted = { current: true }
    const fetchRemediation = async () => {
      if (isRemediationInProgress)
        try {
          const result = await client.getRemediationStatus(devID)
          if (isRemediationCompleted(result.data)) {
            //to add multiple toast : result.data sent as param to requestAssessment
            clearTimeout(window.timerId)
            setRemediateComplete({
              deviceID: devID,
              current: true,
              toastData: result.data.remediationstatus_detailed,
              remediateData: result.data
            })
          } else {
            setRemediateComplete({
              deviceID: devID,
              current: false,
              toastData: null,
              remediateData: result.data
            })
            window.timerId = setTimeout(
              fetchRemediation,
              // clearTimeout(window.timerId)
              RETRY_TIMEOUT
              // window.timerId
            )
            // clearTimeout(window.timerId)
            // setTimerId(setTimeout(fetchRemediation, RETRY_TIMEOUT))
          }
        } catch (error) {
          clearTimeout(window.timerId)
        }
    }

    if (isRemediationInProgress) {
      if (mounted.current) {
        fetchRemediation()
      }
    }

    return () => {
      clearTimeout(window.timerId)
      mounted.current = false
    }
  }, [isRemediationInProgress])

  useEffect(() => {
    return () => {
      clearTimeout(window.timerId)
    }
  }, [])
}
