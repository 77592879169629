import styled from 'styled-components'

// smb-pd-card
export const DeviceDetailsCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 24px;
  align-items: center;
  background-color: #fff;
  border-radius: 16px;
  margin-bottom: 16px;
  @media (max-width: 767px) {
    display: grid;
  }
`

// smb-pd-card--onload
export const DeviceDetailsCardLoader = styled(DeviceDetailsCard)`
  justify-content: center;
  min-height: 204px;
`

// divider-container
export const DividerContainer = styled.div`
  display: flex;
  align-self: stretch;
`

// divider
export const Divider = styled.div`
  & {
    display: flex;
    align-items: flex-start;
    padding: 0px 32px;
    transform: rotate(-180deg);
    position: static;
    height: 100%;
  }
  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 50%;
    border-left: 2px solid #2121211a;
    transform: translate(-50%);
  }
  @media (max-width: 576px) {
    display: none;
  }
`
