import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import RootContext from './RootContext';
import { getMockedLocalization, shouldMockLocalization } from '../../helpers/mockedShell';

const RootProvider = ({
  children,
  resources,
  mockLocalization,
  ...props
}) => {
  const { localization: shellLocalization } = props;

  const localization = shouldMockLocalization(mockLocalization, shellLocalization)
    ? getMockedLocalization(resources)
    : shellLocalization;

  const TranslatorProvider = useMemo(
    () => localization.getReactTranslatorProvider(React),
    [localization],
  );

  const providerValue = {
    ...props,
    localization,
  };

  return (
    <RootContext.Provider value={providerValue}>
      <TranslatorProvider resources={resources}>
        {children}
      </TranslatorProvider>
    </RootContext.Provider>
  );
};

RootProvider.defaultProps = {
  mockLocalization: false,
};

RootProvider.propTypes = {
  localization: PropTypes.objectOf(PropTypes.any).isRequired,
  resources: PropTypes.objectOf(PropTypes.any).isRequired,
  children: PropTypes.node.isRequired,
  mockLocalization: PropTypes.bool,
};

export default RootProvider;
