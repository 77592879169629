function getProductInfoFromEntitlement(entitlement, serviceId) {
  const { softwareProductInfoList } = entitlement;
  if (!softwareProductInfoList) {
    return null;
  }

  const product = softwareProductInfoList.find(productInfo => productInfo.serviceId === serviceId);
  // "find" returns undefined, so to maintain the pattern of returning null,
  // we'll make a check for falsy values and return null.
  if (!product) {
    return null;
  }

  return product;
}

export default getProductInfoFromEntitlement;
