import { useState, useEffect, useContext } from 'react'
// TODO: font change: add
// import { useToast } from '@veneer/core'
import { ToastIDs, ToastTypes } from '../static/consts'
import { getToastErrorStrings } from '../strings/Errors/index'
import { assessmentContext } from '../contexts/assessmentContext'
import { useI18n } from '@jarvis/react-portal-addons'
import GeneralContext from '../contexts/GeneralContext'
import { pickVeneerVersion } from '../font'

export const useSecurityMonitor = ({ client, activePrinterID }) => {
  // TODO: font change: delete
  const { fontNewEnable } = useContext(GeneralContext)
  const VeneerCore = pickVeneerVersion(fontNewEnable)
  const { useToast } = VeneerCore.core

  const [modalState, setModalState] = useState(false)
  const [alertState, setAlertState] = useState(false)
  const [monitorState, setMonitorState] = useState(false)
  const {
    requestAssessment,
    setIsMonitoringInProgress,
    onAssessmentReloadDevice
  } = useContext(assessmentContext)

  const { t } = useI18n()
  const { addToast } = useToast()

  const toastStrings = (status, err) => {
    const toastString = {
      text: status
        ? t(
            'smbDevices.common.toast.toastGoodTitle',
            'Changes on Smart Security settings were saved successfully.'
          )
        : t(
            'smbDevices.common.toast.toastGoodTitle',
            'Changes on Smart Security settings were saved successfully.'
          )
      // subtitle: status
      //   ? t('smbDevices.monitorSecuritySettings.toastOn')
      //   : t('smbDevices.monitorSecuritySettings.toastOff')
    }
    if (err) return getToastErrorStrings(err.data, t)
    return toastString
  }

  const setUpToast = (status, err) => {
    const toast: any = {
      id: err
        ? ToastIDs.setMonitoringSecurity.NEGATIVE
        : ToastIDs.setMonitoringSecurity.POSITIVE,
      type: err ? ToastTypes.NEGATIVE : ToastTypes.POSITIVE,
      ...toastStrings(status, err)
    }
    addToast(toast)
  }

  const toggleMonitoring = async (status) => {
    modalState && setModalState(false)
    let err
    try {
      !alertState && setIsMonitoringInProgress(true)
      await client.changeDeviceAssessmentStatus(
        activePrinterID,
        status ? 'enabled' : 'disabled'
      )
    } catch (error) {
      err = error
      addToast({
        id: ToastIDs.setMonitoringSecurity.NEGATIVE + activePrinterID,
        type: 'negative', //ToastTypes.NEGATIVE
        ...getToastErrorStrings(err.data, t)
      })
    }
    if (!err) {
      alertState && setIsMonitoringInProgress(true)
      alertState && setAlertState(false)
    }
    await requestAssessment(function (status) {
      onAssessmentReloadDevice()
      if (alertState) {
        setUpToast(status, err)
      }
    })
  }

  useEffect(() => {
    alertState && toggleMonitoring(monitorState)
  }, [alertState])

  return {
    modalState,
    alertState,
    monitorState,
    setModalState,
    setAlertState,
    setMonitorState,
    toggleMonitoring
  }
}
