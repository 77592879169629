import React, { useContext } from 'react'
import {
  PolicyFormWrap,
  PolicyFormBody,
  PolicyFormContent,
  PolicyFormTitle
  // PasswordField,
  // TextField,
  // FormButton
} from './styles'
import { UnsavedModal } from '../UnsavedModal'
import { withRouter } from 'react-router-dom'
import { useI18n } from '@jarvis/react-portal-addons'
import styled from 'styled-components'
import { pickVeneerVersion } from '../../font'
import GeneralContext from '../../contexts/GeneralContext'

const SnmpV3Form = ({
  history,
  showForm,
  isInProgress,
  authPassphrase,
  privPassphrase,
  confirmAuthPassphrase,
  confirmPrivPassphrase,
  onInputUsername,
  onInputAuthPass,
  onInputPrivPass,
  onInputConfirmAuthPassword,
  onInputConfirmPrivPassword,
  isCompliant,
  disableSave,
  usernameValidationErr,
  authValidationErr,
  privacyValidationErr,
  confirmAuthValidationErr,
  confirmPrivValidationErr,
  clearUsernameValidationErr,
  clearAuthValidationErr,
  clearPrivacyValidationErr,
  clearConfirmAuthValidationErr,
  clearConfirmPrivValidationErr,
  verifyUsername,
  verifyAuthPass,
  verifyPrivacyPass,
  verifyConfirmAuthPass,
  verifyConfirmPrivPass,
  handleForm,
  username,
  getString
}) => {
  // TODO: font change: delete
  const { fontNewEnable } = useContext(GeneralContext)
  const VeneerCore = pickVeneerVersion(fontNewEnable)
  const { Password, TextBox, Button } = VeneerCore.core
  const { fontFamilyRegular } = VeneerCore.token

  const FormButton = styled(Button)`
    font-size: 16px;
    line-height: 20px;
    width: 112px;
    height: 36px;
    padding: 5px 40px;
    font-family: ${fontFamilyRegular};
  `

  const { t } = useI18n()
  return (
    <PolicyFormWrap>
      {showForm && isCompliant && (
        <PolicyFormBody>
          <PolicyFormContent className="custom-form">
            <PolicyFormTitle
              isInProgress={isInProgress}
              data-testid="snmpV3-username-title"
              font={fontFamilyRegular}
            >
              {getString('formTitle1', 'SNMP V3 user name*')}
            </PolicyFormTitle>
            <TextBox
              value={username}
              onChange={onInputUsername}
              onFocus={clearUsernameValidationErr}
              onBlur={verifyUsername}
              error={!!usernameValidationErr}
              helperText={usernameValidationErr}
              disabled={isInProgress}
              placeholder={getString('usernamePlaceholder', 'Enter user name')}
              aria-label={getString('usernamePlaceholder', 'Enter user name')}
              // style={{ color: '#404040' }}
              name="username"
              id="username"
              data-testid="snmpv3-username"
              className="custom-input-snmp"
            />
            <PolicyFormTitle
              isInProgress={isInProgress}
              data-testid="snmpV3-authPassphrase-title"
              font={fontFamilyRegular}
            >
              {getString('formTitle2', 'Authentication passphrase*')}
            </PolicyFormTitle>
            <Password
              value={authPassphrase}
              onChange={onInputAuthPass}
              onFocus={clearAuthValidationErr}
              onBlur={verifyAuthPass}
              error={!!authValidationErr}
              helperText={authValidationErr}
              disabled={isInProgress}
              placeholder={getString('setPassphrase', 'Set passphrase')}
              aria-label={getString('setPassphrase', 'Set passphrase')}
              // style={{ color: '#404040' }}
              name="AuthenticationPassphrase"
              id={`AuthenticationPassphrase`}
              data-testid="snmpv3-auth-passphrase"
              className="custom-input"
            />
            <Password
              value={confirmAuthPassphrase}
              onChange={onInputConfirmAuthPassword}
              onFocus={clearConfirmAuthValidationErr}
              onBlur={verifyConfirmAuthPass}
              error={!!confirmAuthValidationErr}
              helperText={confirmAuthValidationErr}
              disabled={isInProgress}
              placeholder={getString('confirmPassphrase', 'Confirm passphrase')}
              aria-label={getString('confirmPassphrase', 'Confirm passphrase')}
              // style={{ color: '#404040' }}
              name="ConfirmAuthenticationPassphrase"
              id={`ConfirmAuthenticationPassphrase`}
              data-testid="snmpv3-confirm-auth-passphrase"
              className="custom-input-snmp"
            />
            <PolicyFormTitle
              isInProgress={isInProgress}
              data-testid="snmpV3-privPassphrase-title"
              font={fontFamilyRegular}
            >
              {getString('formTitle3', 'Privacy passphrase*')}
            </PolicyFormTitle>
            <Password
              value={privPassphrase}
              onChange={onInputPrivPass}
              onFocus={clearPrivacyValidationErr}
              onBlur={verifyPrivacyPass}
              error={!!privacyValidationErr}
              helperText={privacyValidationErr}
              disabled={isInProgress}
              aria-label={getString('setPassphrase', 'Set passphrase')}
              placeholder={getString('setPassphrase', 'Set passphrase')}
              // style={{ color: '#404040' }}-
              name="Privacy Passphrase"
              id={`PrivacyPassphrase`}
              data-testid="snmpv3-privacy-passphrase"
              className="custom-input"
            />
            <Password
              value={confirmPrivPassphrase}
              onChange={onInputConfirmPrivPassword}
              onFocus={clearConfirmPrivValidationErr}
              onBlur={verifyConfirmPrivPass}
              disabled={isInProgress}
              error={!!confirmPrivValidationErr}
              helperText={confirmPrivValidationErr}
              placeholder={getString('confirmPassphrase', 'Confirm passphrase')}
              aria-label={getString('confirmPassphrase', 'Confirm passphrase')}
              // style={{ color: '#404040' }}
              name="Confirm Privacy Passphrase"
              id={`ConfirmPrivacyPassphrase`}
              data-testid="snmpv3-confirm-privacy-passphrase"
              className="custom-input"
            />
          </PolicyFormContent>
          <FormButton
            disabled={(showForm && disableSave) || isInProgress}
            onClick={handleForm}
            data-testid="snmpv3-form-submit"
          >
            {t('smbDevices.common.formBtnText', 'Save')}
          </FormButton>
        </PolicyFormBody>
      )}
      <UnsavedModal
        history={history}
        inputValues={[
          username,
          authPassphrase,
          confirmAuthPassphrase,
          privPassphrase,
          confirmPrivPassphrase
        ]}
      />
    </PolicyFormWrap>
  )
}

export default withRouter(SnmpV3Form)
