import React, { useState, useEffect, useContext } from 'react'
// TODO: font change: add
// import { ThemeProvider } from '@veneer/core'
import { withRouter } from 'react-router-dom'
// import GeneralContext from '../../contexts/GeneralContext'
import { pickVeneerVersion } from '../../font'
import { PrinterContext } from '../../provider/PrinterProvider'
import {
  TopNav
  // CustomBreadcrumbs
} from './styles'
import styled from 'styled-components'

const Breadcrumb = (props) => {
  // TODO: font change: delete
  const { fontNewEnable } = props
  const VeneerCore = pickVeneerVersion(fontNewEnable)
  const { Breadcrumbs, ThemeProvider } = VeneerCore.core
  const { fontFamilyRegular } = VeneerCore.token

  const CustomBreadcrumbs = styled(Breadcrumbs)`
    li {
      font-family: ${fontFamilyRegular};
    }
    font-size: 14px;
    line-height: 20px;
  `

  const { navigation } = props
  const { breadcrumbs } = useContext(PrinterContext)
  const [items, setItems] = useState([])

  useEffect(() => {
    setItems(
      breadcrumbs.map((el) => {
        return {
          text: el.text,
          url: el.url,
          onClick: (e) => {
            e.preventDefault()
            if (el.url) {
              if (navigation) {
                props.history.push(el.url)
              } else {
                props.history.push(el.url)
              }
            }
          }
        }
      })
    )
  }, [breadcrumbs])
  return (
    <ThemeProvider>
      <TopNav>
        <CustomBreadcrumbs
          data-testid="smb-breadcrumb"
          className="smb-breadcrumbs"
          aria-label="Breadcrumb with four levels"
          items={items}
        />
      </TopNav>
    </ThemeProvider>
  )
}

export default withRouter(Breadcrumb)
