import React, { useContext, useEffect, useState } from 'react'
// TODO: font change: add
// import Modal from '@veneer/core/dist/scripts/modal'
// import Button from '@veneer/core/dist/scripts/button'
// import { IconWarningAlt } from '@veneer/core/dist/scripts/icons'
import { useI18n } from '@jarvis/react-portal-addons'
import GeneralContext from '../../contexts/GeneralContext'
import { pickVeneerVersion } from '../../font'

export const UnsavedModal = ({ history, inputValues }) => {
  // TODO: font change: delete
  const { fontNewEnable } = useContext(GeneralContext)
  const VeneerCore = pickVeneerVersion(fontNewEnable)
  const { Modal, Button, IconWarningAlt } = VeneerCore.core
  const { fontFamilyRegular, fontFamilyLight } = VeneerCore.token

  const { t } = useI18n()
  const [blockedUrl, setBlockedUrl] = useState(null)
  useEffect(() => {
    return history.block((ev) => {
      if (blockedUrl) {
        setBlockedUrl(null)
      } else if (inputValues.filter((e) => e !== '').length > 0) {
        setBlockedUrl(ev.pathname)
        return false
      }
      return true
    })
  }, [history, blockedUrl, inputValues])

  const handleCancelUnsavedChangesModal = () => {
    setBlockedUrl(null)
  }

  const handleConfirmUnsavedChangesModal = () => {
    history.push(blockedUrl)
  }

  return (
    <Modal
      id="unsaved-changes-modal"
      className="sm-modal"
      data-testid="unsaved-changes-modal"
      closeOnBlur={false}
      onClose={handleCancelUnsavedChangesModal}
      show={!!blockedUrl}
      footer={
        <div
          className="vn-button-group--responsive"
          style={{ textAlign: 'right' }}
        >
          <Button
            customStyle={{ minWidth: '108px' }}
            appearance="secondary"
            onClick={handleCancelUnsavedChangesModal}
            data-testid="modal-cancel-btn"
          >
            {t('smbDevices.common.cancelBtnText', 'Cancel')}
          </Button>
          <Button
            customStyle={{ minWidth: '103px' }}
            appearance="primary"
            onClick={handleConfirmUnsavedChangesModal}
            data-testid="modal-leave-btn"
          >
            {t('smbDevices.common.leaveBtnText', 'Leave')}
          </Button>
        </div>
      }
    >
      <div className="sm-modal__header">
        <IconWarningAlt size={36} filled color="colorDarkOrange4" />
        <h2
          style={{ fontFamily: fontFamilyRegular }}
          data-testid="unsaved-modal-title"
        >
          {t('smbDevices.common.unsavedModalTitle', 'Unsaved changes')}
        </h2>
      </div>
      <div className="sm-modal__content" style={{ paddingBottom: '0px' }}>
        <p
          className="sm-modal__text"
          data-testid="unsaved-modal-content"
          style={{ fontFamily: fontFamilyLight }}
        >
          {t(
            'smbDevices.common.unsavedModalContent',
            'Are you sure you want to leave this page? Your changes have not been saved and will be discarded if you leave now.'
          )}
        </p>
      </div>
    </Modal>
  )
}
