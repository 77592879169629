import React, { useContext } from 'react'
// import {
//   IconWarningAlt,
//   IconCheckCircle,
//   IconInfo
// } from '@veneer/core/dist/scripts/icons'
import { useI18n } from '@jarvis/react-portal-addons'
import {
  PolicyStatusInProgress,
  PolicyStatusDesc,
  PolicyStatusGood,
  PolicyStatusBad
} from './styles'
import defaultLang from '../../assets/locale'
import { pickVeneerVersion } from '../../font'
import GeneralContext from '../../contexts/GeneralContext'

const { en_US } = defaultLang
interface PageProps {
  isCompliant: Boolean
  id: string
  isInProgress?: Boolean
  showCompliantStatus?: Boolean
  showFormComplaintStatus?: Boolean
}

const PolicyStatusCard = ({
  isCompliant,
  id,
  isInProgress,
  showCompliantStatus = true,
  showFormComplaintStatus = false
}: PageProps) => {
  // TODO: font change: delete
  const { fontNewEnable } = useContext(GeneralContext)
  const VeneerCore = pickVeneerVersion(fontNewEnable)
  const { IconWarningAlt, IconCheckCircle, IconInfo } = VeneerCore.core
  const { fontFamilyRegular, fontFamilyLight } = VeneerCore.token

  const { t } = useI18n()
  return isInProgress ? (
    <PolicyStatusInProgress>
      <div>
        <IconInfo
          color={'colorGray5'}
          filled
          size={24}
          data-testid={`device-${id}-statusicon-inprogress`}
        />
      </div>
      <PolicyStatusDesc font={fontFamilyRegular} fontLight={fontFamilyLight}>
        <h6 data-testid={`device-${id}-statustext-inprogress`}>
          {t(`smbDevices.common.updateInProgress`, 'Update in progress')}
        </h6>
        <p data-testid={`device-${id}-statusdesc-inprogress`}>
          {t(`smbDevices.common.updateDesc`, 'This may take a few moments')}
        </p>
      </PolicyStatusDesc>
    </PolicyStatusInProgress>
  ) : isCompliant && !showFormComplaintStatus ? (
    showCompliantStatus && (
      <PolicyStatusGood>
        <div>
          <IconCheckCircle
            color={'colorGreen6'}
            filled
            size={24}
            data-testid={`device-${id}-statusicon-compliant`}
          />
        </div>
        <PolicyStatusDesc font={fontFamilyRegular}>
          <h6 data-testid={`device-${id}-infoText-compliant`}>
            {t(
              `smbDevices.configurations.${id}.infoText`,
              en_US?.smbDevices?.configurations[id]?.infoText
            )}
          </h6>
        </PolicyStatusDesc>
      </PolicyStatusGood>
    )
  ) : (
    <PolicyStatusBad>
      <div>
        <IconWarningAlt
          color={'colorOrange6'}
          filled
          size={24}
          data-testid={`device-${id}-statusicon-noncompliant`}
        />
      </div>
      <PolicyStatusDesc font={fontFamilyRegular} fontLight={fontFamilyLight}>
        <h6 data-testid={`device-${id}-statustext-noncompliant`}>
          {t(
            `smbDevices.configurations.${id}.errTitle`,
            en_US?.smbDevices?.configurations[id]?.errTitle
          )}
        </h6>
        <p data-testid={`device-${id}-statusdesc-noncompliant`}>
          {t(
            `smbDevices.configurations.${id}.errInfo`,
            en_US?.smbDevices?.configurations[id]?.errInfo
          )}
        </p>
      </PolicyStatusDesc>
    </PolicyStatusBad>
  )
}

export default PolicyStatusCard
