import { JarvisWebHttpClient } from '@jarvis/web-http'
import { sprintf } from 'sprintf-js'
import SSException, { defaultExceptionData } from './SSException'
/**
 * HTTP Client for Smart Security
 */
class SsClient {
  apiVersion = 'v2'

  baseUrl

  authProvider

  jarvisWebClient

  /**
   * HTTP Client for Smart Security.
   *
   * @param {String} baseUrl Smart Security backend url.
   * @param {object} jarvisAuthProvider A JarvisWebHttpClient authorization provider.
   */
  constructor(baseUrl, jarvisAuthProvider) {
    this.baseUrl = baseUrl
    this.authProvider = jarvisAuthProvider

    this.jarvisWebClient = new JarvisWebHttpClient({
      host: this.baseUrl,
      defaultAuthProvider: this.authProvider
    })
  }

  getSecurityAssessment(deviceId) {
    return this._handleGetRequest(() => {
      return this.jarvisWebClient.get({
        url: this.securityGetAssessmentEndPoint(deviceId)
      })
    })
  }

  securityGetAssessmentEndPoint(deviceId) {
    return sprintf(
      '%s/%s/devices/%s/securityassessment',
      this.baseUrl,
      this.apiVersion,
      deviceId
    )
  }

  remediationGetStatusEndPoint(deviceId) {
    return sprintf(
      '%s/%s/devices/%s/setdevicesettingsstatus',
      this.baseUrl,
      this.apiVersion,
      deviceId
    )
  }

  securityPostRemediateEndPoint(deviceId) {
    return sprintf(
      '%s/%s/devices/%s/setdevicesettings',
      this.baseUrl,
      this.apiVersion,
      deviceId
    )
  }

  securityPostAssessmentEndPoint(deviceId) {
    return sprintf(
      '%s/%s/devices/%s/securityassessmentstatus',
      this.baseUrl,
      this.apiVersion,
      deviceId
    )
  }

  changeDeviceAssessmentStatus(deviceId, enabled) {
    return this._postSecurityAssessment(deviceId, {
      securityAssessmentState: String(enabled)
    })
  }

  _postSecurityAssessment(deviceId, data, url) {
    return this._handlePostRequest(() => {
      return this.jarvisWebClient.post({
        url: url || this.securityPostAssessmentEndPoint(deviceId),
        data
      })
    })
  }

  setAdminPwdConfig(deviceId, password) {
    return this._postSecurityAssessment(
      deviceId,
      {
        controlAdminPasswordSet_PasswordText: password
      },
      this.securityPostRemediateEndPoint(deviceId)
    )
  }

  changeAutoFwUpdateConfig(deviceId, enabled) {
    return this._postSecurityAssessment(
      deviceId,
      {
        configAutoFWUpdate: enabled
      },
      this.securityPostRemediateEndPoint(deviceId)
    )
  }

  changeSNMPV1V2Config(deviceId, enabled) {
    return this._postSecurityAssessment(
      deviceId,
      {
        configSNMPv1v2Write: enabled
      },
      this.securityPostRemediateEndPoint(deviceId)
    )
  }

  setSNMPV3Config(deviceId, config) {
    return this._postSecurityAssessment(
      deviceId,
      config,
      this.securityPostRemediateEndPoint(deviceId)
    )
  }

  getRemediationStatus(deviceId) {
    return this._handleGetRequest(() => {
      return this.jarvisWebClient.get({
        url: this.remediationGetStatusEndPoint(deviceId)
      })
    })
  }

  _postSecurityAssessment(deviceId, data, url) {
    return this._handlePostRequest(() => {
      return this.jarvisWebClient.post({
        url: url || this.securityPostAssessmentEndPoint(deviceId),
        data
      })
    })
  }

  async _handleGetRequest(request) {
    try {
      return await request()
    } catch (error) {
      if (error.response.status === 403 || error.response.status === 401) {
        try {
          await this.authProvider.getAccessToken(true)
          return await request()
        } catch (e) {
          const responseData =
            e.response && e.response.status
              ? e.response
              : { ...defaultExceptionData, httpCode: e.response.status }

          throw new SSException(
            'Smart Security Client Error HTTP:Get',
            responseData
          )
        }
      } else {
        const responseData =
          error.response && error.response.status
            ? error.response
            : { ...defaultExceptionData, httpCode: error.response.status }
        throw new SSException(
          'Smart Security Client Error HTTP:Get',
          responseData
        )
      }
    }
  }

  async _handlePostRequest(request) {
    try {
      return await request()
    } catch (error) {
      if (error.response.status === 401) {
        try {
          await this.authProvider.getAccessToken(true)
          return await request()
        } catch (e) {
          const responseData =
            error.response && error.response.status
              ? error.response
              : { ...defaultExceptionData, httpCode: error.response.status }
          throw new SSException(
            'Smart Security Client Error HTTP:Post',
            responseData
          )
        }
      } else {
        const responseData =
          error.response && error.response.status
            ? error.response
            : { ...defaultExceptionData, httpCode: error.response.status }
        throw new SSException(
          'Smart Security Client Error HTTP:Post',
          responseData
        )
      }
    }
  }
}

export default SsClient
