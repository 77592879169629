import React from 'react'
import withAdminPassword from '../../hocs/withAdminPassword'
import PolicyDetailsCard from '../PolicyDetailsCard'
import PolicyStatusCard from '../PolicyStatusCard'
import { DeviceConfigurationsId } from '../../static/consts'
import AdminPasswordForm from './AdminPasswordForm'
import { PolicyCardItemWrap, PolicyCardItem } from './styles'
import { AdminPasswordHelpButtonClicked } from '../../utils/analytics'

const AdminPassword = ({ componentProps }) => {
  const componentProp = {
    ...componentProps,
    id: DeviceConfigurationsId.CONFIG_ADMIN_PASSWORD_SET,
    event: AdminPasswordHelpButtonClicked
  }
  const {
    isComplianceOff,
    isCompliant,
    isPwdUnknownValue,
    isInProgress
  } = componentProps
  return (
    !isComplianceOff && (
      <PolicyDetailsCard {...componentProp}>
        <PolicyCardItem showForm={!isCompliant && !isPwdUnknownValue}>
          <PolicyCardItemWrap>
            <PolicyStatusCard
              isCompliant={isCompliant}
              isInProgress={isInProgress}
              id={componentProp.id}
            />
          </PolicyCardItemWrap>
          {!isCompliant && !isPwdUnknownValue && (
            <PolicyCardItemWrap>
              <AdminPasswordForm {...componentProp} />
            </PolicyCardItemWrap>
          )}
        </PolicyCardItem>
      </PolicyDetailsCard>
    )
  )
}

export default withAdminPassword(AdminPassword)
