import React, { useContext } from 'react'
import PolicyDetailsCard from '../PolicyDetailsCard'
import PolicyStatusCard from '../PolicyStatusCard'
import { DeviceConfigurationsId } from '../../static/consts'
// import Toggle from '@veneer/core/dist/scripts/toggle'
import withSnmpV3 from '../../hocs/withSnmpV3'
import SnmpV3Form from './SnmpV3Form'
import {
  PolicyCardItem,
  PolicyCardItemWrap,
  TitleWrap,
  PolicyCardInfo,
  Title,
  TitleDisable
} from './styles'
import { SnmpV3HelpButtonClicked } from '../../utils/analytics'
import GeneralContext from '../../contexts/GeneralContext'
import { pickVeneerVersion } from '../../font'

const SnmpV3 = ({ componentProps }) => {
  // TODO: font change: delete
  const { fontNewEnable } = useContext(GeneralContext)
  const VeneerCore = pickVeneerVersion(fontNewEnable)
  const { Toggle } = VeneerCore.core
  const { fontFamilyRegular } = VeneerCore.token

  const {
    isComplianceOff,
    toggleState,
    handleToggle,
    isCompliant,
    isInProgress,
    showForm,
    getString
  } = componentProps
  const componentProp = {
    ...componentProps,
    id: DeviceConfigurationsId.CONFIG_SNMP_V3,
    event: SnmpV3HelpButtonClicked
  }
  return (
    !isComplianceOff && (
      <PolicyDetailsCard {...componentProp}>
        <PolicyCardItem showForm={showForm && isCompliant}>
          <PolicyCardItemWrap>
            <TitleWrap>
              <Toggle
                id="toggle-snmp-v3"
                on={toggleState}
                disabled={isInProgress}
                onChange={handleToggle}
                data-testid="snmpv3-toggle"
              />
              {isInProgress ? (
                <TitleDisable
                  data-testid="snmpv3-title"
                  font={fontFamilyRegular}
                >
                  {getString('toggleLabel', 'Enable SNMP V3')}
                </TitleDisable>
              ) : (
                <Title data-testid="snmpv3-title" font={fontFamilyRegular}>
                  {getString('toggleLabel', 'Enable SNMP V3')}
                </Title>
              )}
            </TitleWrap>
            <PolicyCardInfo
              isInProgress={isInProgress}
              isCompliant={isCompliant}
              showForm={showForm}
            >
              <PolicyStatusCard
                isCompliant={isCompliant}
                showFormComplaintStatus={showForm}
                showCompliantStatus={false}
                isInProgress={isInProgress}
                id={componentProp.id}
              />
            </PolicyCardInfo>
          </PolicyCardItemWrap>
          {showForm && isCompliant && (
            <PolicyCardItemWrap>
              <SnmpV3Form {...componentProp} />
            </PolicyCardItemWrap>
          )}
        </PolicyCardItem>
      </PolicyDetailsCard>
    )
  )
}

export default withSnmpV3(SnmpV3)
