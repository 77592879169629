import { prop } from 'ramda';
import { isMobile } from 'react-device-detect';
import { DEEPLINK_MAP, HP_SMB_APP_SCHEMA } from './constants';

const linkExtractRegex = /^(.*):\/\/([^/?#&]+)+\/?([^?#&]+)*\??([^#?]+)*#?([^/?#&]*$)/;

export const extractActionParameters = (link, text) => {
  const result = linkExtractRegex.exec(link);
  const parameters = {
    schema: prop('1', result),
    key: prop('2', result),
    path: prop('3', result),
    parameters: prop('4', result),
    fragment: prop('5', result),
    link,
    label: text,
  };

  return parameters;
};

export const windowOpen = (
  url,
  target = '_blank',
  rel = 'noopener noreferrer',
) => {
  if (isMobile) {
    document.location.href = url;
    document.location.target = target;
    document.location.rel = rel;
  } else {
    window.open(url, target, rel);
  }
};

export const buildLink = ({
  schema,
  key,
  path,
  parameters,
  fragment,
  link,
}) => {
  let resultLink = link;
  if (schema === HP_SMB_APP_SCHEMA) {
    const base = DEEPLINK_MAP[key];
    if (typeof base !== 'undefined') {
      resultLink = `${base}${path ? `/${path}` : ''}${parameters ? `?${parameters}` : ''}${fragment ? `#${fragment}` : ''}`;
    } else {
      console.error('Invalid action link key');
      resultLink = '';
    }
  }
  return resultLink;
};

export const onClickActionFunction = ({
  schema,
  key,
  path,
  parameters,
  fragment,
  link,
  navigation,
}) => {
  const resultLink = buildLink({
    schema,
    key,
    path,
    parameters,
    fragment,
    link,
  });

  if (typeof resultLink === 'undefined') {
    return;
  }

  if (schema === HP_SMB_APP_SCHEMA) {
    navigation.push(resultLink);
  } else {
    windowOpen(resultLink, '_blank');
  }
};
