import React, { useContext } from 'react'
// import {
//   IconCheckCircle,
//   IconInfo,
//   IconWarningAlt
// } from '@veneer/core/dist/scripts/icons'
// import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator'
// Import Context
import { assessmentContext } from '../../contexts/assessmentContext'
import { useI18n } from '@jarvis/react-portal-addons'
import { AssessmentResult, SolutionActive } from '../../static/consts'

import {
  DeviceNotifyLoader,
  DeviceMonitorNotifyCard,
  DeviceAttentionNotifyCard,
  DeviceNotMonitorNotifyCard,
  DeviceUnknownNotifyCard,
  DeviceNotConnectCard,
  DeviceStatusNotifyWrap,
  DeviceStatusNotifyTitle,
  DeviceStatusNotifyBody
} from './styles'
import { assessmentHooksContext } from '../../contexts/assesmentHooksContext'
import GeneralContext from '../../contexts/GeneralContext'
import { pickVeneerVersion } from '../../font'

const StatusNotification = (props) => {
  // TODO: font change: delete
  const { fontNewEnable } = useContext(GeneralContext)
  const VeneerCore = pickVeneerVersion(fontNewEnable)
  const {
    ProgressIndicator,
    IconCheckCircle,
    IconInfo,
    IconWarningAlt
  } = VeneerCore.core
  const { fontFamilyRegular, fontFamilyLight } = VeneerCore.token

  const { t } = useI18n()
  const {
    deviceConnectionStatus,
    solutionMetadata,
    solutionMetaResult,
    isActive,
    outOfComplaint
  } = props
  const {
    isMonitoringInProgress,
    loadingAssessment,
    hasCallRemediation
  } = useContext(assessmentContext)

  const {
    useSecurityAssessmentStatusHook: { assessmentDataNull }
  } = useContext(assessmentHooksContext)

  return (
    <>
      {hasCallRemediation || loadingAssessment || isMonitoringInProgress ? (
        <DeviceNotifyLoader>
          <ProgressIndicator data-testid="status-inprogress" />
        </DeviceNotifyLoader>
      ) : (
        <>
          {deviceConnectionStatus === 'offline' ||
          solutionMetadata == null ||
          Object.keys(solutionMetadata).length == 0 ? (
            <DeviceNotConnectCard font={fontFamilyRegular}>
              {t(
                'smbDevices.deviceDesc.notificationStatus.noDataAvailable',
                'No security data available'
              )}
            </DeviceNotConnectCard>
          ) : isActive === SolutionActive.false || assessmentDataNull ? (
            <DeviceNotMonitorNotifyCard>
              {/* grey card for assessment null*/}
              <IconInfo
                color={'colorGray5'}
                filled
                size={24}
                data-testid="device-status-icon"
              />
              <DeviceStatusNotifyWrap>
                <DeviceStatusNotifyTitle
                  data-testid="device-status-monitoring"
                  font={fontFamilyRegular}
                >
                  {t(
                    'smbDevices.deviceDesc.notificationStatus.title.unknown',
                    'Security monitoring is unavailable'
                  )}
                </DeviceStatusNotifyTitle>
                <DeviceStatusNotifyBody
                  data-testid="device-status-desc"
                  font={fontFamilyLight}
                >
                  {t(
                    'smbDevices.deviceDesc.notificationStatus.desc.unknown',
                    'Smart security status could not be loaded successfully due to an unexpected error. Please try again later.'
                  )}
                </DeviceStatusNotifyBody>
              </DeviceStatusNotifyWrap>
            </DeviceNotMonitorNotifyCard>
          ) : solutionMetaResult === AssessmentResult.securityMonitored ? (
            <DeviceMonitorNotifyCard>
              <IconCheckCircle
                color={'colorGreen6'}
                filled
                size={24}
                data-testid="device-status-icon"
              />
              <DeviceStatusNotifyWrap>
                <DeviceStatusNotifyTitle
                  data-testid="device-status-monitoring-on"
                  font={fontFamilyRegular}
                >
                  {t(
                    'smbDevices.deviceDesc.notificationStatus.title.securityMonitored',
                    'Monitoring is on'
                  )}
                </DeviceStatusNotifyTitle>
                <DeviceStatusNotifyBody
                  data-testid="device-status-desc"
                  font={fontFamilyLight}
                >
                  {t(
                    'smbDevices.deviceDesc.notificationStatus.desc.securityMonitored',
                    'This printer’s protection services are enabled and up to date. No action is required.'
                  )}
                </DeviceStatusNotifyBody>
              </DeviceStatusNotifyWrap>
            </DeviceMonitorNotifyCard>
          ) : solutionMetaResult === AssessmentResult.needsAttention ? (
            <DeviceAttentionNotifyCard>
              <IconWarningAlt
                color={'colorOrange6'}
                filled
                size={24}
                data-testid="device-status-icon"
              />
              <DeviceStatusNotifyWrap>
                <DeviceStatusNotifyTitle
                  data-testid="device-status-needs-attention"
                  font={fontFamilyRegular}
                >
                  {t(
                    'smbDevices.deviceDesc.notificationStatus.title.needsAttention',
                    'Items needing attention:'
                  )}
                  &nbsp;
                  {outOfComplaint}
                </DeviceStatusNotifyTitle>
              </DeviceStatusNotifyWrap>
            </DeviceAttentionNotifyCard>
          ) : solutionMetaResult === AssessmentResult.unknown ? (
            <DeviceUnknownNotifyCard>
              <IconWarningAlt
                color={'colorOrange6'}
                filled
                size={24}
                data-testid="device-status-icon"
              />
              <DeviceStatusNotifyWrap>
                <DeviceStatusNotifyTitle
                  data-testid="device-status-unknown"
                  font={fontFamilyRegular}
                >
                  {t(
                    'smbDevices.deviceDesc.notificationStatus.title.unknown',
                    'Security monitoring is unavailable'
                  )}
                </DeviceStatusNotifyTitle>
                <DeviceStatusNotifyBody
                  data-testid="device-status-desc"
                  font={fontFamilyLight}
                >
                  {t(
                    'smbDevices.deviceDesc.notificationStatus.desc.unknown',
                    'Smart security status could not be loaded successfully due to an unexpected error. Please try again later.'
                  )}
                </DeviceStatusNotifyBody>
              </DeviceStatusNotifyWrap>
            </DeviceUnknownNotifyCard>
          ) : (
            <DeviceNotMonitorNotifyCard>
              <IconInfo
                color={'colorGray5'}
                filled
                size={24}
                data-testid="device-status-icon"
              />
              <DeviceStatusNotifyWrap>
                <DeviceStatusNotifyTitle
                  data-testid="device-status-monitoring-off"
                  font={fontFamilyRegular}
                >
                  {t(
                    'smbDevices.deviceDesc.notificationStatus.title.notMonitored',
                    'Monitoring is off'
                  )}
                </DeviceStatusNotifyTitle>
                <DeviceStatusNotifyBody
                  data-testid="device-status-desc"
                  font={fontFamilyLight}
                >
                  {t(
                    'smbDevices.deviceDesc.notificationStatus.desc.notMonitored',
                    'Turn on security monitoring to help protect this printer.'
                  )}
                </DeviceStatusNotifyBody>
              </DeviceStatusNotifyWrap>
            </DeviceNotMonitorNotifyCard>
          )}
        </>
      )}
    </>
  )
}

export default StatusNotification
