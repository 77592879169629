import React from 'react'
import SmbSecurity from '../src/SmbSecurity'
import { ShellProps } from './types/shell'
import packageInfo from '../package.json'
import '../src/styles/global.scss'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
import { fetchLDClientSideID } from '../src/static/consts'
declare global {
  interface Window {
    Shell: ShellProps
  }
}

function Root(props) {
  const { v1 } = window.Shell as ShellProps
  const { stack } = props
  const namespace = '@jarvis/smb-react-security-mfe'
  return (
    <section id={namespace}>
      <SmbSecurity
        id={packageInfo.name}
        {...props}
        authProvider={v1.authProvider}
        stack={stack}
      />
    </section>
  )
}

export default withLDProvider({
  clientSideID: fetchLDClientSideID()
})(Root)
